import React from 'react';
import { Grid } from '@material-ui/core';

import MarkerIcons from './MarkerIcons';
import { RED, GREEN } from 'utils/constants';

const Markers = ({ mouseLocation }) => {

  return (
    <>
      <Grid item lg={6} className="markers">
        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[RED, GREEN, GREEN, RED, RED]}
          />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[RED, RED, GREEN]}
          />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[GREEN, RED, RED]}
          />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons mouseLocation={mouseLocation} icons={[GREEN, GREEN]} />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons mouseLocation={mouseLocation} icons={[RED, GREEN]} />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons mouseLocation={mouseLocation} icons={[RED, RED]} />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[RED, GREEN, GREEN]}
          />
        </Grid>
        <Grid item lg={1} className="marker">
          <MarkerIcons mouseLocation={mouseLocation} icons={[GREEN, GREEN]} />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[RED, GREEN, RED]}
          />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons mouseLocation={mouseLocation} icons={[GREEN, RED]} />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[GREEN, RED, GREEN]}
          />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons mouseLocation={mouseLocation} icons={[RED]} />
        </Grid>
      </Grid>

      <Grid item lg={6} className="markers">
        <Grid item lg={1} className="marker">
          <MarkerIcons mouseLocation={mouseLocation} icons={[RED, GREEN]} />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[RED, GREEN, GREEN]}
          />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[GREEN, GREEN, RED]}
          />
        </Grid>
        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[GREEN, RED, RED, RED]}
          />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[GREEN, RED, GREEN]}
          />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons mouseLocation={mouseLocation} icons={[GREEN, RED]} />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons mouseLocation={mouseLocation} icons={[RED, GREEN]} />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[RED, RED, GREEN]}
          />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons mouseLocation={mouseLocation} icons={[GREEN]} />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons mouseLocation={mouseLocation} icons={[RED, RED]} />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[GREEN, RED, GREEN]}
          />
        </Grid>

        <Grid item lg={1} className="marker">
          <MarkerIcons
            mouseLocation={mouseLocation}
            icons={[RED, RED, GREEN, RED]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Markers;
