import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Material UI.
import {
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';

// Utils.
import { SETTINGS_PAGE } from 'utils/routesUtils';
import { convertToLabel } from 'utils/textUtils';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';

const Tab = ({ name, label, detail, icon: Icon, externalLink }) => {
  const history = useHistory();

  const location = useLocation();

  const userType = getUserTypeService();

  const classes = useStyles({ userType });

  let params = new URLSearchParams(location.search);

  return (
    <>
      <ListItem
        button
        disableRipple
        style={{ padding: 10, paddingLeft: 30, paddingRight: 30 }}
        selected={(params.get('tab') || 'account') === name}
        alignItems="flex-start"
        onClick={() => {
          if (externalLink) window.open(externalLink, '_blank');
          else
            history.push({
              pathname: SETTINGS_PAGE,
              search: `?tab=${name}`
            });
        }}>
        <ListItemAvatar style={{ minWidth: 45 }}>
          {Icon && <Icon />}
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              style={{ fontWeight: 600, fontSize: 12 }}
              className={classes.defColr}>
              {label ? label : convertToLabel(name)}
            </Typography>
          }
          secondary={<Typography style={{ fontSize: 9 }}>{detail}</Typography>}
        />
      </ListItem>
      <Divider
        variant="inset"
        component="li"
        style={{
          marginLeft: 12,
          width: '90%',
          background: '#e5e5e5'
        }}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  colr: {
    color: prop =>
      prop.userType === SHIPPER
        ? theme.palette.secondary.dark
        : theme.palette.primary.dark
  },
  defColr: {
    color: '#6b6b6b'
  }
}));

export default Tab;
