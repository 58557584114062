import React from 'react';

// Material UI.
import { Box, makeStyles, Typography } from '@material-ui/core';

// Components.
import { connect } from 'react-redux';

import Popup from 'components/Popup';

import {
  setDeletePaymentMethodPopupVisible,
  deletePaymentMethod,
  deleteShipperCard
} from 'reducers/Payment';
import ButtonWithBackground from 'components/Form/Button';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';

const RemovePaymentMethod = ({
  paymentMethod,
  deletePaymentMethodPopupVisible,
  setDeletePaymentMethodPopupVisible,
  deletePaymentMethodLoading,
  deletePaymentMethod,
  shipperDeleteCardLoading,
  deleteShipperCard
}) => {
  const userType = getUserTypeService();
  return (
    <Popup
      open={deletePaymentMethodPopupVisible}
      onclosehandler={() => setDeletePaymentMethodPopupVisible(false)}>
      <Box style={{ minHeight: 100 }}>
        <Typography variant="h4">REMOVE PAYMENT METHOD</Typography>
        <Typography style={{ marginTop: 20, fontSize: 12 }}>
          This payment method will be permanantly removed.
        </Typography>

        <Box style={{ position: 'absolute', bottom: 0, right: 12 }}>
          <ButtonWithBackground
            label="Cancel"
            type="border"
            size="small"
            disabledOnly={
              deletePaymentMethodLoading || shipperDeleteCardLoading
            }
            style={{
              marginRight: 8,
              borderRadius: 9,
              fontSize: 10,
              height: 30,
              width: 80
            }}
            clickHandler={() => setDeletePaymentMethodPopupVisible(false)}
          />
          <ButtonWithBackground
            label="Confirm"
            size="small"
            style={{
              borderRadius: 9,
              fontSize: 10,
              height: 30,
              width: 80
            }}
            clickHandler={() => {
              if (userType === SHIPPER) deleteShipperCard(paymentMethod.id);
              else deletePaymentMethod(paymentMethod.id);
            }}
            disabled={deletePaymentMethodLoading || shipperDeleteCardLoading}
            loaderSize={14}
          />
        </Box>
      </Box>
    </Popup>
  );
};

const useStyles = makeStyles(theme => ({
  removePaymentMethod: {}
}));

const mapStateToProps = state => ({
  deletePaymentMethodPopupVisible:
    state.Payment.deletePaymentMethodPopupVisible,
  deletePaymentMethodLoading: state.Payment.deletePaymentMethodLoading,
  shipperDeleteCardLoading: state.Payment.shipperDeleteCardLoading
});

const mapDispatchToProps = dispatch => {
  return {
    setDeletePaymentMethodPopupVisible: bool =>
      dispatch(setDeletePaymentMethodPopupVisible(bool)),
    deletePaymentMethod: payment_mehod_id =>
      dispatch(deletePaymentMethod(payment_mehod_id)),
    deleteShipperCard: card_id => dispatch(deleteShipperCard(card_id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemovePaymentMethod);
