import React, { useEffect, useState } from 'react'
import clsx from 'clsx';
import {
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getUser, updateShipperPayment } from 'reducers/User';
import ButtonWithBackground from 'components/Form/Button';
import { getShipperPaymenturl } from 'reducers/StripePayout';
import Transactions from '../Payment/Transactions';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { getUserIdService } from 'services/login';

const StripePayment = ({
  user_data,
  getUser,
  updatePaymentIssueLoading,
  paymentMethodUpdated,
  shipperPaymenturlLoading,
  shipperPaymenturl,
  getShipperPaymenturl,
  updateShipperPayment
}) => {
  const [status, setStatus] = useState(null);
  const classes = useStyles();

  // get payment status of shipper
  useEffect(() => {
    if (user_data && user_data.payment_status)
      setStatus(user_data.payment_status);
  }, [user_data]);

  useEffect(() => {
    if ( shipperPaymenturl != null) {
      window.location.href = shipperPaymenturl;
    }
  }, [shipperPaymenturl]);

  // update shipper details after fixing payment issue
  useEffect(() => {
    if (paymentMethodUpdated === true) {
      getUser('shipper',getUserIdService())
    }
  }, [paymentMethodUpdated]);
   
  // set button label according to different payment status
  let buttonLabel
  switch (status) {
    case 'HAS_NO_STRIPE_ACCOUNT':
      buttonLabel = 'Set up payment';
      break;
    case 'HAS_NO_DEFAULT_PAYMENT_METHOD':
      buttonLabel = 'Finish set up';
      break;
    default:
      buttonLabel = 'Manage Payment';
  }

  return (
    <Box className={clsx('container', classes.container)}>
      {/* show banner for payment issue */}
      {(status === 'HAS_PAYMENT_ISSUES' && paymentMethodUpdated == false) && (
        <Box className={classes.message}>
          <Box className={classes.banner}>
                <WarningAmberOutlinedIcon className={classes.iconbutton} />
                <Typography className={classes.bannertext}>
                There was a payment issue while booking one of your loads. We have removed your loads off the load. Please verify your default payment method then confirm you have fixed the issue to put your loads back on the load board.
                </Typography>
            <ButtonWithBackground
              className={classes.updatebutton}
              clickHandler={() => { updateShipperPayment()}}
              disabled={updatePaymentIssueLoading}
              label="I fixed the issue" />
          </Box>
        </Box>
      )}

      <Typography className={classes.text}>
        Payment Method
      </Typography>
      
      <ButtonWithBackground
        label={buttonLabel}
        disabled={shipperPaymenturlLoading}
        clickHandler={getShipperPaymenturl}
        className={classes.button}
      />
      <Transactions/>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent:'left',
    flexDirection: 'column',
  },
  text: {
    color: '#00000099',
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 9
  },
  detail: {
    textAlign: 'center',
    maxWidth: 380,
    color: '#00000099',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 20,
    justifyContent: 'center'
  },
  button: {
    backgroundColor: '#2AB4B8',
    borderRadius: 6,
    cursor:'pointer',
    width:220
  },
  message: {
    width: '100%',
    borderRadius:5,
    position:'relative',
    top:-20,
    backgroundColor: '#FFD9D5',
    display: 'block',
    padding:'6px 0px'
  },
  banner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 30,
    paddingRight: 30
  },
  bannertext: {
    padding:12,
    paddingRight:8,
    color: '#ED3D2E',
    fontWeight: 600,
    paddingTop:12,
    fontSize: 14
  },
  iconbutton: {
    marginTop: 14,
    height: '60%',
    color: '#ED3D2E'
  },
  updatebutton: {
    borderRadius: 8,
    width: 220,
    backgroundColor: '#ffffff',
    color: '#6B6B6B',
    marginTop: 12,
    padding: 6,
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#6B6B6B',
    },
  }
}
))

const mapStateToProps = (state) => {
  return {
    shipperPaymenturlLoading: state.StripePayout.shipperPaymenturlLoading,
    shipperPaymenturl: state.StripePayout.shipperPaymenturl,
    user_data: state.User.user_data,
    updatePaymentIssueLoading: state.User.updatePaymentIssueLoading,
    paymentMethodUpdated: state.User.paymentMethodUpdated
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShipperPaymenturl: () => dispatch(getShipperPaymenturl()),
    updateShipperPayment: () => dispatch(updateShipperPayment()),
    getUser: (type,id) => dispatch(getUser(type,id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StripePayment);