import Axios from 'axios';
import axiosClient from 'utils/axiosClient';
import {
  LOAD_STATUS,
  LOAD_STEP,
  PREFIX_SHIPMENT,
  PREFIX_TRUCK,
  ROADCALL_SAVE_SEARCHES,
  PREFIX_TRUCKER,
  ROADCALL_PICKUP_CONTACT_DETAILS,
  ROADCALL_DROP_CONTACT_DETAILS,
  ROADCALL_PICKUP_RECENT_SEARCHES,
  ROADCALL_DROP_RECENT_SEARCHES,
  ROADCALL_MY_CURRENT_LOCATION,
  PREFIX_INVOICE
} from 'utils/constants';
import {
  JOB_COPY_PAGE,
  JOB_EDIT_PAGE,
  LOADS,
  POST_JOB_PAGE
} from 'utils/routesUtils';
import states from 'utils/states';
import { convertToLabel } from 'utils/textUtils';
import { CARRIER, SHIPPER } from 'utils/users';
import { getShipmentMedia } from './files';
import { getUserTypeService } from './login';
import { ADMINLOADS } from 'utils/routesUtils';
import { trackEvent } from 'utils/MixPanel';

const version = process.env.REACT_APP_API_VER;
const google_api_key = process.env.REACT_APP_GOOGLE_API;
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const s3URL = process.env.REACT_APP_S3_URL;

const getShipmentsService = async (
  offset: number = 0,
  limit: number = 10,
  ordering: string = '-created_at',
  carrier_id: string = '0',
  shipment_id: string = '',
  shipper_id: string = '',
  tag: string = 'all',
  carrier_id_null: boolean,
  userType: string
) => {
  let url = `shipment/${version}/available/?offset=${offset}&limit=${limit}
	&ordering=${ordering}`;

  if (shipment_id) {
    url += `&shipment_id=RC-SHIPMENT-${shipment_id}`;
  }

  if (shipper_id) {
    url += `&shipper_id=${shipper_id}`;
  }

  if (carrier_id) {
    url += `&carrier_id=${carrier_id}`;
  }

  if (userType === SHIPPER) {
    url += `&carrier_id__isnull=${carrier_id_null}`;
  }

  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const getShipmentDetailService = async (shipment_id: string) => {
  let url = `shipment/${version}/${shipment_id}/`;

  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const getTotalCustomers = async () => {
  let url = `/shipment/${version}/contacts/`;

  try {
    const response = await axiosClient.get(url);
    if (response.data && response.data.count) {
      return response.data.count;
    } else {
      // Handle the case where the 'count' property is not present in the response.
      throw new Error("Count not found in response data");
    }
  } catch (error) {
    throw error.response;
  }
}

const getStateAbbreviation = (paramState: string = '') => {
  if (!paramState) return '';
  paramState = paramState.trim();
  if (states.some(state => state.name === paramState))
    return states.find(state => state.name === paramState)?.abbreviation;
  return paramState;
};

const getMultipolygonCoords = async (location: string) => {
  let url = `https://nominatim.openstreetmap.org/search.php`;

  try {
    const response = await Axios.get(url, {
      params: {
        q: location,
        polygon_geojson: 1,
        format: 'json'
        //type: 'administrative',
        //dedupe: 0,
        //countrycodes: 'us',
      }
    });

    if (response.data.some((d: any) => d.type === 'administrative')) {
      return response.data.find((d: any) => d.type === 'administrative')
        .geojson;
    }
    return response.data[0].geojson;
  } catch (error) {
    throw error.response;
  }
};

const newShipmentService = async (obj: any) => {
  let url = `shipment/all/${version}/`;

  try {
    // fetch data from a url endpoint
    const response = await axiosClient.post(url, obj);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const setShipmentQuoteService = async (obj: any) => {
  let url = `shipment/quote/${version}/`;

  try {
    // fetch data from a url endpoint
    const response = await axiosClient.post(url, obj);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const getShipmentQuoteService = async (id: string) => {
  let url = `shipment/quote/${version}/?offset=0&limit=1000&shipment_id=${id}`;

  try {
    // fetch data from a url endpoint
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const updateShipmentService = async (shipment_id: string, obj: any) => {
  let url = `shipment/${version}/${shipment_id}/`;

  try {
    const response = await axiosClient.patch(url, obj);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const getLoadRoutes = (load: any) => {
  let route = `https://www.google.com/maps/embed/v1/directions?key=${google_api_key}`;

  route += `&origin=${load.pickup_city}, ${load.pickup_state}, USA&destination=${load.delivery_city}, ${load.delivery_state}, USA`;

  // if (load.pickup_city === load.delivery_city) {
  //   route += `&origin=${load.pickup_full_address}&destination=${load.delivery_full_address}`;
  // } else {
  //   route += `&origin=${load.pickup_city}&destination=${load.delivery_city}`;
  // }

  return route;
};

const updateShipment = async (shipment_id: string, obj: any) => {
  let url = `shipment/${version}/${shipment_id}/update/`;

  try {
    const response = await axiosClient.patch(url, obj);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const updateShipmentItems = async (shipment_id: string, items: any) => {
  let url = `shipment/${version}/${shipment_id}/item/update/`;

  try {
    const response = await axiosClient.patch(url, items);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const isUSAddressService = (address: any) => {
  let address_components = address.address_components;

  let country = address_components.filter((addr: any) => {
    if (addr.types.includes('country')) {
      return addr;
    }
  });

  return country.length && country[0].short_name === 'US';
};

const companyFromAddressService = (address: any, location: any) => {
  const companyName = location.split(',');
  const mainText = companyName[0].trim();
  return mainText;
};

const zipFromAddressService = (address: any) => {
  let address_components = address.address_components;

  let zip = address_components.filter((addr: any) => {
    if (addr.types.includes('postal_code')) {
      return addr;
    }
  });

  if (zip.length) return zip[0].long_name;

  return false;
};

const StreetNoFromAddressService = (address: any) => {
  let address_components = address.address_components;

  let street_no = address_components.filter((addr: any) => {
    if (addr.types.includes('street_number')) {
      return addr;
    }
  });
  if (street_no.length) {
    return street_no[0].long_name;
  }
  return false;
};
const routeFromAddressService = (address: any) => {
  let address_components = address.address_components;

  let route = address_components.filter((addr: any) => {
    if (addr.types.includes('route')) {
      return addr;
    }
  });
  if (route.length) {
    return route[0].long_name
  };
  return false;
};

const cityFromAddressService = (address: any) => {
  let address_components = address.address_components;

  // var address = results[0].formatted_address;
  // var pin = results[0].address_components[results[0].address_components.length - 1].long_name;
  // var country = results[0].address_components[results[0].address_components.length - 2].long_name;
  // var state = results[0].address_components[results[0].address_components.length - 3].long_name;
  // var city = results[0].address_components[results[0].address_components.length - 4].long_name;

  let city = address_components.filter((addr: any) => {
    if (addr.types.includes('locality')) {
      return addr;
    }
  });

  if (city.length) return city[0].long_name;

  city = address_components.filter((addr: any) => {
    if (addr.types.includes('sublocality')) {
      return addr;
    }
  });

  if (city.length) return city[0].long_name;

  city = address_components.filter((addr: any) => {
    if (addr.types.includes('administrative_area_level_2')) {
      return addr;
    }
  });

  if (city.length) return city[0].long_name;

  return false;
};

const stateFromAddressService = (address: any) => {
  let address_components = address.address_components;

  let state = address_components.filter((addr: any) => {
    if (addr.types.includes('administrative_area_level_1')) {
      return addr;
    }
  });

  if (state.length) {
    return {
      long_name: state[0].long_name,
      short_name: state[0].short_name
    };
  }

  return false;
};

const shipmentService = async (obj: any) => {
  let url = `shipment/${version}/`;
  if (obj.is_private === true) url = `shipment/${version}/private/`;

  try {
    // fetch data from a url endpoint
    const response = await axiosClient.post(url, obj);
    trackEvent("Load Added")
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const shipmentUploadMediaService = async (shipment_id: string, data: any) => {
  let url = `shipment/${version}/${shipment_id}/media/`;

  try {
    const response = await axiosClient.post(url, data);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const carrierUploadMediaService = async (data: any) => {
  let url = `${getUserTypeService(false)}/media/${version}/`;

  try {
    const response = await axiosClient.post(url, data);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const carrierGraphData = async (date_from: any, date_to: any) => {
  let url = `shipment/${version}/dashboard/data/`;

  try {
    const response = await axiosClient.get(url, {
      params: {
        date_from,
        date_to
      }
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};
const AdminGraphData = async (
  date_from: any,
  date_to: any,
  transaction_group: any
) => {
  try {
    const response = await axiosClient.get(`dashboard/${version}/admin/data`, {
      params: {
        date_from,
        date_to,
        transaction_group
      }
    });
    {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

const dashboardData = async () => {
  let url = `shipment/${version}/dashboard/sum/data/`;

  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const getLocationByCoords = async (coords: any) => {
  let url = `https://maps.googleapis.com/maps/api/geocode/json`;

  try {
    const response = await Axios.get(url, {
      params: {
        latlng: `${coords.latitude},${coords.longitude}`,
        key: google_api_key
      }
    });
    return response.data.results[0].formatted_address;
  } catch (error) {
    throw error.response;
  }
};

const calculateDistance = async (pick: any, drop: any) => {
  let url = 'https://maps.googleapis.com/maps/api/distancematrix/json';

  try {
    const response = await Axios.get(url, {
      params: {
        origins: `${pick.lat},${pick.lng}`,
        destinations: `${drop.lat},${drop.lng}`,
        units: 'imperial',
        key: google_api_key
      }
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const removeShipmentPrefix = (shipment_id: string = '') => {
  return shipment_id.replace(PREFIX_SHIPMENT, '');
};

const removeInvoicePrefix = (invoice_id: string = '') => {
  return invoice_id.replace(PREFIX_INVOICE, '');
};

const removeTruckPrefix = (truck_id: string = '') => {
  return truck_id.replace(PREFIX_TRUCK, '');
};

const removeTruckerPrefix = (trucker_id: string = '') => {
  return trucker_id.replace(PREFIX_TRUCKER, '');
};

const getLoadDetailUrl = (load: any) => {
  const shipmentId = load.shipment_offer_id ? load.shipment.shipment_id : load.shipment_id;
  let url = `${LOADS}?id=${shipmentId}`;

  // Check if job is added by carrier.
  if (load.is_private) {
    url += `&is_private=1`;
  } else if (load.quote_status) {
    url += `&quote_status=${load.quote_status}`;
  }

  return url;
};

const getAdminLoadDetailUrl = (load: any, user_id: any) => {
  let url = `${ADMINLOADS}?id=${load.shipment_id}?user_id=${user_id}`;
  // Check if job is added by carrier.
  if (load.is_private) url += `&is_private=1`;
  if (load.quote_status) url += `&quote_status=${load.quote_status}`;
  return url;
};

const getLoadCopyUrl = (load: any) => {
  let url = `${JOB_COPY_PAGE}?id=${load.shipment_id}`;

  // Check if job is added by carrier.
  if (load.is_private) url += `&is_private=1`;
  else if (load.quote_status) url += `&quote_status=${load.quote_status}`;

  return url;
};

const generateBOL = async (shipment_id: string) => {
  try {
    const response = await axiosClient.get(
      `shipment/${version}/${shipment_id}/bol/`
    );
    getShipmentMedia(response.data[0], shipment_id);
  } catch (error) {
    throw error.response;
  }
};

const getDeleteJobReasons = async () => {
  try {
    const response = await axiosClient.get(
      `shipment/${version}/config/?cancel_reason=true`
    );
    return response.data.cancel_reason;
  } catch (error) {
    throw error.response;
  }
};

const cancelJobService = async (
  shipment_id: string,
  cancel_type: string,
  data: any
) => {
  try {
    const response = await axiosClient.put(
      `shipment/${version}/${shipment_id}/cancel/?cancel_type=${cancel_type}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const generateBolUrl = async (shipment_id: string) => {
  try {
    const response = await axiosClient.get(
      `shipment/${version}/${shipment_id}/bol/`
    );
    return response.data[0];
  } catch (error) {
    throw error.response;
  }
};

const getSavedLoadsService = async (url: string = '') => {
  try {
    const response = await axiosClient.get(
      url || `shipment/${version}/favourite/`
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const isLoadHasBOL = (load: any) => {
  //if (getUserTypeService() === CARRIER && load.is_private) return false;
  return true;
};

const isLoadEditable = (load: any) => {
  if (!load) return false;
  else if (load.is_private) return true;
  else if (
    load.status === LOAD_STEP.ORDERED &&
    getUserTypeService() === SHIPPER
  )
    return true;
  return false;
};

const isLoadOffered = (load: any) => {
  if (!load) return false;
  else if (
    load.status === LOAD_STEP.ORDERED && 
    load.shipment_offer &&
    getUserTypeService() === SHIPPER
  )
    return true;
  return false;
};
const isLoadAssignable = (load: any) => {
  if (!load) return false;
  else if (
    load.status === LOAD_STEP.ORDERED && 
    !load.shipment_offer &&
    getUserTypeService() === SHIPPER
  )
    return true;
  return false;
};

const isLoadMarkableToDelivered = (load: any) => {
  if (!load) return false;
  else if (load.is_private === true && load.status === LOAD_STATUS.PICKED_UP)
    return true;
  return false;
};

const isAccountPremium = (plan: string) => {
  if (!plan) return false;
  else if (plan == 'Premium Plan')
    return true;
  return false;
};

const isLoadMarkableToActive = (load: any) => {
  if (!load) return false;
  else if (
    load.is_private === true &&
    load.status === LOAD_STATUS.TRUCK_ASSIGNED
  )
    return true;
  return false;
};

const isLoadMarkableToNewFromActive = (load: any) => {
  if (!load) {
    return false;
  }
  else if (
    load.is_private === true &&
    load.status === LOAD_STATUS.PICKED_UP ||
    load.status === LOAD_STATUS.ON_THE_WAY
  ) {
    return true;
  }
  return false;
};
const isLoadMarkableToActiveFromDelivered = (load: any) => {
  if (!load) {
    return false;
  } else if (
    (load.is_private === true && load.status === LOAD_STATUS.DELIVERED && !hasNonNullInvoice(load))
  ) {
    return true;
  }

  return false;
};

const hasNonNullInvoice = (load: any) => {
  if (load.shipment_items && load.shipment_items.length > 0) {
    for (const item of load.shipment_items) {
      if (item.invoice !== null) {
        // If any shipment item has a non-null invoice, return true
        return true;
      }
    }
  }
  // Return false if all shipment items have null invoices
  return false;
};

const isLoadPayable = (load: any) => {
  if (!load) return false;
  else if (
    load.is_private &&
    !load.paid_at &&
    load.status === LOAD_STATUS.DELIVERED
  )
    return true;
  return false;
};

const isLoadArchivable = (load: any) => {
  if (!load) return false;
  else if (
    //load.is_private &&
    //load.paid_at
    load.status === LOAD_STATUS.DELIVERED
  )
    return true;
  return false;
};

const isLoadInvoicable = (load: any) => {
  if (!load) return false;
  if (
    load.is_private &&
    !load.invoiced_at &&
    load.status === LOAD_STATUS.DELIVERED
  ) {
    if (load.shipment_items && load.shipment_items.length > 0) {
      for (const item of load.shipment_items) {
        if (item.invoice === null) {
          // If any item is not invoiced, return true
          return true;
        }
      }
    }
    // if all the items are invoiced return false
    return false;
  }
  // Default return if conditions are not met
  return false;
};

const isAnyItemInvoiced = (load: any) => {
  if (!load) return false;
  if (
    load.is_private &&
    load.status === LOAD_STATUS.DELIVERED
  ) {
    if (load.shipment_items && load.shipment_items.length > 0) {
      for (const item of load.shipment_items) {
        if (item.invoice !== null) {
          // If any item is invoiced, return true
          return true;
        }
      }
    }
    // if none of the items are invoiced, return false
    return false;
  }
  // Default return if conditions are not met
  return false;
};

const isLoadUninvoicable = (load: any) => {
  if (!load) return false;
  if (
    load.is_private &&
    load.invoiced_at &&
    load.status === LOAD_STATUS.DELIVERED
  )
    return true;
  return false;
};

const isLoadUnarchivable = (load: any) => {
  if (!load) return false;
  else if (load.status === LOAD_STATUS.CANCELLED) return true;
  return false;
};

const archiveJob = async (shipment_id: string) => {
  try {
    const response = await axiosClient.put(
      `shipment/${version}/${shipment_id}/archive/`
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const jobInvoiced = async (shipment_id: string) => {
  try {
    const response = await axiosClient.post(
      `shipment/${version}/${shipment_id}/invoiced/`
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const jobUninvoiced = async (shipment_id: string) => {
  try {
    const response = await axiosClient.post(
      `shipment/${version}/${shipment_id}/uninvoiced/`
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const unArchiveJob = async (shipment_id: string) => {
  try {
    const response = await axiosClient.put(
      `shipment/${version}/${shipment_id}/unarchive/`
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const isLoadDeletable = (load: any) => {
  if (!load) return false;

  const userType = getUserTypeService();

  if (userType === CARRIER) {
    if (load.quote_status === LOAD_STATUS.REJECTED) return false;
    else if (load.status === LOAD_STATUS.CANCELLED) return false;
    else if (
      load.status === LOAD_STATUS.ACCEPTED ||
      load.status === LOAD_STATUS.TRUCK_ASSIGNED ||
      load.status === LOAD_STATUS.TRANSPORTER_ASSIGNED
    )
      return true;
  } else if (userType === SHIPPER) {
    if (
      load.status === LOAD_STATUS.ORDERED ||
      load.status === LOAD_STATUS.PENDING ||
      load.status === LOAD_STATUS.TRUCK_ASSIGNED ||
      load.status === LOAD_STATUS.TRANSPORTER_ASSIGNED
    )
      return true;
  }
  return false;
};

const getEditLoadUrl = (load: any) => {
  let url = `${JOB_EDIT_PAGE}?id=${load.shipment_id}`;
  if (load.is_private) url += `&is_private=1`;
  else if (load.quote_status) url += `&quote_status=${load.quote_status}`;
  return url;
};

const breakAddressBy = (address: string = '', breakpoint: string = '') => {
  if (!address.includes(breakpoint)) breakpoint = 'USA'; // Remove country from address.
  let updatedAddress = address
    .substring(0, address.lastIndexOf(breakpoint))
    .trim();

  if (updatedAddress.slice(-1) === ',')
    // Remove comma from end.
    updatedAddress = updatedAddress.substring(0, updatedAddress.length - 1);

  return updatedAddress;
};

const getShipmentStatusService = (load: any) => {
  if(load.shipment_offer) return "Offered";
  else if (!load) return '';
  else if (load.quote_status === 'rejected') return shipmentStatus('rejected');

  let currentUserType = getUserTypeService();
  let load_status = load.quote_status || load.status;

  if (currentUserType === SHIPPER) {
    load_status =
      load.quote_status === 'pending' && load.status === 'ordered'
        ? 'pending'
        : load.status;
  } else if (
    currentUserType === CARRIER &&
    load.status === 'truck_assigned' &&
    load.quote_status !== 'rejected'
  )
    load_status = load.status;
  else if (
    currentUserType === CARRIER &&
    !load.quote_status &&
    load.status === 'transporter_assigned'
  )
    load_status = load.status;
  else if (currentUserType === CARRIER && load.status === 'cancelled')
    load_status = LOAD_STEP.ARCHIVED;
  else if (currentUserType === CARRIER && load.status === 'picked_up')
    load_status = LOAD_STEP.ACTIVE;
  else if (load.status === 'delivered') load_status = 'delivered';
  else load_status = load.quote_status;

  return shipmentStatus(load_status, load.is_private);
};

const getAdminShipmentStatusService = (load: any, currentUserType: string) => {
  if (!load) return '';
  else if (load.quote_status === 'rejected') return shipmentStatus('rejected');

  let load_status = load.quote_status || load.status;

  if (currentUserType === SHIPPER) {
    load_status =
      load.quote_status === 'pending' && load.status === 'ordered'
        ? 'pending'
        : load.status;
  } else if (
    currentUserType === CARRIER &&
    load.status === 'truck_assigned' &&
    load.quote_status !== 'rejected'
  )
    load_status = load.status;
  else if (
    currentUserType === CARRIER &&
    !load.quote_status &&
    load.status === 'transporter_assigned'
  )
    load_status = load.status;
  else if (currentUserType === CARRIER && load.status === 'cancelled')
    load_status = LOAD_STEP.ARCHIVED;
  else if (currentUserType === CARRIER && load.status === 'picked_up')
    load_status = LOAD_STEP.ACTIVE;
  else if (load.status === 'delivered') load_status = 'delivered';
  // else load_status = load.quote_status;
  return shipmentStatus(load_status, load.is_private);
};

const shipmentStatus = (status: string, isPrivate: boolean = false) => {
  //if (isPrivate) return convertToLabel(LOAD_STEP.MY_JOBS);

  switch (status) {
    case LOAD_STATUS.TRANSPORTER_ASSIGNED:
    case LOAD_STATUS.ACCEPTED:
    case LOAD_STATUS.TRUCK_ASSIGNED:
      return convertToLabel(LOAD_STEP.NEW);
    case LOAD_STATUS.PICKED_UP:
    case LOAD_STATUS.ON_THE_WAY:
      return convertToLabel(LOAD_STEP.ACTIVE);
    case LOAD_STATUS.DELIVERED:
      return convertToLabel(LOAD_STEP.DELIVERED);
    case LOAD_STATUS.CANCELLED:
      return convertToLabel(LOAD_STEP.ARCHIVED);
    case LOAD_STATUS.REJECTED:
      return convertToLabel('rejected');
    case LOAD_STATUS.ORDERED:
      return convertToLabel(LOAD_STEP.UNASSIGNED);
    case LOAD_STATUS.PENDING:
      return convertToLabel(LOAD_STEP.PENDING);
    default:
      return convertToLabel(status);
  }
};

const updateSearch = (index: number, data: any) => {
  let saveSearches: any = localStorage.getItem(ROADCALL_SAVE_SEARCHES);
  if (saveSearches) {
    saveSearches = JSON.parse(saveSearches);
    saveSearches[index] = data;
    localStorage.setItem(ROADCALL_SAVE_SEARCHES, JSON.stringify(saveSearches));
  }
};

const savePickupRecentSearch = (data: any) => {
  if (!data) return;
  else if (Object.keys(data).length === 0) return;

  let recentSearches: any = localStorage.getItem(
    ROADCALL_PICKUP_RECENT_SEARCHES
  );
  if (recentSearches) {
    recentSearches = JSON.parse(recentSearches);

    // Check if address already exists.
    if (
      recentSearches.filter(
        (search: any) => search.description === data.description
      ).length
    )
      return;

    // Remove very first saved search data if searches list exeeds to 5.
    if (recentSearches.length >= 5) recentSearches.pop();

    // Add data in the beginning.
    recentSearches.unshift(data);
  } else recentSearches = [data];
  localStorage.setItem(
    ROADCALL_PICKUP_RECENT_SEARCHES,
    JSON.stringify(recentSearches)
  );
};

const getPickupRecentSearches = () => {
  let recentSearches: any = localStorage.getItem(
    ROADCALL_PICKUP_RECENT_SEARCHES
  );
  if (recentSearches) return JSON.parse(recentSearches);
  return [];
};

const saveDropRecentSearch = (data: any) => {
  if (!data) return;
  else if (Object.keys(data).length === 0) return;

  let recentSearches: any = localStorage.getItem(ROADCALL_DROP_RECENT_SEARCHES);
  if (recentSearches) {
    recentSearches = JSON.parse(recentSearches);

    // Check if address already exists.
    if (
      recentSearches.filter(
        (search: any) => search.description === data.description
      ).length
    )
      return;

    // Remove very first saved search data if searches list exeeds to 5.
    if (recentSearches.length >= 5) recentSearches.pop();

    // Add data in the beginning.
    recentSearches.unshift(data);
  } else recentSearches = [data];
  localStorage.setItem(
    ROADCALL_DROP_RECENT_SEARCHES,
    JSON.stringify(recentSearches)
  );
};

const saveMyCurrentLocation = (location: string) =>
  localStorage.setItem(ROADCALL_MY_CURRENT_LOCATION, location);

const getMyCurrentLocation = () =>
  localStorage.getItem(ROADCALL_MY_CURRENT_LOCATION);

const getDropRecentSearches = () => {
  let recentSearches: any = localStorage.getItem(ROADCALL_DROP_RECENT_SEARCHES);
  if (recentSearches) return JSON.parse(recentSearches);
  return [];
};

const saveSearch = (data: any) => {
  let saveSearches: any = localStorage.getItem(ROADCALL_SAVE_SEARCHES);
  if (saveSearches) {
    saveSearches = JSON.parse(saveSearches);

    // Remove very first saved search data if searches list exeeds to 5.
    if (saveSearches.length >= 5) saveSearches.pop();

    // Add data in the beginning.
    saveSearches.unshift(data);
  } else saveSearches = [data];
  localStorage.setItem(ROADCALL_SAVE_SEARCHES, JSON.stringify(saveSearches));
};

const deleteSavedSearch = (index: number) => {
  let saveSearches: any = localStorage.getItem(ROADCALL_SAVE_SEARCHES);
  if (saveSearches) {
    saveSearches = JSON.parse(saveSearches);
    saveSearches.splice(index, 1);
    localStorage.setItem(ROADCALL_SAVE_SEARCHES, JSON.stringify(saveSearches));
  }
};

const getSaveSearches = () => {
  let saveSearches: any = localStorage.getItem(ROADCALL_SAVE_SEARCHES);
  if (saveSearches) return JSON.parse(saveSearches);
  return [];
};

const getShipmentHeaderService = (load: any) => {
  if (load.shipment_offer_id) {
    load = load.shipment;
  }

  if (!load.category) {
    load.category = 'vehicle';
  }
  if (load.shipment_items.length === 0) return 'No items';
  let headerText = '';

  if (load.category === 'vehicle') {
    headerText =
      load.shipment_items[0].year +
      ' ' +
      load.shipment_items[0].make +
      ' ' +
      load.shipment_items[0].model;
  } else
    headerText = load.shipment_items[0].type
      ? load.shipment_items[0].type
      : convertToLabel(load.category);

  if (!headerText.trim()) headerText = convertToLabel(load.category);

  headerText +=
    load.shipment_items.length > 1 ? ` +${load.shipment_items.length - 1}` : '';

  return headerText;
};

const getShipmentHeaderServiceAdmin = (shipment: any) => {
  if (shipment.items.length === 0) return 'No items';
  let headerText = '';
  let category = 'vehicle';
  if (category === 'vehicle') {
    headerText =
      shipment.items[0].year +
      ' ' +
      shipment.items[0].make +
      ' ' +
      shipment.items[0].model;
  } else
    headerText = shipment.items[0].type
      ? shipment.shipment.items[0].type
      : convertToLabel(shipment.category);

  if (!headerText.trim()) headerText = convertToLabel(shipment.category);
  headerText +=
    shipment.items.length > 1 ? ` +${shipment.items.length - 1}` : '';
  return headerText;
};

const getOnlineBOL = async (shipment_id: string) => {
  const url = `${baseUrl}shipment/${version}/${shipment_id}/bol_data/`;

  try {
    const response = await Axios.get(url);

    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const sendInvoice = async (obj: any) => {
  const url = `${baseUrl}shipment/${version}/invoice/`;
  console.log(obj)
  try {
    const response = await axiosClient.post(url, obj);
    return response;
  } catch (error) {
    throw error.response;
  }
};

const markJobAsPaid = async (data: any) => {
  const { invoice_id, shipment_items, paid_on, payment_method } = data;
  const url = `shipment/${version}/invoice/${invoice_id}/mark_paid/`;

  try {
    const response = await axiosClient.patch(url, {
      shipment_items,
      paid_on,
      payment_method
    });

    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const getDamageCodes = async () => {
  try {
    const response = await Axios.get(
      `${baseUrl}shipment/${version}/config/?shipment_damage=true`
    );
    return response.data.shipment_damages;
  } catch (error) {
    throw error.response;
  }
};
const getPaymentOptions = async (obj: boolean) => {
  let url = `${baseUrl}shipment/${version}/config/?payment_type=true`;

  // Check if the flag is true, then append the additional parameter
  if (obj) {
    url += "&invoice_payment_term=true";
  }

  try {
    const response = await Axios.get(url);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const savePickupContactDetails = (data: any) => {
  if (
    !(
      data.save_pickup_contact_details === true &&
      (data.pickup_company_name.trim() || data.pickup_contact_name.trim())
    )
  )
    return;
  let obj = {
    pickup_coords: data.pickup_coords,
    pickup_location: data.pickup_location,
    pick_date: data.pick_date,
    pick_time: data.pick_time,
    pickup_company_name: data.pickup_company_name,
    pickup_contact_name: data.pickup_contact_name,
    pickup_company_working_from: data.pickup_company_working_from,
    pickup_company_working_to: data.pickup_company_working_to,
    pickup_contact_number: data.pickup_contact_number,
    pickup_additional_contact_number: data.pickup_additional_contact_number,
    pickup_contact_email: data.pickup_contact_email,
    pickup_additional_contact_email: data.pickup_additional_contact_email
  };

  let pickupContactDetails: any = localStorage.getItem(
    ROADCALL_PICKUP_CONTACT_DETAILS
  );
  if (pickupContactDetails) {
    pickupContactDetails = JSON.parse(pickupContactDetails);

    if (pickupContactDetails.length >= 10) pickupContactDetails.pop();

    // Add data in the beginning.
    pickupContactDetails.unshift(obj);
  } else pickupContactDetails = [obj];
  localStorage.setItem(
    ROADCALL_PICKUP_CONTACT_DETAILS,
    JSON.stringify(pickupContactDetails)
  );
};

const getPickupContactDetails = (val: any = '', key: string = '') => {
  let value = '';
  if (val && val.trim()) value = val.trim().toLowerCase();
  else return [];
  const pickupDetails: any = localStorage.getItem(
    ROADCALL_PICKUP_CONTACT_DETAILS
  );
  if (pickupDetails) {
    const parsedPickupDetails = JSON.parse(pickupDetails);

    return parsedPickupDetails.filter((info: any) =>
      info[key].toLowerCase().includes(value)
    );
  }
  return [];
};

const saveDropContactDetails = (data: any) => {
  if (
    !(
      data.save_dropoff_contact_details === true &&
      (data.drop_company_name.trim() || data.drop_contact_name.trim())
    )
  )
    return;
  let obj = {
    drop_coords: data.drop_coords,
    drop_location: data.drop_location,
    drop_date: data.drop_date,
    drop_time: data.drop_time,
    drop_company_name: data.drop_company_name,
    drop_contact_name: data.drop_contact_name,
    delivery_company_working_from: data.delivery_company_working_from,
    delivery_company_working_to: data.delivery_company_working_to,
    drop_contact_number: data.drop_contact_number,
    drop_additional_contact_number: data.drop_additional_contact_number,
    drop_contact_email: data.drop_contact_email,
    drop_additional_contact_email: data.drop_additional_contact_email
  };

  let dropContactDetails: any = localStorage.getItem(
    ROADCALL_DROP_CONTACT_DETAILS
  );
  if (dropContactDetails) {
    dropContactDetails = JSON.parse(dropContactDetails);

    if (dropContactDetails.length >= 10) dropContactDetails.pop();

    // Add data in the beginning.
    dropContactDetails.unshift(obj);
  } else dropContactDetails = [obj];
  localStorage.setItem(
    ROADCALL_DROP_CONTACT_DETAILS,
    JSON.stringify(dropContactDetails)
  );
};
const getDropContactDetails = (val: any = '', key: string = '') => {
  let value = '';
  if (val && val.trim()) value = val.trim().toLowerCase();
  else return [];
  const dropDetails: any = localStorage.getItem(ROADCALL_DROP_CONTACT_DETAILS);
  if (dropDetails) {
    const parsedDropDetails = JSON.parse(dropDetails);

    return parsedDropDetails.filter((info: any) =>
      info[key].toLowerCase().includes(value)
    );
  }
  return [];
};
export {
  getDamageCodes,
  getShipmentsService,
  getShipmentDetailService,
  newShipmentService,
  setShipmentQuoteService,
  getShipmentQuoteService,
  updateShipmentService,
  isUSAddressService,
  zipFromAddressService,
  shipmentService,
  shipmentUploadMediaService,
  cityFromAddressService,
  stateFromAddressService,
  getLocationByCoords,
  calculateDistance,
  shipmentStatus,
  removeShipmentPrefix,
  breakAddressBy,
  getLoadDetailUrl,
  getEditLoadUrl,
  updateShipment,
  getLoadRoutes,
  removeTruckPrefix,
  updateShipmentItems,
  carrierUploadMediaService,
  saveSearch,
  updateSearch,
  getSaveSearches,
  deleteSavedSearch,
  isLoadEditable,
  isLoadDeletable,
  isLoadHasBOL,
  generateBOL,
  getLoadCopyUrl,
  carrierGraphData,
  dashboardData,
  removeTruckerPrefix,
  generateBolUrl,
  getMultipolygonCoords,
  getDeleteJobReasons,
  cancelJobService,
  getShipmentStatusService,
  getSavedLoadsService,
  getStateAbbreviation,
  getShipmentHeaderService,
  getOnlineBOL,
  savePickupContactDetails,
  getPickupContactDetails,
  saveDropContactDetails,
  getDropContactDetails,
  isLoadPayable,
  markJobAsPaid,
  isLoadArchivable,
  archiveJob,
  isLoadUnarchivable,
  unArchiveJob,
  savePickupRecentSearch,
  getPickupRecentSearches,
  saveDropRecentSearch,
  getDropRecentSearches,
  saveMyCurrentLocation,
  getMyCurrentLocation,
  isLoadMarkableToDelivered,
  isLoadMarkableToActive,
  isLoadMarkableToActiveFromDelivered,
  isLoadMarkableToNewFromActive,
  isLoadInvoicable,
  isAnyItemInvoiced,
  jobInvoiced,
  isLoadUninvoicable,
  jobUninvoiced,
  getAdminLoadDetailUrl,
  AdminGraphData,
  getShipmentHeaderServiceAdmin,
  getAdminShipmentStatusService,
  getPaymentOptions,
  companyFromAddressService,
  StreetNoFromAddressService,
  routeFromAddressService,
  getTotalCustomers,
  sendInvoice,
  removeInvoicePrefix,
  isAccountPremium,
  isLoadAssignable,
  isLoadOffered
};
