import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { getCarrierUploadedDocuments } from 'reducers/User';
import { Box, Typography } from '@material-ui/core';

import { ReactComponent as UploadIcon } from 'assets/images/job/upload.svg';
import ButtonWithBackground from 'components/Form/Button';
import { uploadFileService, validFileTypes } from 'services/files';
import { getUserIdService, getUserTypeService } from 'services/login';
import { carrierUploadMediaService } from 'services/Shipments';
import { ERROR, S3_BUCKET_DIR, SUCCESS } from 'utils/constants';
import { errorMessageSingleLine } from 'utils/errorUtils';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';
import { BROKER } from 'utils/users';

const Documents = ({
  setSnackbar,
  getCarrierUploadedDocuments,
  documents,
  documents_loading
}) => {
  const [files, setFiles] = useState([]);
  const [uploadingFilesLoading, setUploadingFilesLoading] = useState(false);

  const { getRootProps, getInputProps, acceptedFiles, open } = useDropzone({
    accept: validFileTypes().splice(','),
    noClick: true,
    multiple: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const uploadImages = async () => {
    setUploadingFilesLoading(true);
    try {
      for (const file of files) {
        const bucket_url = await uploadFileService(
          file.name,
          S3_BUCKET_DIR.CARRIER_MEDIA,
          file
        );

        getUserTypeService(false) === BROKER
          ? await carrierUploadMediaService({
              s3_bucket_url: bucket_url,
              broker: getUserIdService()
            })
          : await carrierUploadMediaService({
              s3_bucket_url: bucket_url,
              carrier: getUserIdService()
            });
      }
      setFiles([]);
      getUploadedDocuments();
      setSnackbar({
        type: SUCCESS,
        message: 'Documents uploaded!'
      });
    } catch (e) {
      setSnackbar({
        type: ERROR,
        message: errorMessageSingleLine()
      });
    }
    setUploadingFilesLoading(false);
  };

  const getUploadedDocuments = () =>
    getCarrierUploadedDocuments(getUserIdService());

  useEffect(() => {
    getUploadedDocuments();
  }, []);

  return null;

  return (
    <Box>
      <div className="dropzone">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="dz-message" onClick={open}>
            {files.length ? (
              <Typography variant="h4">
                {files.length} file(s) selected!
              </Typography>
            ) : (
              <>
                <UploadIcon />
                <Typography variant="h5">Drag & Drop files here</Typography>
                <Typography variant="h5">Or</Typography>
                <Typography variant="h5">Browse</Typography>
              </>
            )}
          </div>
        </div>
      </div>
      <ButtonWithBackground
        label="Upload"
        size="large"
        style={{ marginTop: 20, width: 200, marginRight: 8 }}
        disabledOnly={files.length === 0}
        disabled={uploadingFilesLoading}
        type="border"
        clickHandler={uploadImages}
      />
      {documents.length ? (
        <Box style={{ marginTop: 20 }}>
          <Typography variant="h4">My Documents</Typography>
          {documents.map((doc, index) => {
            return (
              <Box key={index}>
                <Link to={{ pathname: doc }} target="_blank">
                  <Typography style={{ marginBottom: 4, marginTop: 4 }}>
                    {
                      doc
                        .split('-')
                        .pop()
                        .split('?')[0]
                    }
                  </Typography>
                </Link>
              </Box>
            );
          })}
        </Box>
      ) : null}
      {documents_loading && (
        <Box>
          <Skeleton />
          <Skeleton />
          <Skeleton width={250} />
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = state => ({
  documents: state.User.documents,
  documents_loading: state.User.documents_loading
});

const mapDispatchToProps = dispatch => {
  return {
    getCarrierUploadedDocuments: user_id =>
      dispatch(getCarrierUploadedDocuments(user_id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
