import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import ButtonWithBackground from 'components/Form/Button';
import { reportBug, uploadFileService } from 'services/files';
import { S3_BUCKET_DIR } from 'utils/constants';
import { ReactComponent as RemoveIcon } from 'assets/images/common/crossIcon.svg';
import { getBrowser, getOperatingSystem, getUserOSVersion } from 'utils/systemUtils';
import { validateEmail } from 'utils/validationUtils';
import { CircularProgress } from '@material-ui/core';

const Bug = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [operatingSystem, setOperatingSystem] = useState('');
    const [previewImage, setPreviewImage] = useState(false);
    const [showUploadButton, setShowUploadButton] = useState(true);
    const [osVersion, setOSVersion] = useState('');
    const [browser, setBrowser] = useState('');
    const [appVersion, setAppVersion] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleEmailChange = event => {
        const value = event.target.value;
        setEmail(value);
        setEmailError('');
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }
        try {
            setLoading(true);
            await uploadImages(files);
            const response = await reportBug(email, operatingSystem, osVersion, browser, appVersion, message, s3BucketUrls);
            if (response && !response.error) {
                setSubmitted(true);
                setLoading(false);
            }
            setTimeout(() => {
                setSubmitted(false);
                setEmail('');
                setMessage('');
                setFiles('')
                setPreviewImage(false)
                setShowUploadButton(true)
            }, 1500);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleUploadButtonClick = () => {
        open();
    };

    const handleRemovePreview = () => {
        setPreviewImage(null);
        setPreviewImage(false)
        setShowUploadButton(true);

    };
    const { getRootProps, getInputProps, acceptedFiles, open } = useDropzone({
        accept: '.jpeg, .jpg, .png, .pdf',
        noClick: true,
        multiple: false,
        noKeyboard: true,
        onDrop: (acceptedFiles) => {
            setFiles(acceptedFiles);
            setShowUploadButton(false);

            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.onload = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    });

    const s3BucketUrls = [];
    const uploadImages = async (files) => {
        if (files.length === 0) {
            return;
        }
        const file = files[0];
        const uploadedFiles = [];
        try {
            const res = await uploadFileService(
                file.name,
                S3_BUCKET_DIR.BUGS,
                file
            );
            const uploadedFileData = {
                s3_bucket_url: res,
                file_name: file.name,
                file_size: file.size
            };
            uploadedFiles.push(uploadedFileData);
            s3BucketUrls.push(res);
        } catch (error) {
        }
    };
    useEffect(() => {
        const os = getOperatingSystem();
        const browser = getBrowser();
        setOperatingSystem(os);
        setBrowser(browser);
    }, []);

    useEffect(() => {
        const version = process.env.REACT_APP_APP_VER;
        setAppVersion(version);
    }, []);

    useEffect(() => {
        const osVersion = getUserOSVersion();
        setOSVersion(osVersion)
    }, []);

    return (
        <>
            <Box className={clsx(classes.box, 'container')}>
                <Typography className={classes.text}>
                    Hit a road Bump? Let us know the details so we can work to get this fixed in no time.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xs={12} sm={12} style={{ paddingTop: 4, paddingBottom: 12 }}>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                variant="outlined"
                                placeholder="Email address*"
                                fullWidth
                                className={clsx('inpt-fld fld-active', classes.textfield)}
                                margin="normal"
                                required
                                value={email}
                                onChange={handleEmailChange}
                                error={Boolean(emailError)}
                            />
                            <TextField
                                variant="outlined"
                                placeholder="Description of the issue*"
                                fullWidth
                                className={clsx('inpt-fld fld-active', classes.textfield)}
                                multiline
                                rows={12}
                                value={message}
                                onChange={event => setMessage(event.target.value)}
                                margin="normal"
                                required
                            />
                            <Box className={classes.buttongroup}>
                                <Grid container>
                                    {previewImage && (
                                        <div className={classes.previewContainer}>
                                            <img src={previewImage} alt="Preview" className={classes.img} />
                                            <div className={classes.removePreview}>
                                                <RemoveIcon onClick={handleRemovePreview} />
                                            </div>
                                        </div>
                                    )}
                                </Grid>
                                {!previewImage && showUploadButton && (
                                    <ButtonWithBackground
                                        color="primary"
                                        type="border"
                                        label="+Add a screenShot"
                                        className={classes.sbtButton}
                                        clickHandler={handleUploadButtonClick}
                                    />
                                )}
                                <div>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                    </div>
                                </div>
                                <Button
                                    type="submit"
                                    disabled={!validateEmail(email) || !message || loading || submitted}
                                    style={{ background: !validateEmail(email) || !message ? '#D0D0D0' : '#ED3D2E', color: !validateEmail(email) || !message ? '#959595' : '#fff', fontWeight: 600 }}
                                    className={classes.sbtButton2}
                                    onClick={handleSubmit}>
                                    {loading ? (
                                        < CircularProgress size={25} color='inherit' thickness={4} />
                                    ) : (
                                        submitted ? 'Thank you for your feedback' : 'Submit Feedback')}
                                </Button>
                            </Box>
                        </form>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    box: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: -30,
        paddingLeft: 0
    },
    text: {
        fontSize: 14,
        color: '#71828A',
        fontWeight: 500,
        minWidth: 600,
        marginBottom: 12
    },
    textfield: {
        minWidth: 600,
        minHeight: 40,
        fontSize: '14!important',
        color: '#9F9F9F!important',
        fontWeight: '800!important',
        background: '#F0F5F9',
        borderRadius: '11px',
        '& .MuiOutlinedInput-root': {
            border: 'none',
            borderRadius: 11
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderRadius: 11
        },
    },
    sbtButton: {
        minWidth: 600,
        paddingTop: 8,
        marginTop: 8,
        paddingBottom: 8,
        border: '3px solid',
        borderRadius: '11px',
        marginTop: 10,
        '&:hover': {
            border: '3px solid',
        }
    },
    sbtButton2: {
        minWidth: 600,
        paddingTop: 8,
        paddingBottom: 8,
        borderRadius: '11px',
        marginTop: 8,
        color: '#959595',
        background: '#D0D0D0',
    },
    buttongroup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'left',
    },
    previewContainer: {
        position: 'relative',
        display: 'inline-block',
        width: 200,
        height: 150,
        marginTop: 20,
        marginBottom: 20,
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        border: '1px solid #D1D1D1',
        borderRadius: '17px',
        paddingTop: 10,
        paddingLeft: 10
    },
    removePreview: {
        position: 'absolute',
        top: 0,
        right: 4,
        cursor: 'pointer',
    },
}));
export default Bug
