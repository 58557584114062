import axiosClient from 'utils/axiosClient';
import { PREFIX_TRUCK } from 'utils/constants';
import { errorMessageSingleLine } from 'utils/errorUtils';

const version = process.env.REACT_APP_API_VER;

//declarations
const SET_AVAILABLE_TRUCKS = 'AVAILABLE_TRUCKS';
const SET_ERROR = 'SET_ERROR_TRUCK';
const SET_AVAILABLE_TRUCKS_LOADING = 'AVAILABLE_TRUCKS_LOADING';
const SET_TRUCKERS_LOADING = 'SET_TRUCKERS_LOADING';
const SET_TRUCKERS = 'SET_TRUCKERS';
const SET_ASSIGN_TRUCKER_LOADING = 'SET_ASSIGN_TRUCKER_LOADING';
const SET_TRUCKER_ASSIGN_MODAL_VISIBLE = 'SET_TRUCKER_ASSIGN_MODAL_VISIBLE';
const SET_EDIT_TRUCKER_MODAL_VISIBLE = 'SET_EDIT_TRUCKER_MODAL_VISIBLE';
const SET_TRUCKER_ASSIGNED = 'SET_TRUCKER_ASSIGNED';
const SET_ADD_NEW_DRIVER_VIEW_VISIBLE = 'SET_ADD_NEW_DRIVER_VIEW_VISIBLE';
const SET_DRIVER = 'SET_DRIVER';
const ADD_DRIVER_LOADING = 'ADD_DRIVER_LOADING';
const SET_ASSIGNED_TRUCKER_INFO_LOADING = 'SET_ASSIGNED_TRUCKER_INFO_LOADING';
const SET_ASSIGNED_TRUCKER_INFO = 'SET_ASSIGNED_TRUCKER_INFO';
const IWILLDRIVE_LOADING = 'IWILLDRIVE_LOADING';
const SET_TRUCK_TYPES = 'SET_TRUCK_TYPES';
const POST_TRUCK_LOADING = 'POST_TRUCK_LOADING';
const POST_TRUCK = 'POST_TRUCK';
const TRUCKS = 'TRUCKS';
const TRUCKS_LOADING = 'TRUCKS_LOADING';
const TRUCK = 'TRUCK';
const TRUCK_LOADING = 'TRUCK_LOADING';
const DELETE_TRUCK_LOADING = 'DELETE_TRUCK_LOADING';
const DELETE_TRUCK = 'DELETE_TRUCK';
const LOAD_MORE_TRUCKERS = 'LOAD_MORE_TRUCKERS';
const LOAD_MORE_TRUCKERS_LOADING = 'LOAD_MORE_TRUCKERS_LOADING';
const TRUCKS_CONFIGS = 'TRUCKS_CONFIGS';
const TRUCKS_CONFIG_LOADING = 'TRUCKS_CONFIG_LOADING';
const TRUCKS_LOADMORE_LOADING = 'TRUCKS_LOADMORE_LOADING';
const TRUCKS_LOADMORE = 'TRUCKS_LOADMORE';
const TRUCK_VIEWED = 'TRUCK_VIEWED';
const TRUCKS_AVAILABLE_LOADMORE = 'TRUCKS_AVAILABLE_LOADMORE';
const MAX_DRIVER_LIMIT = 'MAX_DRIVER_LIMIT';
const UNASSIGNED_TRUCKER_LOADING = 'UNASSIGNED_TRUCKER_LOADING';
const UNASSIGNED_TRUCKER = 'UNASSIGNED_TRUCKER';

//initial state
const initialState = {
  available_trucks: null,
  error: '',
  message: '',
  available_trucks_loading: false,
  truckers: null,
  truckers_loading: false,
  assigned_trucker_loading_id: '',
  truckerAssignModalOpen: false,
  EditTruckerModalOpen: false,
  addNewDriverViewVisible: false,
  add_driver_loading: false,
  trucker_assigned_info_loading: false,
  trucker_assigned: null,
  trucker_assigned_info: null,
  i_will_drive_loading: false,
  truck_types: [],
  transport_types: [],
  postTruckLoading: false,
  postTruck: null,
  driver: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    truck_licence: '',
    truck_capacity: 0,
    pay_out: 0,
    profile_pic_url: '',
    truck_type: ''
  },
  trucks: null,
  trucks_loading: false,
  truck: null,
  truck_loading: false,
  deleteTruckLoading: false,
  deletedTruckId: '',
  loadMoreTruckersLoading: false,
  trucksConfigLoading: false,
  destination_radius: [],
  origin_radius: [],
  regions: [],
  truck_destination_option: [],
  truck_capacities: [],
  trucksLoadmoreLoading: false,
  updateDriverName: false,
  maxDriverLimitLoading: false,
  maxDriverLimit: false,
  unassignTruckerLoading: false,
  truckerUnassigned: false
};

export const getTruckTypes = () => {
  return async dispatch => {
    const response = await axiosClient.get(`/truck/${version}/config/`, {
      params: { truck_type: true }
    });

    dispatch({
      type: SET_TRUCK_TYPES,
      data: response.data.truck_type
    });
  };
};

// Post truck.
export const postTruck = data => {
  return async dispatch => {
    dispatch({
      type: POST_TRUCK_LOADING
    });
    try {
      const response = await axiosClient.post(`/truck/${version}/`, data);
      dispatch({
        type: POST_TRUCK,
        data: response.data,
        message: 'Truck has been posted!'
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

// Update truck.
export const updateTruck = (truck_id, data) => {
  return async dispatch => {
    dispatch({
      type: POST_TRUCK_LOADING
    });
    try {
      const response = await axiosClient.put(
        `/truck/${version}/${PREFIX_TRUCK}${truck_id}/update/`,
        data
      );
      dispatch({
        type: POST_TRUCK,
        data: response.data,
        message: 'Truck has been updated!'
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

// Get available trucks.
export const getTrucks = (loadMoreUrl = '') => {
  return async dispatch => {
    dispatch({
      type: TRUCKS_LOADING
    });
    try {
      let response = null;
      if (loadMoreUrl) response = await axiosClient.get(loadMoreUrl);
      else
        response = await axiosClient.get(`/truck/${version}/`, {
          params: {
            ordering: '-created_at'
          }
        });
      dispatch({
        type: TRUCKS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const loadMoreTrucks = loadMoreUrl => {
  return async dispatch => {
    dispatch({
      type: TRUCKS_LOADMORE_LOADING
    });
    try {
      const response = await axiosClient.get(loadMoreUrl);

      dispatch({
        type: TRUCKS_LOADMORE,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const loadMoreAvailableTrucks = loadMoreUrl => {
  return async dispatch => {
    dispatch({
      type: TRUCKS_LOADMORE_LOADING
    });
    try {
      const response = await axiosClient.get(loadMoreUrl);

      dispatch({
        type: TRUCKS_AVAILABLE_LOADMORE,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const getTruckConfigs = data => {
  return async dispatch => {
    dispatch({
      type: TRUCKS_CONFIG_LOADING
    });
    try {
      let response = await axiosClient.get(`/truck/${version}/config/`, {
        params: data
      });

      dispatch({
        type: TRUCKS_CONFIGS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

// Get truck.
export const getTruck = truck_id => {
  return async dispatch => {
    dispatch({
      type: TRUCK_LOADING
    });
    try {
      const response = await axiosClient.get(`/truck/${version}/${truck_id}`);
      dispatch({
        type: TRUCK,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

// Mark a truck as viewed.
export const markTruckAsViewed = truck => {
  return async dispatch => {
    if (truck.is_viewed) return;

    try {
      const response = await axiosClient.post(
        `/truck/${version}/${truck.truck_id}/add/view/`
      );
      dispatch({
        type: TRUCK_VIEWED,
        data: response.data
      });
    } catch (error) {}
  };
};

// Get available trucks.
export const getAvailableTrucks = data => {
  return async dispatch => {
    dispatch({
      type: SET_AVAILABLE_TRUCKS_LOADING
    });
    try {
      const response = await axiosClient.get(`/truck/${version}/available/`, {
        params: data
      });
      dispatch({
        type: SET_AVAILABLE_TRUCKS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const getTruckers = owner_id => {
  return async dispatch => {
    dispatch({ type: SET_TRUCKERS_LOADING });

    try {
      const response = await axiosClient.get(`/trucker/${version}/`, {
        params: {
          owner: owner_id,
          limit: 20,
          ordering: '-updated_at'
        }
      });

      dispatch({ type: SET_TRUCKERS, data: response.data });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.statusText)
      });
    }
  };
};

export const loadMoreTruckers = next => {
  return async dispatch => {
    dispatch({ type: LOAD_MORE_TRUCKERS_LOADING });

    try {
      const response = await axiosClient.get(next);

      dispatch({ type: LOAD_MORE_TRUCKERS, data: response.data });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.statusText)
      });
    }
  };
};

export const iWillDrive = trucker_id => {
  return async dispatch => {
    dispatch({ type: IWILLDRIVE_LOADING });
    try {
      return await axiosClient.get(`/trucker/${version}/${trucker_id}/`);
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.statusText)
      });
    }
  };
};

export const getAssignedTruckerInfo = (trucker_id = null) => {
  return async dispatch => {
    if (!trucker_id) {
      dispatch({
        type: SET_ASSIGNED_TRUCKER_INFO,
        data: null
      });
      return;
    }

    dispatch({ type: SET_ASSIGNED_TRUCKER_INFO_LOADING });
    try {
      const response = await axiosClient.get(
        `/trucker/${version}/${trucker_id}/`
      );
      dispatch({
        type: SET_ASSIGNED_TRUCKER_INFO,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.statusText)
      });
    }
  };
};

export const addDriver = driver => {
  return async dispatch => {
    dispatch({ type: ADD_DRIVER_LOADING });
    try {
      const response = await axiosClient.post(`/trucker/${version}/`, driver);
      if (response && response.data) {
        dispatch(assignTrucker(response.data.trucker_id, driver.shipment_id));
      }
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const assignTrucker = (trucker_id, shipment_id) => {
  return async dispatch => {
    dispatch({ type: SET_ASSIGN_TRUCKER_LOADING, data: trucker_id });

    try {
      const response = await axiosClient.put(
        `/shipment/${version}/${shipment_id}/truck_assign/`,
        { trucker_id }
      );

      dispatch(setAssignedTrucker(response.data));
    } catch (error) {

      if (error.response.data.error_code === "MAX_DRIVERS") {
        dispatch({
          type: MAX_DRIVER_LIMIT
        });
      } else {
        dispatch({
          type: SET_ERROR,
          data: errorMessageSingleLine(error.response.data)
        });
      }
    }
  };
};

export const unAssignTrucker = (shipment_id) => {
  return async dispatch => {
    dispatch({ type: UNASSIGNED_TRUCKER_LOADING });

    try {
      const response = await axiosClient.put(
        `/shipment/${version}/${shipment_id}/truck_unassign/`
      );
      dispatch({
        type: UNASSIGNED_TRUCKER,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response.data)
      });
    }
  };
};

export const deleteTruck = truck_id => {
  return async dispatch => {
    dispatch({
      type: DELETE_TRUCK_LOADING
    });
    try {
      const response = await axiosClient.delete(
        `/truck/${version}/${truck_id}/delete/`
      );

      dispatch({
        type: DELETE_TRUCK,
        data: truck_id
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const setAssignedTrucker = data => dispatch =>
  dispatch({ type: SET_TRUCKER_ASSIGNED, data });

export const setTruckerAssignModalOpen = boolean => dispatch =>
  dispatch({ type: SET_TRUCKER_ASSIGN_MODAL_VISIBLE, data: boolean });

export const setAddNewDriverViewVisible = boolean => dispatch =>
  dispatch({ type: SET_ADD_NEW_DRIVER_VIEW_VISIBLE, data: boolean });

export const setEditTruckerModelOpen = (boolean) => dispatch => {
  dispatch({ type: SET_EDIT_TRUCKER_MODAL_VISIBLE, data: boolean });
}
export const setDriver = data => dispatch => {
  dispatch({ type: SET_DRIVER, data });
};

//reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_AVAILABLE_TRUCKS:
      return {
        ...state,
        available_trucks_loading: false,
        error: '',
        available_trucks: action.data
      };
    case TRUCK_VIEWED:
      const availableTrucks = { ...state.available_trucks };
      const updatedAvailableTrucks = availableTrucks.results.map(truck => {
        if (truck.truck_id === action.data.truck_id) {
          truck.is_viewed = action.data.is_viewed;
          truck.views = action.data.views;
        }
        return truck;
      });
      return {
        ...state,
        available_trucks: {
          ...availableTrucks,
          results: updatedAvailableTrucks
        }
      };
    case SET_TRUCK_TYPES:
      return {
        ...state,
        truck_types: action.data
      };
    case SET_DRIVER:
      return {
        ...state,
        driver: action.data
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.data,
        available_trucks_loading: false,
        truckers_loading: false,
        assigned_trucker_loading_id: '',
        add_driver_loading: false,
        trucker_assigned_info_loading: false,
        i_will_drive_loading: false,
        postTruckLoading: false,
        trucks_loading: false,
        truck_loading: false,
        deleteTruckLoading: false,
        loadMoreTruckersLoading: false,
        trucksConfigLoading: false,
        trucksLoadmoreLoading: false
      };
    case SET_ASSIGN_TRUCKER_LOADING:
      return {
        ...state,
        error: '',
        assigned_trucker_loading_id: action.data,
        maxDriverLimitLoading:true,
        maxDriverLimit: false
      };
    case TRUCKS_LOADMORE_LOADING:
      return {
        ...state,
        trucksLoadmoreLoading: true
      };
    case TRUCKS_CONFIG_LOADING:
      return {
        ...state,
        trucksConfigLoading: true
      };
    case LOAD_MORE_TRUCKERS_LOADING:
      return {
        ...state,
        loadMoreTruckersLoading: true
      };
    case POST_TRUCK_LOADING:
      return {
        ...state,
        error: '',
        message: '',
        postTruck: null,
        postTruckLoading: true
      };
    case POST_TRUCK:
      return {
        ...state,
        error: '',
        postTruckLoading: false,
        postTruck: action.data,
        message: action.message
      };
    case MAX_DRIVER_LIMIT:
     return {
        ...state,
        maxDriverLimitLoading:false,
        assigned_trucker_loading_id: '',
        i_will_drive_loading: false,
        add_driver_loading: false,
         maxDriverLimit:true,
       };
    case SET_TRUCKER_ASSIGNED:
      return {
        ...state,
        assigned_trucker_loading_id: '',
        add_driver_loading: false,
        trucker_assigned: action.data,
        i_will_drive_loading: false,
        maxDriverLimitLoading:false,
        maxDriverLimit:false
      };
    case UNASSIGNED_TRUCKER_LOADING:
      return {
        ...state,
        truckerUnassigned:false,
        unassignTruckerLoading: true,
      };
    case UNASSIGNED_TRUCKER:
      return {
        ...state,
        unassignTruckerLoading: false,
        truckerUnassigned: true,
        EditTruckerModalOpen: false,
        trucker_assigned:null,
        trucker_assigned_info:null
      };
    case SET_ASSIGNED_TRUCKER_INFO:
      return {
        ...state,
        trucker_assigned_info: action.data,
        trucker_assigned_info_loading: false,
        updateDriverName:true,
        maxDriverLimit:false
          };
    case TRUCKS_CONFIGS:
      const configData = action.data;
      return {
        ...state,
        trucksConfigLoading: false,
        destination_radius: configData.destination_radius,
        origin_radius: configData.origin_radius,
        regions: configData.regions,
        truck_destination_option: configData.truck_destination_option,
        truck_capacities: configData.capacity,
        truck_types: configData.truck_type,
        transport_types: configData.transport_type
      };
    case SET_AVAILABLE_TRUCKS_LOADING:
      return {
        ...state,
        available_trucks_loading: true,
        error: '',
        message: ''
      };
    case SET_ASSIGNED_TRUCKER_INFO_LOADING:
      return {
        ...state,
        trucker_assigned_info_loading: true,
        trucker_assigned_info: null,
         };
    case SET_EDIT_TRUCKER_MODAL_VISIBLE:
      return {
        ...state,
        EditTruckerModalOpen: action.data,
      };
   case SET_TRUCKERS:
      return {
        ...state,
        truckers_loading: false,
        truckers: action.data
      };
    case LOAD_MORE_TRUCKERS:
      return {
        ...state,
        truckers_loading: false,
        loadMoreTruckersLoading: false,
        truckers: {
          ...action.data,
          results: state.truckers.results.concat(action.data.results)
        }
      };
    case SET_ADD_NEW_DRIVER_VIEW_VISIBLE:
      return {
        ...state,
        addNewDriverViewVisible: action.data
      };
    case SET_TRUCKER_ASSIGN_MODAL_VISIBLE:
      return {
        ...state,
        truckerAssignModalOpen: action.data,
      };
    case IWILLDRIVE_LOADING:
      return {
        ...state,
        error: '',
        i_will_drive_loading: true
      };
    case ADD_DRIVER_LOADING:
      return {
        ...state,
        error: '',
        add_driver_loading: true
      };
    case SET_TRUCKERS_LOADING:
      return {
        ...state,
        error: '',
        truckers_loading: true
      };
    case TRUCKS_LOADING:
      return {
        ...state,
        error: '',
        message: '',
        trucks_loading: true
      };
    case TRUCKS:
      return {
        ...state,
        trucks: action.data,
        trucks_loading: false
      };

    case TRUCKS_LOADMORE:
      return {
        ...state,
        trucksLoadmoreLoading: false,
        trucks: {
          ...action.data,
          results: state.trucks.results.concat(action.data.results)
        }
      };

    case TRUCKS_AVAILABLE_LOADMORE:
      return {
        ...state,
        trucksLoadmoreLoading: false,
        available_trucks: {
          ...action.data,
          results: state.available_trucks.results.concat(action.data.results)
        }
      };
    case TRUCK_LOADING:
      return {
        ...state,
        error: '',
        message: '',
        truck_loading: true,
        truck: null
      };
    case TRUCK:
      return {
        ...state,
        truck: action.data,
        truck_loading: false
      };
    case DELETE_TRUCK_LOADING:
      return {
        ...state,
        error: '',
        message: '',
        deleteTruckLoading: true
      };
    case DELETE_TRUCK:
      return {
        ...state,
        error: '',
        message: '',
        deleteTruckLoading: false,
        deletedTruckId: action.data
      };
    default:
      return state;
  }
}
