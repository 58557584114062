import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
  ONBOARDING_PAGE,
  LOGIN_PAGE,
  DASHBOARD_PAGE,
  LOADS,
  POST_JOB_PAGE,
  JOB_EDIT_PAGE,
  JOB_COPY_PAGE,
  INBOX_PAGE,
  TRUCKS_ADD_PAGE,
  TRUCKS_PAGE,
  SAVED_LOADS_PAGE
} from 'utils/routesUtils';
import {
  getUserTypeService,
  getUserIdService,
  checkUserTokenService,
  saveFCMToken
} from 'services/login';
import { getUserDetailService } from 'services/users';
import { connect } from 'react-redux';
import PageLoader from 'components/FullPageLoader';

import { Header } from '../index';
import Sidebar from 'components/Sidebar';
import Backdrop from 'components/Backdrop';
import { getUserConfig, setUserData } from 'reducers/User';
import {
  PENDING_EMAIL,
  PERMISSION,
  RIDER_INFO,
  RIDER_INFO_SKIPPED,
  TOKEN_TYPE,
  UPLOAD_DOCUMENTS
} from 'utils/constants';
import { errorMessageSingleLine } from 'utils/errorUtils';
import { ADMIN } from 'utils/users';

// Reducers.
import { getTruckConfigs } from 'reducers/Truck';
import { getCategories } from 'reducers/Job';
import { getNotifications, setFcmToken } from 'reducers/Inbox';
import firebase from 'firebase/app';
import 'firebase/messaging';
import DocumentVerificationDialogue from 'layout/Header/DocumentVerificationDialogue';
import TwilioInit from './TwilioInit';
import PaymentUpdateDialogue from 'layout/Header/HeaderUserbox/PaymentUpdateDialogue';

const vapidKey = process.env.REACT_APP_FIREBASE_VAPIDKEY;

firebase.initializeApp({
  apiKey: 'AIzaSyC8f0GQsxkX5peUix2GM6HnESh1WUWt7oQ',
  authDomain: 'roadcall.firebaseapp.com',
  projectId: 'roadcall',
  storageBucket: 'roadcall.appspot.com',
  messagingSenderId: '515340204617',
  appId: '1:515340204617:web:75eaebeb9b7a26e53add98',
  measurementId: 'G-9SXEQB5RL8'
});

//firebase.initializeApp(window.firebaseConfig);

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

const LeftSidebar = ({
  children,
  sidebarToggle,
  sidebarFixed,
  footerFixed,
  contentBackground,
  contentLoader,
  setUserData,
  setSnackbar,
  getCategories,
  getNotifications,
  getTruckConfigs,
  getUserConfig,
  setFcmToken,
  fcmToken,
  client
}) => {
  const history = useHistory();
  const location = useLocation();
  const [userDetailLoading, setUserDetailLoading] = useState(false);

  const userType = getUserTypeService(false);
  const userId = getUserIdService();

  const registerPushToken = () =>
    messaging
      .getToken({ vapidKey })
      .then(token => {
        if (token) {
          setFcmToken(token);
          saveFCMToken({
            device_token: token,
            device_type: TOKEN_TYPE.WEB
          });
        }
      })
      .catch(err => {
        console.log(err);
      });

  const getUserDetails = async () => {
    try {
      getCategories();
      if (checkUserTokenService() === false) {
        history.push(LOGIN_PAGE);
        return false;
      }

      if (userType === ADMIN) return false;

      setUserDetailLoading(true);

      const res = await getUserDetailService(userId, userType);
      setUserData(res);

      getTruckConfigs({
        destination_options: true,
        regions: true,
        origin_radius: true,
        destination_radius: true,
        capacity: true,
        truck_type: true,
        transport_type: true
      });

      getUserConfig({
        role: true
      });

      getNotifications({
        ordering: '-created_at',
        limit: 5
      });
      if (res.onboarding_step === PENDING_EMAIL) {
        history.push(LOGIN_PAGE);
        return false;
      } else if (
        !(
          res.onboarding_step === UPLOAD_DOCUMENTS ||
          res.onboarding_step === RIDER_INFO ||
          res.onboarding_step === RIDER_INFO_SKIPPED
        )
      ) {
        history.push(ONBOARDING_PAGE);
        return false;
      }
    } catch (e) {
      setSnackbar({
        type: 'error',
        message: errorMessageSingleLine(e?.message)
      });
      history.push(LOGIN_PAGE);
    }

    setUserDetailLoading(false);
  };

  useEffect(() => {
    getUserDetails();

    if (messaging) {
      // Ask for push notification & Register token.
      Notification.requestPermission().then(permission => {
        if (permission === PERMISSION.GRANTED) registerPushToken();
      });

      messaging.onTokenRefresh(() => {
        registerPushToken();
      });
    }
  }, []);

  useEffect(() => {
    if (client && fcmToken) {
      client.setPushRegistrationId('fcm', fcmToken);
      messaging.onMessage(payload => {
        client.handlePushNotification(payload);
      });
    }
  }, [client, fcmToken]);

  const getBgColor = () => {
    if (
      history.location.pathname === DASHBOARD_PAGE ||
      history.location.pathname === LOADS ||
      history.location.pathname === POST_JOB_PAGE ||
      history.location.pathname === JOB_EDIT_PAGE ||
      history.location.pathname === JOB_COPY_PAGE ||
      history.location.pathname === INBOX_PAGE ||
      history.location.pathname === TRUCKS_ADD_PAGE ||
      history.location.pathname === TRUCKS_PAGE ||
      history.location.pathname === SAVED_LOADS_PAGE
    )
      return '#f5f8fa';

    return '#fff';
  };
  const isPaymentTab = location.pathname === '/settings' &&
    location.search === '?tab=payment';

  if (userDetailLoading) return <PageLoader />;

  return (
    <div className={clsx('app-wrapper', contentBackground)}>
      <TwilioInit />
      <DocumentVerificationDialogue />
      {!isPaymentTab && (
        <PaymentUpdateDialogue />
      )}
      
      <div
        className={clsx('app-main', {
          'app-main-sidebar-static': !sidebarFixed
        })}>
        <Header setSnackbar={setSnackbar} />

        {contentLoader && <Backdrop />}

        <Sidebar />
        <div
          style={{ background: getBgColor() }}
          className={clsx('app-content', {
            'app-content-sidebar-collapsed': sidebarToggle,
            'app-content-sidebar-fixed': sidebarFixed,
            'app-content-footer-fixed': footerFixed
          })}>
          {children}
          {/* <Footer /> */}
        </div>
      </div>
    </div>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = state => ({
  userData: state.User.user_data,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,

  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground,
  contentLoader: state.ThemeOptions.contentLoader,
  fcmToken: state.Inbox.fcmToken,
  client: state.Inbox.client
});

const mapDispatchToProps = dispatch => {
  return {
    getUserConfig: data => dispatch(getUserConfig(data)),
    setUserData: data => dispatch(setUserData(data)),
    getCategories: () => dispatch(getCategories()),
    getNotifications: params => dispatch(getNotifications(params)),
    getTruckConfigs: data => dispatch(getTruckConfigs(data)),
    setFcmToken: token => dispatch(setFcmToken(token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
