import React from 'react';

// Material UI.
import { makeStyles } from '@material-ui/core';
import CustomTable from 'components/CustomTable';
import TransactionHeader from 'components/TableHeader/TransactionHeader';
import TableDataSkeleton from './Layout/TableData';

const TransactionsLoading = () => {
  const classes = useStyles();

  return (
    <CustomTable isStickyHeader className={classes.table} noBorder>
      <TransactionHeader />
      <TableDataSkeleton column={8} />
    </CustomTable>
  );
};

const useStyles = makeStyles(theme => ({
  table: {
    height: 'calc(100vh - 320px)',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    '& tbody tr td:first-child': {
      borderLeft: '1px solid #eff0f4'
    },
    '& tbody tr td:last-child': {
      borderRight: '1px solid #eff0f4'
    },
    '& thead tr th:first-child': {
      borderTopLeftRadius: 16
    },
    '& thead tr th:last-child': {
      borderTopRightRadius: 16
    },
    '& th': {
      lineHeight: '10px'
    },
    '& thead tr': {
      border: '1px solid #eff0f4'
    }
  }
}));

export default TransactionsLoading;
