import { errorMessageSingleLine } from 'utils/errorUtils';
import axiosClient from 'utils/axiosClient';
import { getUserTypeService } from 'services/login';
import axios from 'axios';
import { APP_TOKEN, PLATFORM, ROADCALL_USER_TOKEN } from 'utils/constants';
const baseUrl = process.env.REACT_APP_API_BASE_URL;

//declarations
const SET_USER_DATA = 'SET_USER_DATA';
const SET_USERS = 'SET_USERS';
const SET_USER = 'SET_USER';
const SET_USER_DATA_LOADING = 'SET_USER_DATA_LOADING';
const UPDATE_PROFILE_LOADING = 'UPDATE_PROFILE_LOADING';
const SET_ERROR = 'SET_ERROR';
const SET_MESSAGE = 'SET_MESSAGE';
const DOCUMENTS_LOADING = 'DOCUMENTS_LOADING';
const DOCUMENTS = 'DOCUMENTS';
const SET_USERS_ORDERING = 'SET_USERS_ORDERING';
const USERS_LOADING = 'USERS_LOADING';
const USER_LOADING = 'USER_LOADING';
const SET_USER_PAYMENT_STATUS = 'SET_USER_PAYMENT_STATUS';
const SET_VALIDATE_PAYMENT_URL_LOADING = 'SET_VALIDATE_PAYMENT_URL_LOADING';
const SET_VALIDATE_PAYMENT_URL_DATA = 'SET_VALIDATE_PAYMENT_URL_DATA';
const SET_VALIDATE_PAYOUT_URL_LOADING = 'SET_VALIDATE_PAYOUT_URL_LOADING';
const SET_VALIDATE_PAYOUT_URL_DATA = 'SET_VALIDATE_PAYOUT_URL_DATA';
const SET_USER_IS_CARD_ADDED = 'SET_USER_IS_CARD_ADDED';
const USER_CONFIG_LOADING = 'USER_CONFIG_LOADING';
const USER_CONFIG = 'USER_CONFIG';
const DOCUMENT_VERIFICATION_VISIBILITY = 'DOCUMENT_VERIFICATION_VISIBILITY';
const PAYMENT_UPDATE_VISIBILITY = 'PAYMENT_UPDATE_VISIBILITY';
const UPDATE_PAYMENT_ISSUE_LOADING = 'UPDATE_PAYMENT_ISSUE_LOADING';
const UPDATE_PAYMENT_ISSUE = 'UPDATE_PAYMENT_ISSUE';
const USER_NOTE_LOADING = 'USER_NOTE_LOADING';
const USER_NOTE = 'USER_NOTE';
const USER_DELETE_NOTE_LOADING = 'USER_DELETE_NOTE_LOADING';
const USER_DELETE_NOTE = 'USER_DELETE_NOTE';
const NOTES_LIST_LOADING = 'NOTES_LIST_LOADING';
const SET_NOTES = 'SET_NOTES'
const EDIT_NOTE = 'EDIT_NOTE';
const CANCEL_EDIT_NOTE_ADMIN = 'CANCEL_EDIT_NOTE_ADMIN';
const ADMIN_NOTE_LOADING = 'ADMIN_NOTE_LOADING';
const ADMIN_NOTE = 'ADMIN_NOTE';
const UPDATE_ADMIN_NOTE = 'UPDATE_ADMIN_NOTE';
const DELETE_USER_ACCOUNT_LOADING = 'DELETE_USER_ACCOUNT_LOADING';
const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';
const DELETE_USER_ACCOUNT_REASON_LOADING = 'DELETE_USER_ACCOUNT_REASON_LOADING';
const DELETE_USER_ACCOUNT_REASON = 'DELETE_USER_ACCOUNT_REASON';
const version = process.env.REACT_APP_API_VER;

//initial state
const initialState = {
  users: null,
  user: null,
  user_loading: false,
  users_loading: false,
  user_data: null, // Logged in user detail.
  user_data_getting: false,
  update_profile_loading: false,
  user_error: '',
  user_message: '',
  documents_loading: false,
  documents: [],
  validatePaymentUrlLoading: false,
  validatePayoutUrlLoading: false,
  userConfigLoading: false,
  user_roles: [],
  showDocumentVerificationDialogue: false,
  showPaymentUpdateDialogue: false,
  updatePaymentIssueLoading: false,
  paymentMethodUpdated: false,
  users_ordering: '-created_at',
  error: '',
  message: '',
  loading: false,
  userNote: '',
  userNoteLoading: false,
  adminNoteLoading: false,
  adminNote: null,
  noteListLoading: false,
  noteList: null,
  editNote: null,
  isNoteEdited: false,
  updateNote: null,
  deletUserAccountLoading: false,
  deleteUserAccount:null,
  deleteAccountReasonLoading:false,
  deleteAccountReason:null
};

//reducer functions
export const setUserData = data => {
  return {
    type: SET_USER_DATA,
    data
  };
};

export const setDocumentVerificationDialogueVisibility = bool => {
  return {
    type: DOCUMENT_VERIFICATION_VISIBILITY,
    data: bool
  };
};

export const setPaymentUpdateVisibility = bool => {
  return {
    type: PAYMENT_UPDATE_VISIBILITY,
    data: bool
  };
};

export const setUserPaymentStatus = value => {
  return {
    type: SET_USER_PAYMENT_STATUS,
    data: value
  };
};

export const setUserIsCardAdded = bool => {
  return {
    type: SET_USER_IS_CARD_ADDED,
    data: bool
  };
};

export const setUsersOrdering = ordering => {
  return {
    type: SET_USERS_ORDERING,
    data: ordering
  };
};

export const setUserDataLoading = data => {
  return {
    type: SET_USER_DATA_LOADING,
    data
  };
};

export const getUsers = (userType, paginate_url = '') => {
  return async dispatch => {
    dispatch({
      type: USERS_LOADING
    });
    try {
      let response = null;
      if (paginate_url) response = await axiosClient.get(paginate_url);
      else
        response = await axiosClient.get(`${userType}/${version}/`, {
          params: {
            ordering: ''
          }
        });
      dispatch({
        type: SET_USERS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('')
      });
    }
  };
};

export const getAllUsers = (
  userStatus,
  filter,
  ordering,
  paginate_url = ''
) => {
  return async dispatch => {
    dispatch({
      type: USERS_LOADING
    });
    try {
      let response = null;
      if (paginate_url) response = await axiosClient.get(paginate_url);
      else
        response = await axiosClient.get(`users/${version}/`, {
          params: {
            verification_status: userStatus,
            ordering,
            search: filter,
            limit: 25
          }
        });
      dispatch({
        type: SET_USERS,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('')
      });
    }
  };
};
export const getUser = (type, id) => {
  return async dispatch => {
    dispatch({
      type: USER_LOADING
    });
    try {
      const response = await axiosClient.get(`${type}/${version}/${id}/`);
      dispatch({
        type: SET_USER,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('')
      });
    }
  };
};

export const getAdminDashBoardData = async () => {
  try {
    const response = await axiosClient.get(
      `dashboard/${version}/admin/data/`,
      {}
    );
    {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateShipperPayment = () => {
  return async dispatch => {
    dispatch({
      type: UPDATE_PAYMENT_ISSUE_LOADING
    });
    try {
      const response = await axiosClient.post(
        `/shipper/${version}/payment/update/`);
      dispatch({
        type: UPDATE_PAYMENT_ISSUE,
        data: response.data
      });
      dispatch(setPaymentUpdateVisibility(false));
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine('')
      });
    }
  };
};

export const addAdminNote = (id, text) => {
  return async dispatch => {
    dispatch({
      type: USER_NOTE_LOADING
    });

    try {
      const response = await axiosClient.post(
        `/dashboard/${version}/create/note/`,
        {
          user_id: id,
          note: text
        }
      );

      dispatch({
        type: USER_NOTE,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const updateAdminNote = (note, noteId) => {
  return async dispatch => {
    dispatch({
      type: ADMIN_NOTE_LOADING
    });
    try {
      const response = await axiosClient.patch(`/dashboard/${version}/note/${noteId}/`, { note});
      dispatch({
        type: UPDATE_ADMIN_NOTE,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const editAdminNote = (note) => {
  return async dispatch => {
    dispatch({
      type: EDIT_NOTE,
      data: note
    });
  };
};

export const cancelEditNoteAdmin = (note) => {
  return async dispatch => {
    dispatch({
      type: CANCEL_EDIT_NOTE_ADMIN,
      data: note
    });
  };
};

export const adminNotesList = (userId) => {
  return async dispatch => {
    dispatch({
      type: NOTES_LIST_LOADING
    });
    try {
      let url = `/dashboard/${version}/notes/?user_id=${userId}`;
      const response = await axiosClient.get(url);
      dispatch({
        type: SET_NOTES,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error?.response?.data?.detail)
      });
    }
  };
};

export const deleteAdminNote = noteId => {
  return async dispatch => {
    dispatch({
      type: USER_DELETE_NOTE_LOADING
    });

    try {
      await axiosClient.delete(`/dashboard/${version}/note/${noteId}/`);

      dispatch({
        type: USER_DELETE_NOTE,
        data: noteId
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const deleteAccount = (message,reason) => {
  return async dispatch => {
    dispatch({
      type: DELETE_USER_ACCOUNT_LOADING
    });
    let url = `/auth/deleteuser/${version}/`
    try {
      const response = await axiosClient.delete(url,
        {
          params: {
            get_params:true,
            delete_feedback: message,
            delete_reason: reason
          }
        });

      dispatch({
        type: DELETE_USER_ACCOUNT,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const deleteAccountReasonList = () => {
  return async dispatch => {
    dispatch({
      type: DELETE_USER_ACCOUNT_REASON_LOADING
    });
    
    try {
      const response = await axiosClient.get(`auth/deletereasons/${version}/`)

      dispatch({
        type: DELETE_USER_ACCOUNT_REASON,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const updateProfile = (user_type, user_id, data) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_PROFILE_LOADING
    });
    try {
      const requestData = {
        ...data,
        platform: PLATFORM
      };

      const response = await axiosClient.put(
        `/${user_type}/${version}/${user_id}/`,
        requestData
      );

      dispatch(setUserData(response.data));
      dispatch({
        type: SET_MESSAGE,
        data: ''
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const getCarrierUploadedDocuments = user_id => {
  return async dispatch => {
    dispatch({
      type: DOCUMENTS_LOADING
    });
    try {
      const response = await axiosClient.get(
        `/${getUserTypeService(false)}/media/${version}/?${getUserTypeService(
          false
        )}_id=${user_id}&limit=100`
      );
      dispatch({
        type: DOCUMENTS,
        data: response.data.signed_urls
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response.data)
      });
    }
  };
};

export const getDocUploaded = (user_type, user_id) => {
  return async dispatch => {
    dispatch({
      type: DOCUMENTS_LOADING
    });
    try {
      const response = await axiosClient.get(
        `/${user_type}/media/${version}/?${user_type}_id=${user_id}&limit=100`
      );
      dispatch({
        type: DOCUMENTS,
        data: response.data.signed_urls
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response.data)
      });
    }
  };
};

export const validatePaymentUrl = random_id => {
  localStorage.removeItem(ROADCALL_USER_TOKEN);
  return async dispatch => {
    dispatch({
      type: SET_VALIDATE_PAYMENT_URL_LOADING
    });
    try {
      const response = await axios.post(
        `${baseUrl}auth/validateurl/${version}/${random_id}/`
      );
      dispatch(setUserData(response.data));
      localStorage.setItem(ROADCALL_USER_TOKEN, JSON.stringify(response.data));
      dispatch({
        type: SET_VALIDATE_PAYMENT_URL_DATA,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response.data)
      });
    }
  };
};

export const validatePayoutUrl = (token) => {
  localStorage.removeItem(ROADCALL_USER_TOKEN);
  return async dispatch => {
    dispatch({
      type: SET_VALIDATE_PAYOUT_URL_LOADING
    });
    try {
      const response = await axios.post(
        `${baseUrl}auth/validate_payouturl/${version}/${token}/`
      );
      dispatch(setUserData(response.data));
      localStorage.setItem(ROADCALL_USER_TOKEN, JSON.stringify(response.data));
      localStorage.setItem(APP_TOKEN,true)
      dispatch({
        type: SET_VALIDATE_PAYOUT_URL_DATA,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response.data)
      });
    }
  };
};
export const getUserConfig = data => {
  return async dispatch => {
    dispatch({
      type: USER_CONFIG_LOADING
    });
    try {
      let response = await axiosClient.get(`/carrier/${version}/config/`, {
        params: data
      });

      dispatch({
        type: USER_CONFIG,
        data: response.data
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

export const uploadVerificationDocuments = data => {
  return async dispatch => {
    // dispatch({
    //   type: USER_CONFIG_LOADING
    // });
    try {
      let response = await axiosClient.post(
        `/carrier/${version}/verification/documents/`,
        data
      );

      // dispatch({
      //   type: USER_CONFIG,
      //   data: response.data
      // });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.data)
      });
    }
  };
};

//reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        user_data: action.data
      };
    case DOCUMENT_VERIFICATION_VISIBILITY:
      return {
        ...state,
        showDocumentVerificationDialogue: action.data
      };
    case PAYMENT_UPDATE_VISIBILITY:
      return {
        ...state,
        showPaymentUpdateDialogue: action.data
      };
      case UPDATE_PAYMENT_ISSUE_LOADING:
      return {
        ...state,
        updatePaymentIssueLoading: true
      };
      case UPDATE_PAYMENT_ISSUE:
      return {
        ...state,
        updatePaymentIssueLoading: false,
        paymentMethodUpdated: true
      };
    case SET_VALIDATE_PAYMENT_URL_LOADING:
      return {
        ...state,
        validatePaymentUrlLoading: true
      };
    case SET_VALIDATE_PAYMENT_URL_DATA:
      return {
        ...state,
        validatePaymentUrlLoading: false
      };
    case SET_VALIDATE_PAYOUT_URL_LOADING:
      return {
        ...state,
        validatePayoutUrlLoading: true
      };
    case SET_VALIDATE_PAYOUT_URL_DATA:
      return {
        ...state,
        validatePayoutUrlLoading: false
      };
    case SET_USER_PAYMENT_STATUS:
      return {
        ...state,
        user_data: {
          ...state.user_data,
          payment_status: action.data
        }
      };
    case SET_USER_IS_CARD_ADDED:
      return {
        ...state,
        user_data: {
          ...state.user_data,
          is_card_added: action.data
        }
      };
    case SET_ERROR:
      return {
        ...state,
        update_profile_loading: false,
        documents_loading: false,
        user_message: '',
        user_error: action.data,
        users_loading: false,
        user_loading: false,
        validatePaymentUrlLoading: false,
        validatePayoutUrl: false,
        userConfigLoading: false,
        deletUserAccountLoading: false,
        deleteAccountReasonLoading: false

      };
    case USER_CONFIG_LOADING:
      return {
        ...state,
        user_error: '',
        userConfigLoading: true
      };
    case USER_CONFIG:
      return {
        ...state,
        user_roles: action.data.roles,
        userConfigLoading: false
      };
    case SET_MESSAGE:
      return {
        ...state,
        update_profile_loading: false,
        user_error: '',
        user_message: action.data
      };
    case UPDATE_PROFILE_LOADING:
      return {
        ...state,
        user_error: '',
        user_message: '',
        update_profile_loading: true
      };
    case SET_USERS_ORDERING:
      return {
        ...state,
        users_ordering: action.data
      };
    case DOCUMENTS_LOADING:
      return {
        ...state,
        user_error: '',
        user_message: '',
        documents_loading: true
      };
    case DOCUMENTS:
      return {
        ...state,
        documents_loading: false,
        documents: action.data
      };
    case SET_USER_DATA_LOADING:
      return {
        ...state,
        user_data_getting: action.data
      };
    case USERS_LOADING:
      return {
        ...state,
        users: null,
        users_loading: true
      };
    case SET_USERS:
      return {
        ...state,
        users: action.data,
        users_loading: false
      };
    case USER_LOADING:
      return {
        ...state,
        user_loading: true
      };
    case SET_USER:
      return {
        ...state,
        user: action.data,
        user_loading: false
      };
    case USER_NOTE_LOADING:
      return {
        ...state,
        userNoteLoading: true,
        userNote: null,
        error: '',
        message: ''
      };
    case USER_DELETE_NOTE_LOADING:
      return {
        ...state,
        userDeleteNoteLoading: true,
        userDeleteNote: null,
        error: '',
        message: ''
      };
    case USER_NOTE:
      return {
        ...state,
        userNoteLoading: false,
        noteList: [action.data, ...state.noteList],
        userNote: action.data
      };
    case DELETE_USER_ACCOUNT_LOADING:
      return {
        ...state,
        deletUserAccountLoading: true,
      }
    case DELETE_USER_ACCOUNT:
      return {
        ...state,
        deleteUserAccount: action.data
      };
       case DELETE_USER_ACCOUNT_REASON_LOADING:
      return {
        ...state,
        deleteAccountReasonLoading: true,
      }
    case DELETE_USER_ACCOUNT_REASON:
      return {
        ...state,
        deleteAccountReasonLoading: false,
        deleteAccountReason: action.data
      };
    case USER_DELETE_NOTE:
      return {
        ...state,
        userDeleteNoteLoading: false,
        userDeleteNote: action.data,
        noteList: state.noteList.filter(note => note.id !== action.data)

      };
    case ADMIN_NOTE_LOADING:
      return {
        ...state,
        adminNoteLoading: true,
        adminNote: null,
        updateNote: null,
        editNote: null,
        error: '',
        message: ''
      };
    case NOTES_LIST_LOADING:
      return {
        ...state,
        noteListLoading: true,
        noteList: null,
        error: '',
        message: ''
      };
    case SET_NOTES:
      return {
        ...state,
        adminNoteLoading: false,
        noteList:action.data,
        error: ''
      };

    case EDIT_NOTE:
      return {
        ...state,
        editNote: action.data,
        isNoteEdited: true
      };

    case CANCEL_EDIT_NOTE_ADMIN:
      return {
        ...state,
        editNote: null,
        noteList: state.noteList.map(note => {
          if (note.id === action.data.id) {
            return { ...action.data };
          }
          return note;
        })
      };
    case UPDATE_ADMIN_NOTE:
      return {
        ...state,
        adminNoteLoading: false,
        editNote: null,
        updateNote: action.data,
        noteList: [
          { ...action.data },
          ...state.noteList.filter(note => note.id !== action.data.id)
        ],
      };
    default:
      return state;
  }
}
