import React from 'react';
import Skeleton from './Skeleton';
import { CardHeader } from '@material-ui/core';

const DriverSingleLoading = () => (
  <CardHeader
    avatar={
      <Skeleton animation="wave" variant="circle" width={40} height={40} />
    }
    title={
      <Skeleton
        animation="wave"
        height={15}
        width="80%"
        style={{ marginBottom: 6 }}
      />
    }
    subheader={<Skeleton animation="wave" height={15} width="40%" />}
  />
);

export default DriverSingleLoading;
