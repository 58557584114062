import React from 'react';

// Material UI.
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import { capitalizeFirst, convertToLabel, yesOrNo } from 'utils/textUtils';
import BoxLayout2 from 'components/BoxLayout2';
import BoxLayout3 from 'components/BoxLayout3';
import { VEHICLE_SUBCATEGORY } from 'utils/constants';
import ShipmentItemDamages from '../ShipmentItemDamages';
import { ReactComponent as InOperable } from 'assets/images/job/InOperable.svg';

const Vehicle = ({
  shipment_items,
  subCategory,
  shipment_damages,
  setSelectedDamageItem
}) => {
  const classes = useStyles();
  return (
    <Box style={{ marginTop: 30 }}>
      {shipment_items.map((item, index) => (
        <BoxLayout3
          key={index}
          className={classes.box}
          title={
            <>
              {item.description || ''}
              {convertToLabel(subCategory)}
              {item.VIN ? ` (${item.VIN})` : ''}
              {!(item.operable) && (<InOperable style={{
                paddingLeft:6,
                justifyContent:'center',
                marginBottom:2
              }}
              />)} 
            </>
          }
          collapse={index > 0}>
          <Box className={classes.info}>
            <Box>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingRight: 4,
                        paddingBottom: 8,
                        verticalAlign: 'top'
                      }}>
                      <Typography display="inline" className={classes.key}>
                        Year:
                      </Typography>{' '}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingLeft: 4,
                        paddingBottom: 8
                      }}>
                      <Typography display="inline" style={{ fontSize: 12 }}>
                        {item.year}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingRight: 4,
                        paddingBottom: 8,
                        verticalAlign: 'top'
                      }}>
                      <Typography display="inline" className={classes.key}>
                        Make:
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingLeft: 4,
                        paddingBottom: 8
                      }}>
                      <Typography display="inline" style={{ fontSize: 12 }}>
                        {item.make}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingRight: 4,
                        paddingBottom: 8,
                        verticalAlign: 'top'
                      }}>
                      <Typography display="inline" className={classes.key}>
                        Model:
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingLeft: 4,
                        paddingBottom: 8
                      }}>
                      <Typography display="inline" style={{ fontSize: 12 }}>
                        {item.model}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {subCategory === VEHICLE_SUBCATEGORY.CARS ||
                  subCategory === VEHICLE_SUBCATEGORY.BOATS ||
                  subCategory === VEHICLE_SUBCATEGORY.JETSKI ? (
                    <TableRow>
                      <TableCell
                        style={{
                          borderBottom: 'unset',
                          padding: 0,
                          paddingRight: 4,
                          paddingBottom: 8,
                          verticalAlign: 'top'
                        }}>
                        <Typography display="inline" className={classes.key}>
                          Type:
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: 'unset',
                          padding: 0,
                          paddingLeft: 4,
                          paddingBottom: 8
                        }}>
                        <Typography display="inline" style={{ fontSize: 12 }}>
                          {item.type}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </Box>
            <Box>
              <Table>
                <TableBody>
                  {(subCategory === VEHICLE_SUBCATEGORY.BOATS ||
                    subCategory === VEHICLE_SUBCATEGORY.JETSKI ||
                    subCategory === VEHICLE_SUBCATEGORY.LIGHT_DUTY_TRUCKS ||
                    subCategory === VEHICLE_SUBCATEGORY.HEAVY_DUTY_TRUCKS) &&
                  item.trailer_preference &&
                  item.trailer_type_preference ? (
                    <TableRow>
                      <TableCell
                        style={{
                          borderBottom: 'unset',
                          padding: 0,
                          paddingRight: 4,
                          paddingBottom: 8,
                          verticalAlign: 'top'
                        }}>
                        <Typography display="inline" className={classes.key}>
                          Preference:
                        </Typography>{' '}
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: 'unset',
                          padding: 0,
                          paddingLeft: 4,
                          paddingBottom: 8
                        }}>
                        <Typography display="inline" style={{ fontSize: 12 }}>
                          {convertToLabel(item.trailer_preference)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {subCategory === VEHICLE_SUBCATEGORY.MOTOR_VEHICLE_PARTS ? (
                    <>
                      <TableRow>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingRight: 4,
                            paddingBottom: 8,
                            verticalAlign: 'top'
                          }}>
                          <Typography display="inline" className={classes.key}>
                            Item:
                          </Typography>{' '}
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingLeft: 4,
                            paddingBottom: 8
                          }}>
                          <Typography display="inline" style={{ fontSize: 12 }}>
                            {item.description}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingRight: 4,
                            paddingBottom: 8,
                            verticalAlign: 'top'
                          }}>
                          <Typography display="inline" className={classes.key}>
                            Unit:
                          </Typography>{' '}
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingLeft: 4,
                            paddingBottom: 8
                          }}>
                          <Typography display="inline" style={{ fontSize: 12 }}>
                            {item.handling_unit}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : null}

                  {subCategory === VEHICLE_SUBCATEGORY.BOATS ||
                  subCategory === VEHICLE_SUBCATEGORY.JETSKI ? (
                    <>
                      <TableRow>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingRight: 4,
                            paddingBottom: 8,
                            verticalAlign: 'top'
                          }}>
                          <Typography
                            display="inline"
                            className={classes.key}
                            style={{ whiteSpace: 'nowrap' }}>
                            On trailer:
                          </Typography>{' '}
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingLeft: 4,
                            paddingBottom: 8
                          }}>
                          <Typography display="inline" style={{ fontSize: 12 }}>
                            {yesOrNo(item.on_trailer)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingRight: 4,
                            paddingBottom: 8,
                            verticalAlign: 'top'
                          }}>
                          <Typography display="inline" className={classes.key}>
                            Dimension:
                          </Typography>{' '}
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingLeft: 4,
                            paddingBottom: 8
                          }}>
                          <Typography display="inline" style={{ fontSize: 12 }}>
                            {capitalizeFirst(item.dimension_type)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingRight: 4,
                            paddingBottom: 8,
                            verticalAlign: 'top'
                          }}>
                          <Typography display="inline" className={classes.key}>
                            Length:
                          </Typography>{' '}
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingLeft: 4,
                            paddingBottom: 8
                          }}>
                          <Typography display="inline" style={{ fontSize: 12 }}>
                            {item.length} {item.length_unit_type}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingRight: 4,
                            paddingBottom: 8,
                            verticalAlign: 'top'
                          }}>
                          <Typography display="inline" className={classes.key}>
                            Width:
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingLeft: 4,
                            paddingBottom: 8
                          }}>
                          <Typography display="inline" style={{ fontSize: 12 }}>
                            {item.width} {item.width_unit_type}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingRight: 4,
                            paddingBottom: 8,
                            verticalAlign: 'top'
                          }}>
                          <Typography display="inline" className={classes.key}>
                            Height:
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingLeft: 4,
                            paddingBottom: 8
                          }}>
                          <Typography display="inline" style={{ fontSize: 12 }}>
                            {item.height} {item.height_unit_type}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingRight: 4,
                            paddingBottom: 8,
                            verticalAlign: 'top'
                          }}>
                          <Typography display="inline" className={classes.key}>
                            Weight:
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'unset',
                            padding: 0,
                            paddingLeft: 4,
                            paddingBottom: 8
                          }}>
                          <Typography display="inline" style={{ fontSize: 12 }}>
                            {item.weight} {item.weight_unit_type}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : null}

                  {/* {subCategory === VEHICLE_SUBCATEGORY.CARS ||
                  subCategory === VEHICLE_SUBCATEGORY.LIGHT_DUTY_TRUCKS ||
                  subCategory === VEHICLE_SUBCATEGORY.HEAVY_DUTY_TRUCKS ||
                  subCategory === VEHICLE_SUBCATEGORY.MOTERCYCLES ||
                  subCategory === VEHICLE_SUBCATEGORY.BOATS ||
                  subCategory === VEHICLE_SUBCATEGORY.JETSKI ? ( */}
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingRight: 4,
                        paddingBottom: 8,
                        verticalAlign: 'top'
                      }}>
                      <Typography display="inline" className={classes.key}>
                        InOp:
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingLeft: 4,
                        paddingBottom: 8
                      }}>
                      <Typography display="inline" style={{ fontSize: 12 }}>
                        {yesOrNo(!item.operable)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>
          <ShipmentItemDamages
            shipment_damages={shipment_damages}
            setSelectedDamageItem={setSelectedDamageItem}
            shipmentItemId={item.shipment_item_id}
          />
        </BoxLayout3>
      ))}
    </Box>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    box: {
      marginBottom: theme.spacing(3)
    },
    info: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    row: {
      marginBottom: 8
    },
    key: {
      fontWeight: 600,
      fontSize: 12
    }
  })
);

export default Vehicle;
