import React, { Fragment } from 'react';

import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';

import { connect } from 'react-redux';

import SidebarHeader from './SidebarHeader';
//import SidebarUserbox from './SidebarUserbox';
import SidebarMenu from './SidebarMenu';
//import SidebarFooter from './SidebarFooter';

import adminItems from './AdminMenuItems';
import shipperItems from './ShipperMenuItems';
import carrierItems from './CarrierMenuItems';
import brokerItems from './BrokerMenuItems';

import {
  setSidebarToggleMobile,
  setSidebarHover,
  setSidebarToggle,
  setSidebarFooter,
  setSidebarUserbox
} from 'reducers/ThemeOptions';

import { getUserTypeService, isUserTypeBrokerService } from 'services/login';

import { SHIPPER, CARRIER, ADMIN, BROKER } from 'utils/users';

import { Link } from 'react-router-dom';
import { TRUCKS_ADD_PAGE, TRUCKS_PAGE } from 'utils/routesUtils';
import { ReactComponent as PostTruckIcon } from 'assets/images/common/post-truck.svg';
import { ReactComponent as PostTruckDisabledIcon } from 'assets/images/common/post-truck-disabled.svg';
import { ReactComponent as FindTruckIcon } from 'assets/images/common/find-truck.svg';
import { ReactComponent as FindTruckDisabledIcon } from 'assets/images/common/find-truck-disabled.svg';
import DocumentVerificationView from './SidebarMenu/DocumentVerificationView/DocumentVerificationView';
import UnAvailableInBetaTooltip from 'components/Tooltips/UnAvailableInBetaTooltip';

const Sidebar = (props: any) => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarFixed,

    sidebarHover,
    setSidebarHover,
    sidebarToggle,
    //sidebarUserbox,
    sidebarShadow
    //sidebarFooter
  } = props;

  const toggleHoverOn = () => setSidebarHover(true);
  const toggleHoverOff = () => setSidebarHover(false);

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  const userType = getUserTypeService();

  const dashBoardMenuItems = () => {
    switch (userType) {
      case SHIPPER:
        return shipperItems;
      case ADMIN:
        return adminItems;
      case CARRIER:
        return carrierItems;
      case BROKER:
        return brokerItems;
    }
  };

  const menuItems = dashBoardMenuItems();

  const sidebarMenuContent = (
    <div
      className={clsx({
        'app-sidebar-nav-close': sidebarToggle && !sidebarHover
      })}>
      {menuItems?.map(list => (
        <SidebarMenu
          component="div"
          key={list.label}
          pages={list.content}
          title={list.label}
        />
      ))}
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <PerfectScrollbar>
            <SidebarHeader />
            {/* {sidebarUserbox && <SidebarUserbox />} */}
            {sidebarMenuContent}
            {/* {userType === CARRIER || userType === SHIPPER ? (
              <Link to={userType === CARRIER ? TRUCKS_ADD_PAGE : TRUCKS_PAGE}> */}
                {userType === CARRIER && (
                  <UnAvailableInBetaTooltip>
                    <PostTruckDisabledIcon
                    style={{
                      marginLeft: 19,
                      //marginTop: 10,
                      marginBottom: 8
                    }}
                  />
                  </UnAvailableInBetaTooltip>
                )}
                {userType === SHIPPER && (
                  <UnAvailableInBetaTooltip>
                    <FindTruckDisabledIcon
                    style={{
                      marginLeft: 19,
                      //marginTop: 10,
                      marginBottom: 8
                    }}
                  />
                  </UnAvailableInBetaTooltip>
                )}
              {/* </Link>
            ) : null} */}
            {/* {sidebarFooter && <SidebarFooter />} */}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          onMouseEnter={toggleHoverOn}
          onMouseLeave={toggleHoverOff}
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-close': sidebarToggle,
            'app-sidebar-wrapper-open': sidebarHover,
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          //open={sidebarToggle}
          elevation={sidebarShadow ? 11 : 3}>
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed,
              'app-sidebar-collapsed': sidebarToggle && !sidebarHover
            })}>
            <PerfectScrollbar
              //className="perfect-scrollbar"
              options={{ wheelPropagation: false }}>
              {/* {sidebarUserbox && <SidebarUserbox />} */}
              <SidebarHeader />
              {sidebarMenuContent}
              {/* {sidebarFooter && <SidebarFooter />} */}

              {/* currently making pin truck option disable */}
              {/* {userType === CARRIER || userType === SHIPPER ? (
                <Link
                  to={userType === CARRIER ? TRUCKS_ADD_PAGE : TRUCKS_PAGE}
                  style={{ display: 'flex', justifyContent: 'center' }}>
                  {userType === CARRIER && <PostTruckIcon />}
                  {userType === SHIPPER && <FindTruckIcon />}
                </Link>
              ) : null} */}

              <div
                style={{ display: 'flex', justifyContent: 'center' }}>
                {userType === CARRIER &&
                  <UnAvailableInBetaTooltip>
                    <PostTruckDisabledIcon />
                  </UnAvailableInBetaTooltip>}
                {userType === SHIPPER &&
                  <UnAvailableInBetaTooltip>
                    <FindTruckDisabledIcon />
                  </UnAvailableInBetaTooltip>}
              </div>
              <DocumentVerificationView />
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarFooter: state.ThemeOptions.sidebarFooter,
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch: any) => ({
  setSidebarToggleMobile: (enable: boolean) =>
    dispatch(setSidebarToggleMobile(enable)),
  setSidebarToggle: (enable: boolean) => dispatch(setSidebarToggle(enable)),
  setSidebarHover: (enable: boolean) => dispatch(setSidebarHover(enable)),
  setSidebarFooter: (enable: boolean) => dispatch(setSidebarFooter(enable)),
  setSidebarUserbox: (enable: boolean) => dispatch(setSidebarUserbox(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
