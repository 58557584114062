import * as React from "react"

const DownloadSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 1770"
          transform="translate(0 .219)"
          fill="#999db7"
          d="M0 0h14v14H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 8597">
      <g
        data-name="Group 8596"
        transform="translate(0 -.219)"
        clipPath="url(#a)"
        fill="#999db7"
      >
        <path
          data-name="Path 10612"
          d="M7.303-.108a.614.614 0 0 0 .065.025.887.887 0 0 1 .69.887v6.877c.056-.051.093-.083.127-.117.5-.5 1-1.013 1.515-1.512a.9.9 0 1 1 1.233 1.3q-1.194 1.19-2.384 2.383c-.241.241-.48.483-.722.722a.893.893 0 0 1-1.33 0q-1.56-1.556-3.116-3.115a.873.873 0 0 1-.25-.9.841.841 0 0 1 .649-.628.877.877 0 0 1 .869.258l1.483 1.485a1.059 1.059 0 0 1 .092.122l.043-.021V7.5c0-2.159.009-4.318-.006-6.477a1.017 1.017 0 0 1 .734-1.131Z"
        />
        <path
          data-name="Path 10613"
          d="M0 13.156c.019-.046.038-.092.055-.139a.9.9 0 0 1 .811-.583H13.234a.936.936 0 0 1 1.093.755v.28a2.7 2.7 0 0 1-.1.257.946.946 0 0 1-.66.5H.756a.946.946 0 0 1-.581-.372A3.2 3.2 0 0 1 0 13.492v-.336"
        />
      </g>
    </g>
  </svg>
)

export default DownloadSvg
