import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { convertToLabel } from 'utils/textUtils';
import Account from './Account';
import Documents from './Documents';
import { createStyles } from '@mui/material';
import Bug from './Reportbug';
import Driver from './Driver';
const TabInfo = ({ activeTab, className, setSnackbar }) => {
  const classes = useStyles();

  const getTabInfo = () => {
    if (activeTab === 'account') return <Account setSnackbar={setSnackbar} />;
    if (activeTab === 'documents')
      return <Documents setSnackbar={setSnackbar} />;
    if (activeTab === 'report_a_bug') return < Bug setSnackbar={setSnackbar} />;
    if (activeTab === 'drivers') return  < Driver setSnackbar={setSnackbar} />;
  };

  return (
    <Box className={className}>
      <Grid container>
        <Grid item lg={1} md={1}></Grid>
        <Grid item lg={8} md={8}>
          <Box>
            <Typography
              gutterBottom
              variant="h4"
              style={{ color: '#6b6b6b', fontWeight: 700 }}>
              {convertToLabel(activeTab)}
            </Typography>
            {getTabInfo()}
          </Box>
        </Grid>
        <Grid item lg={3} md={3}></Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    info: {
      marginTop: 20,
      paddingLeft: 120,
      paddingRight: 150,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 10,
        paddingRight: 10
      }
    }
  })
);

export default TabInfo;
