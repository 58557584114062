import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import { useDropzone } from 'react-dropzone';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { getUserTypeService } from 'services/login';
import { ReactComponent as EditIcon } from 'assets/images/common/editicon.svg';

const useStyles = makeStyles(theme =>
  createStyles({
    dropzone: {
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '100%'
    },
    border: {
      border: `2px solid #D5D5D5`
    },
    border2: {
      border: `1px solid #D5D5D5`
    },
    previewImg: {
      width: '100px',
      borderRadius: '100%'
    },
    dropzoneHandler: {
      cursor: 'pointer',
      width: 100,
      height: 100,
      background: '#ccc',
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center'
    }
  })
);

function StyledDropzone(props) {
  const classes = useStyles();
  const userType = getUserTypeService();
  const [files, setFiles] = useState([]);
  const { title, onSubmit, accept, profile_pic } = props;
  const { getRootProps, getInputProps, acceptedFiles, open } = useDropzone({
    accept: accept,
    noClick: true,
    multiple: false,
    noKeyboard: true,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  //auto upload once file added
  useEffect(() => {
    if (acceptedFiles.length) {
      onSubmit(acceptedFiles[0].name, acceptedFiles[0]);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  return (
    <div className={clsx(classes.dropzone, classes.border)}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div onClick={open} className={classes.dropzoneHandler}>
          {profile_pic && acceptedFiles.length === 0 ? (
            <img
              height={100}
              src={profile_pic}
              className={classes.previewImg}
            />
          ) : null}

          {acceptedFiles.length ? (
            <img
              height={100}
              src={acceptedFiles[0].preview}
              className={classes.previewImg}
            />
          ) : null}

          {acceptedFiles.length === 0 && !profile_pic ? (
            <Box className="avatar-block">
              {/* { title } */}
              <CameraAltOutlinedIcon className="cameraAltIconAvatar" />
            </Box>
          ) : null}
          <EditIcon style={{ position: 'absolute', bottom: 4, right: 4 }} />
        </div>
      </div>
    </div>
  );
}

StyledDropzone.propTypes = {
  profile_pic: PropTypes.string,
  title: PropTypes.string.isRequired,
  noFilesMessage: PropTypes.string.isRequired,
  noFilesAlert: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired
};

export default StyledDropzone;
