import React from 'react';

const AppReturnPage = () => (
  <div
    style={{
      display: 'flex',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
    <h3>
      <a href={'roadcall://com.roadcall.co/payment/return_url'}>Return</a>
    </h3>
  </div>
);

export default AppReturnPage;
