import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import {
  createStyles,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  createStripeAccount,
  createPaymentMethod,
  setPaymentMethodPopupVisible
} from 'reducers/Payment';
import ButtonWithBackground from 'components/Form/Button';
import { connect } from 'react-redux';
import { PAYMENT_METHOD_TYPES, PAYMENT_STATUS } from 'utils/constants';
import { useForm } from 'react-hook-form';
import { convertToLabel } from 'utils/textUtils';
import Popup from 'components/Popup';

const AddPaymentMethod = ({
  user,
  createStripeAccount,
  createPaymentMethod,
  createPaymentMethodLoading,
  createStripeAccountLoading,
  setPaymentMethodPopupVisible,
  paymentMethodPopupVisible
}) => {
  const formDefaultValues = {
    company_name: user.company_name,
    company_tax_id: '',
    card_exp_month: '',
    card_exp_year: '',
    account_holder_name: `${user.first_name} ${user.last_name}`,
    account_number: '',
    routing_number: ''
  };

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: formDefaultValues
  });

  const classes = useStyles();

  const [paymentMethodType, setPaymentMethodType] = useState(
    PAYMENT_METHOD_TYPES.CARD
  );

  const onSubmit = async formData => {
    const data = {
      is_bank: paymentMethodType === PAYMENT_METHOD_TYPES.BANK
    };

    if (data.is_bank) {
      data.bank_account = {
        account_holder_name: formData.account_holder_name,
        routing_number: formData.routing_number,
        account_number: formData.account_number
      };
    } else {
      data.card = {
        number: formData.account_number,
        exp_month: formData.card_exp_month,
        exp_year: formData.card_exp_year
      };
    }

    if (user.payment_status === PAYMENT_STATUS.UPDATED) {
      createPaymentMethod(data);
      return;
    }

    data.company = {
      name: formData.company_name,
      tax_id: formData.company_tax_id
    };

    createStripeAccount(data);
  };

  useEffect(() => {
    if (paymentMethodPopupVisible === true) reset(formDefaultValues);
  }, [paymentMethodPopupVisible]);

  return (
    <Popup
      open={paymentMethodPopupVisible}
      onclosehandler={() => setPaymentMethodPopupVisible(false)}>
      <Box style={{ maxWidth: 600, padding: 16 }}>
        <Typography variant="h5" style={{ marginBottom: 12 }}>
          Add payment method
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ marginTop: 8 }}>
            <Grid container spacing={1}>
              <Grid item lg={12} xs={12} sm={12}>
                <RadioGroup
                  row
                  value={paymentMethodType}
                  onChange={e => setPaymentMethodType(e.target.value)}>
                  <FormControlLabel
                    title={'Card'}
                    value={PAYMENT_METHOD_TYPES.CARD}
                    control={
                      <Radio
                        disableRipple
                        color="default"
                        checked={
                          paymentMethodType === PAYMENT_METHOD_TYPES.CARD
                        }
                      />
                    }
                    label={
                      <Typography
                        style={{
                          marginLeft: 10,
                          fontWeight: 500,
                          textTransform: 'capitalize'
                        }}>
                        {PAYMENT_METHOD_TYPES.CARD}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    title={'Bank'}
                    value={PAYMENT_METHOD_TYPES.BANK}
                    control={
                      <Radio
                        disableRipple
                        color="default"
                        checked={
                          paymentMethodType === PAYMENT_METHOD_TYPES.BANK
                        }
                      />
                    }
                    label={
                      <Typography
                        style={{
                          marginLeft: 10,
                          fontWeight: 500,
                          textTransform: 'capitalize'
                        }}>
                        {convertToLabel(PAYMENT_METHOD_TYPES.BANK)}
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>

              {user.payment_status !== PAYMENT_STATUS.UPDATED && (
                <>
                  <Grid item lg={12} xs={12} sm={12}>
                    <Typography className={classes.label}>
                      Company Name
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      className="inpt-fld capitalizefield"
                      placeholder="Company name"
                      style={{ marginTop: 8 }}
                      helperText={errors.company_name?.message}
                      error={errors.company_name ? true : false}
                      value={watch('company_name')}
                      {...register('company_name', {
                        required: true,
                        minLength: {
                          value: 3,
                          message: 'Company name too short'
                        },
                        maxLength: {
                          value: 30,
                          message: 'Company name too lengthy'
                        }
                      })}
                      onChange={e => {
                        setValue('company_name', e.target.value.trimLeft());
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} sm={12}>
                    <Typography className={classes.label}>Tax ID</Typography>
                    <TextField
                      inputProps={{ inputMode: 'numeric' }}
                      variant="outlined"
                      fullWidth
                      className="inpt-fld"
                      placeholder="Tax ID"
                      style={{ marginTop: 8 }}
                      helperText={errors.company_tax_id?.message}
                      error={errors.company_tax_id ? true : false}
                      value={watch('company_tax_id')}
                      {...register('company_tax_id', {
                        required: true,
                        minLength: {
                          value: 9,
                          message: 'Tax ID must have 9 digits'
                        },
                        maxLength: {
                          value: 9,
                          message: 'Tax ID must have 9 digits'
                        }
                      })}
                      onChange={e => {
                        setValue(
                          'company_tax_id',
                          e.target.value.replace(/\D/g, '')
                        );
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item lg={6} xs={12} sm={12}>
                {paymentMethodType === PAYMENT_METHOD_TYPES.CARD && (
                  <>
                    <Typography className={classes.label}>
                      Card number
                    </Typography>
                    <TextField
                      inputProps={{ inputMode: 'numeric' }}
                      variant="outlined"
                      fullWidth
                      className="inpt-fld"
                      placeholder="Card number"
                      style={{ marginTop: 8 }}
                      helperText={errors.account_number?.message}
                      error={errors.account_number ? true : false}
                      value={watch('account_number')}
                      {...register('account_number', {
                        required: true,
                        minLength: {
                          value: 10,
                          message: 'Invalid card number'
                        },
                        maxLength: {
                          value: 18,
                          message: 'Invalid card number'
                        }
                      })}
                      onChange={e => {
                        setValue(
                          'account_number',
                          e.target.value.replace(/\D/g, '')
                        );
                      }}
                    />
                  </>
                )}
              </Grid>

              <Grid item lg={3} xs={12} sm={12}>
                {paymentMethodType === PAYMENT_METHOD_TYPES.CARD && (
                  <>
                    <Typography className={classes.label}>
                      Expiry month
                    </Typography>
                    <TextField
                      inputProps={{ inputMode: 'numeric' }}
                      variant="outlined"
                      fullWidth
                      className="inpt-fld"
                      placeholder="06"
                      style={{ marginTop: 8 }}
                      helperText={errors.card_exp_month?.message}
                      error={errors.card_exp_month ? true : false}
                      value={watch('card_exp_month')}
                      {...register('card_exp_month', {
                        required: true,
                        min: { value: 1, message: 'Invalid month' },
                        max: { value: 12, message: 'Invalid month' }
                      })}
                      onChange={e => {
                        setValue(
                          'card_exp_month',
                          e.target.value.replace(/\D/g, '')
                        );
                      }}
                    />
                  </>
                )}
              </Grid>

              <Grid item lg={3} xs={12} sm={12}>
                {paymentMethodType === PAYMENT_METHOD_TYPES.CARD && (
                  <>
                    <Typography className={classes.label}>
                      Expiry year
                    </Typography>
                    <TextField
                      inputProps={{ inputMode: 'numeric' }}
                      variant="outlined"
                      fullWidth
                      className="inpt-fld"
                      placeholder="2029"
                      style={{ marginTop: 8 }}
                      helperText={errors.card_exp_year?.message}
                      error={errors.card_exp_year ? true : false}
                      value={watch('card_exp_year')}
                      {...register('card_exp_year', {
                        required: true,
                        min: { value: 2022, message: 'Invalid year' },
                        max: { value: 2099, message: 'Invalid year' }
                      })}
                      onChange={e => {
                        setValue(
                          'card_exp_year',
                          e.target.value.replace(/\D/g, '')
                        );
                      }}
                    />
                  </>
                )}
              </Grid>

              {paymentMethodType === PAYMENT_METHOD_TYPES.BANK && (
                <>
                  <Grid item lg={12} xs={12} sm={12}>
                    <Typography className={classes.label}>
                      Account Holder Name
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      className="inpt-fld capitalizefield"
                      placeholder="Full name"
                      style={{ marginTop: 8 }}
                      helperText={errors.account_holder_name?.message}
                      error={errors.account_holder_name ? true : false}
                      value={watch('account_holder_name')}
                      {...register('account_holder_name', {
                        required: true,
                        minLength: { value: 3, message: 'Name too short' },
                        maxLength: { value: 30, message: 'Name too lengthy' }
                      })}
                      onChange={e => {
                        setValue(
                          'account_holder_name',
                          e.target.value.trimLeft()
                        );
                      }}
                    />
                  </Grid>

                  <Grid item lg={12} xs={12} sm={12}>
                    <Typography className={classes.label}>
                      Account Number
                    </Typography>
                    <TextField
                      inputProps={{ inputMode: 'numeric' }}
                      variant="outlined"
                      fullWidth
                      className="inpt-fld"
                      placeholder="Account number"
                      style={{ marginTop: 8 }}
                      helperText={errors.account_number?.message}
                      error={errors.account_number ? true : false}
                      value={watch('account_number')}
                      {...register('account_number', {
                        required: true,
                        minLength: {
                          value: 8,
                          message: 'Invalid account number'
                        },
                        maxLength: {
                          value: 18,
                          message: 'Invalid account number'
                        }
                      })}
                      onChange={e => {
                        setValue(
                          'account_number',
                          e.target.value.replace(/\D/g, '')
                        );
                      }}
                    />
                  </Grid>

                  <Grid item lg={12} xs={12} sm={12}>
                    <Typography className={classes.label}>
                      Routing Number
                    </Typography>
                    <TextField
                      inputProps={{ inputMode: 'numeric' }}
                      variant="outlined"
                      fullWidth
                      className="inpt-fld"
                      placeholder="Routing number"
                      style={{ marginTop: 8 }}
                      helperText={errors.routing_number?.message}
                      error={errors.routing_number ? true : false}
                      value={watch('routing_number')}
                      {...register('routing_number', {
                        required: true,
                        minLength: {
                          value: 9,
                          message: 'Routing number must have 9 digits'
                        },
                        maxLength: {
                          value: 9,
                          message: 'Routing number must have 9 digits'
                        }
                      })}
                      onChange={e => {
                        setValue(
                          'routing_number',
                          e.target.value.replace(/\D/g, '')
                        );
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          <ButtonWithBackground
            disabled={createPaymentMethodLoading || createStripeAccountLoading}
            loaderSize={14}
            style={{
              marginTop: 16,
              borderRadius: 9,
              fontSize: 10,
              textTransform: 'capitalize',
              minWidth: 100,
              height: 30
            }}
            label={`add ${convertToLabel(paymentMethodType)}`}
            buttonType="submit"
          />
        </form>
      </Box>
    </Popup>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    label: {
      color: '#454647',
      fontSize: 12,
      marginLeft: 25,
      fontWeight: 600
    }
  })
);

const mapStateToProps = state => ({
  user: state.User.user_data,
  createPaymentMethodLoading: state.Payment.createPaymentMethodLoading,
  createStripeAccountLoading: state.Payment.createStripeAccountLoading,
  paymentMethodPopupVisible: state.Payment.paymentMethodPopupVisible
});

const mapDispatchToProps = dispatch => {
  return {
    createStripeAccount: data => dispatch(createStripeAccount(data)),
    createPaymentMethod: data => dispatch(createPaymentMethod(data)),
    setPaymentMethodPopupVisible: bool =>
      dispatch(setPaymentMethodPopupVisible(bool))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethod);
