const SHIPPER = 'shipper'
const CARRIER = 'carrier'
const ADMIN = 'admin'
const INDIVIDUAL = 'individual'
const BUSINESS = 'business'
const BROKER = 'broker'

export {
  SHIPPER,
  CARRIER,
  ADMIN,
  INDIVIDUAL,
  BUSINESS,
  BROKER
}