import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useReactToPrint } from 'react-to-print';

import { getDamageCodes, getOnlineBOL } from 'services/Shipments';

import Header from '../Header';

import { connect } from 'react-redux';
import { modifyImageUrlInDocumentsToShare } from 'reducers/Job';

import { ERROR } from 'utils/constants';
import { useParams } from 'react-router-dom';
import { errorMessageSingleLine } from 'utils/errorUtils';
import PageLoader from 'components/loader';

import Dialog from '@mui/material/Dialog';

import Box from '@mui/material/Box';
import { Backdrop } from '@mui/material';

import { ReactComponent as PrintIcon } from 'assets/images/bol/print.svg';
import ButtonWithBackground from 'components/Form/Button';
import { SUCCESS } from 'utils/constants';
import BOLBody from './BOLBody';
const S3_URL = process.env.REACT_APP_S3_URL;

const BillOfLading = props => {
  const {
    setSnackbar,
    documentsToShare,
    modifyImageUrlInDocumentsToShare,
    error
  } = props;

  const componentRef = useRef(null);

  const [bol, setBol] = useState(null);

  const [damageCodes, setDamageCodes] = useState([]);

  const [bolLoading, setBolLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [selectedDamageItem, setSelectedDamageItem] = useState(null);

  const { load_id: url_load_id } = useParams();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const fetchOnlineBOL = async load_id => {
    setBolLoading(true);
    try {
      const bolResp = await getOnlineBOL(load_id);
      setBol(bolResp);
    } catch (e) {
      setBolLoading(false);
      setSnackbar({
        type: ERROR,
        message: errorMessageSingleLine(e.data)
      });
    }
    setBolLoading(false);
  };

  const fetchDamageCodes = async () => {
    try {
      const damageCodesResp = await getDamageCodes();
      setDamageCodes(damageCodesResp);
    } catch (e) {}
  };

  useEffect(() => {
    fetchOnlineBOL(url_load_id);
    fetchDamageCodes();
  }, []);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (error) {
      setSnackbar({
        type: ERROR,
        message: error
      });
    }
  }, [error]);

  if (bolLoading) return <PageLoader />;
  else if (!bol) return null;

  return (
    <Box style={{ background: '#f5f8fa' }}>
  
      {selectedDamageItem ? (
        <Dialog
          fullWidth
          onClose={() => setSelectedDamageItem(null)}
          open={Boolean(selectedDamageItem)}
          maxWidth="md"
          BackdropComponent={Backdrop}>
          <img src={selectedDamageItem.image} style={{ maxHeight: '90vh' }} />
        </Dialog>
      ) : null}

      <Header
        heading={'Online BOL'}
        rightPart={
          <>
            <ButtonWithBackground
              label="Copy link"
              style={{
                marginRight: 30,
                marginBottom: 0,
                fontSize: 10,
                fontWeight: 700,
                paddingTop: 6,
                paddingBottom: 6,
                paddingLeft: 30,
                paddingRight: 30,
                borderRadius: 10
              }}
              clickHandler={() => {
                navigator.clipboard.writeText(window.location.href);
                setSnackbar({
                  type: SUCCESS,
                  message: 'Link copied!'
                });
              }}
            />
            <PrintIcon style={{ cursor: 'pointer' }} onClick={handlePrint} />
          </>
        }
      />

      <BOLBody
        ref={componentRef}
        bol={bol}
        damageCodes={damageCodes}
        open={open}
        setOpen={setOpen}
        setSelectedDamageItem={setSelectedDamageItem}
        selectedDamageItem={selectedDamageItem}
        url_load_id={url_load_id}
        documentsToShare={documentsToShare}
        modifyImageUrlInDocumentsToShare={modifyImageUrlInDocumentsToShare}
      />
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    documentsToShare: state.Job.documentsToShare,
    error: state.Job.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    modifyImageUrlInDocumentsToShare: image_url =>
      dispatch(modifyImageUrlInDocumentsToShare(image_url))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillOfLading);
