import Axios from 'axios';
import { removeUserTokenService } from 'services/login';
import { ROADCALL_VERSION, ROADCALL_USER_TOKEN } from 'utils/constants';
import { LOGIN_PAGE } from './routesUtils';
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getAuthToken = () => {
  const token: any = localStorage.getItem(ROADCALL_USER_TOKEN);
  const parsedToken = JSON.parse(token);
  if (token) return parsedToken.token;
  else return '';
};

const updateAppVersion = (response: any) => {
  if (response.config.method === 'get') {
    const roadcallVersion = response.headers['app-version'];
    const roadcallVersionLocal = localStorage.getItem(ROADCALL_VERSION);

    if (!(roadcallVersion === roadcallVersionLocal)) {
      localStorage.setItem(ROADCALL_VERSION, roadcallVersion);
      //window.location.reload();
    }
  }
};

const axiosInstanceWithToken = Axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstanceWithToken.interceptors.request.use(
  config => {
    config.headers.Authorization = `Token ${getAuthToken()}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstanceWithToken.interceptors.response.use(
  response => {
    updateAppVersion(response);
    return response;
  },
  error => {
    // If token expire, redirect to signin page.
    if (error.response.status === 401 && getAuthToken()) {
      removeUserTokenService();
      window.location.href = LOGIN_PAGE;
    }
    updateAppVersion(error.response);
    return Promise.reject(error);
  }
);

export default axiosInstanceWithToken;
