import React from 'react';

// Components.
import Header from 'components/CustomTable/Header';

const TransactionHeader = ({ ordering, clickHandler }) => {
  return (
    <Header
      list={[
        {
          label: 'Status',
          name: 'status'
        },
        {
          label: 'Shipment ID',
          name: 'shipment'
        },
        {
          label: 'Transaction ID',
          name: 'transaction_id'
        },
        {
          label: 'Origin',
          name: 'pickup_city'
        },
        {
          label: 'Destination',
          name: 'delivery_city'
        },
        {
          label: 'Date',
          name: 'created_at'
        },
        {
          label: 'Rate/ mile',
          name: 'price_per_mile'
        },
        {
          label: 'Amount',
          name: 'amount'
        }
      ]}
      headerType="secondary"
      clickHandler={clickHandler}
      ordering={ordering}
      showSortIconsWhileLoading={true}
    />
  );
};

export default TransactionHeader;
