import React from 'react';

// Material UI.
import { Box, Grid, Typography } from '@material-ui/core';
import { convertToLabel } from 'utils/textUtils';

import { ReactComponent as CameraGreenIcon } from 'assets/images/bol/camera-green.svg';
import { ReactComponent as CameraRedIcon } from 'assets/images/bol/camera-red.svg';

const S3_URL = process.env.REACT_APP_S3_URL;

const ShipmentItemDamages = ({
  shipment_damages,
  setSelectedDamageItem,
  shipmentItemId
}) => {
  let damages_grouped = shipment_damages.reduce(function(r, a) {
    r[a.profile_id] = r[a.profile_id] || [];
    r[a.profile_id].push(a);

    return r;
  }, Object.create(null));

  var damages_grouped_updated = {};

  // Make pickup array as first and delivery as second element.
  Object.keys(damages_grouped).map(function(key, index) {
    let first = damages_grouped[key][0];
    let second = damages_grouped[key][1];
    let temp = null;

    if (first.profile_type === 'delivery') {
      temp = first;
      first = second;
      second = temp;
    }

    damages_grouped_updated[key] = [];

    damages_grouped_updated[key].push(first);
    damages_grouped_updated[key].push(second);
  });

  shipment_damages.sort((a, b) => {
    const titleA = convertToLabel(a.profile_title).toLowerCase();
    const titleB = convertToLabel(b.profile_title).toLowerCase();
    if (titleA < titleB) return -1;
    if (titleA > titleB) return 1;
    return 0;
  });

  const damagesContains = profile_type =>
    shipment_damages.some(
      shipment_damage => shipment_damage.profile_type === profile_type
    );

  const getProfileTypeText = (damage, code) => {
    if (
      (damage.profile_type === 'delivery' && damagesContains('delivery')) ||
      (damage.profile_type === 'pickup' &&
        damagesContains('delivery') === false)
    )
      return (
        <Typography
          display="inline"
          style={{
            color: '#303030',
            marginLeft: 8,
            marginRight: 12,
            fontSize: 10,
            fontWeight: 600
          }}>
          {convertToLabel(code)}
        </Typography>
      );
  };

  const getDamage = code => {
    if (damages_grouped_updated[code] === undefined) return null;

    return damages_grouped_updated[code].map((damage, index) => {
      if (!damage) return null;
      if (shipmentItemId === damage.shipment_item_id)
        return (
          <Box display="inline" key={index}>
            {damage.image_url.map((image, i) => {
              return (
                <Box
                  key={i}
                  display="inline"
                  style={{ cursor: 'pointer', marginRight: 4 }}
                  onClick={() =>
                    setSelectedDamageItem({ image: S3_URL + image })
                  }>
                  {damage.profile_type === 'pickup' ? (
                    <CameraRedIcon />
                  ) : (
                    <CameraGreenIcon />
                  )}
                </Box>
              );
            })}
            {getProfileTypeText(damage, code)}
          </Box>
        );

      return null;
    });
  };

  const method1 = (
    <Box style={{ marginTop: 12 }}>
      {getDamage('front')}

      {getDamage('back')}

      {getDamage('left_side')}

      {getDamage('right_side')}

      {getDamage('car_front')}

      {getDamage('car_back')}

      {getDamage('car_roof')}

      {getDamage('car_driver')}

      {getDamage('car_keys')}

      {getDamage('car_odometer')}

      {getDamage('car_passenger')}

      {getDamage('other')}
    </Box>
  );

  const method2 = Object.keys(damages_grouped)
    .map(key => [key, damages_grouped[key]])
    .map((item, index) => {
      return (
        <Box
          key={index}
          display="inline"
          style={{ marginRight: 20, whiteSpace: 'nowrap' }}>
          {item[1].map((damage, damage_index) => {
            return (
              <Box display="inline" key={damage_index}>
                <Box display="inline">
                  {damage.image_url.map((image, i) => {
                    return (
                      <Box
                        key={i}
                        display="inline"
                        style={{ cursor: 'pointer', marginRight: 6 }}
                        onClick={() =>
                          setSelectedDamageItem({ image: S3_URL + image })
                        }>
                        {damage.profile_type === 'pickup' ? (
                          <CameraRedIcon />
                        ) : (
                          <CameraGreenIcon />
                        )}
                      </Box>
                    );
                  })}
                </Box>
                {damage.profile_type === 'delivery' ? (
                  <Typography
                    display="inline"
                    style={{
                      color: '#303030',
                      marginLeft: 8,
                      marginRight: 8,
                      fontSize: 12,
                      fontWeight: 600
                    }}>
                    {convertToLabel(item[0])}
                  </Typography>
                ) : null}
              </Box>
            );
          })}
        </Box>
      );
    });

  const method3 = (
    <>
      <Grid container style={{ marginTop: 12 }}>
        {shipment_damages.map((damage, index) => {
          if (shipmentItemId === damage.shipment_item_id) {
            return damage.image_url.map((image, i) => {
              return (
                <Grid
                  item
                  lg={6}
                  key={i}
                  style={{ cursor: 'pointer', marginTop: 4 }}
                  onClick={() =>
                    setSelectedDamageItem({ image: S3_URL + image })
                  }>
                  {damage.profile_type === 'pickup' ? (
                    <CameraRedIcon />
                  ) : (
                    <CameraGreenIcon />
                  )}

                  <Typography
                    display="inline"
                    style={{
                      color: '#303030',
                      marginLeft: 8,
                      marginRight: 8,
                      fontSize: 12,
                      fontWeight: 600
                    }}>
                    {convertToLabel(damage.profile_title)}
                  </Typography>
                </Grid>
              );
            });
          }
          return null;
        })}
      </Grid>
    </>
  );

  return (
    <Box>
      {/* {method1} */}

      {/* {method2} */}

      {method3}
    </Box>
  );
};

export default ShipmentItemDamages;
