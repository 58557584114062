import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Snackbar from 'components/Snackbar';
import {
    Button,
    makeStyles,
    CircularProgress,
    Box,
    Typography
} from '@material-ui/core';
import {
    upgradeSubscription,
    cancelSubscription,
} from 'reducers/SubScriptionPlan';
import { ReactComponent as RightIcon } from 'assets/images/common/rightIconButton.svg';
import { useHistory } from 'react-router';
import { STRIPE_TABLE_PAGE } from 'utils/routesUtils';
import Popup from 'components/Popup';
import RcCheckbox1 from 'components/Form/RcCheckBox1';

const UpgradePlan = ({
    plan,
    cancel_subscription_loading,
    cancel_premium_Subscription,
    cancelSubscription,
    currentPlan,
    upgradeSubscription,
    upgrade_premium_subscription_loading,
    upgrade_premium_subscription,
    subscription_status,
    scheduled_for_cancelation,
    setStripeScriptLoaded
}) => {

    const classes = useStyles();
    const history = useHistory();
    const snackbarDataInitial = null;
    const [snackbar, setSnackbar] = useState(snackbarDataInitial);
    const [open, setOpen] = useState(false)
    const [checkbox, setCheckbox] = useState(false)

    const handleUpgradeClick = async (plan) => {
        try {
            if (plan === "Premium") {
                upgradeSubscription();
            } else {
                setOpen(true);
            }
        } catch (error) {
            console.error("Error during payment:", error);
        }
    };
    const handleCancel = () => {
        setCheckbox(false);
        setOpen(false);
    };
    useEffect(() => {
        if (cancel_premium_Subscription == true) {
            setOpen(false);
            setCheckbox(false);
            setSnackbar({
                type: 'success', message: 'Subscription cancelled successfully'
            });
        }
    }, [cancel_premium_Subscription]);
    useEffect(() => {
        if (upgrade_premium_subscription == true && subscription_status !== null) {
            switch (subscription_status) {
                case 'USE_STRIPE_TABLE':
                    setStripeScriptLoaded(true);
                    break;
                case 'RENEWED':
                    setSnackbar({ type: 'success', message: 'Subscription renewed successfully' });
                    break;
                case 'SUBSCRIBED':
                    setSnackbar({ type: 'success', message: 'Thank you for subscribing' });
                    break;
                default:
                    break;
            }
        }
    }, [upgrade_premium_subscription]);

    const getLabelBasedOnPlan = (plan) => {
        switch (plan) {
            case 'Free':
                if (currentPlan === "Premium Plan"
                    && scheduled_for_cancelation === false) {
                    return "Downgrade Plan";
                } else {
                    return (
                        <>
                            Current Plan
                            <RightIcon style={{ marginLeft: 8 }} />
                        </>
                    );
                }
            case 'Premium':
                if (currentPlan === "Premium Plan"
                    && scheduled_for_cancelation === false) {
                    return (
                        <>
                            Current Plan
                            <RightIcon style={{ marginLeft: 8 }} />
                        </>
                    );
                } else {
                    return 'Upgrade Plan';
                }
        }
    };
    const label = () => {
        return (
            <Typography style={{
                color: "#6B6B6B",
                fontSize: 15, fontWeight: 600,
                marginLeft: 6,
                alignItems: 'center'
            }}>
                I understand how these changes will affect my account
            </Typography>

        )
    }
    if (plan === "Free") {
        return (
            <>
                <Popup
                    open={open}
                    onclosehandler={() => setOpen(false)}
                >
                    <Box style={{ margin: 20 }}>
                        <Typography className={classes.popupheading}>
                            Are you sure you want to downgrade to the Free plan?
                        </Typography>
                        <Typography className={classes.popupSubheading}>
                            Are you sure you want to cancel your paid subscription? You will lose access to all <b>Premium</b> features.
                        </Typography>
                        <RcCheckbox1
                            label={label()}
                            checked={checkbox}
                            onChange={() => setCheckbox(!checkbox)}
                        />
                    </Box>

                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            float: 'right',
                            alignItems: 'center',
                            marginTop: 12
                        }}
                    >
                        <Button
                            className={classes.cancelbtn}
                            onClick={() => handleCancel()}
                            disabled={cancel_subscription_loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.downgradebtn}
                            onClick={() => cancelSubscription()}
                            disabled={
                                checkbox === false ||
                                cancel_subscription_loading ||
                                scheduled_for_cancelation === true ||
                                currentPlan === "Free Plan"
                            }
                        >
                            {cancel_subscription_loading ? (
                                <CircularProgress size={25} color='inherit' thickness={4} />
                            ) : (
                                <Typography>Switch to Free Plan</Typography>
                            )}
                        </Button>
                    </Box>
                </Popup>

                <Button
                    onClick={() => handleUpgradeClick(plan)}
                    className={classes.upgradebutton}
                    disabled={
                        scheduled_for_cancelation === true ||
                        currentPlan === "Free Plan"
                    }
                    style={{
                        backgroundColor: currentPlan === "Premium Plan" && scheduled_for_cancelation === false
                            ? "#FFFFFF"
                            : "#F0F0F0",
                        color: currentPlan === "Premium Plan" && scheduled_for_cancelation === false
                            ? "#000000"
                            : "#A5A5A5",
                        border: "2px solid #F0F0F0",
                    }}
                >
                    {getLabelBasedOnPlan(plan)}
                </Button>

            </>
        );
    }
    else if (plan === "Premium") {
        return (
            <>
                <Snackbar
                    data={snackbar}
                    onClose={() => setSnackbar(snackbarDataInitial)}
                />
                <Button
                    onClick={() => handleUpgradeClick(plan)}
                    className={classes.upgradebutton}
                    disabled={
                        upgrade_premium_subscription_loading ||
                        (currentPlan === "Premium Plan" && scheduled_for_cancelation === false)
                    }
                    style={{
                        backgroundColor:
                            currentPlan === "Premium Plan" && scheduled_for_cancelation === false
                                ? "#F0F0F0"
                                : "#ED3D2E",
                        color:
                            currentPlan === "Premium Plan" && scheduled_for_cancelation === false
                                ? "#A5A5A5"
                                : "#FFFFFF",
                    }}
                >
                    {upgrade_premium_subscription_loading ? (
                        <CircularProgress size={25} color='inherit' thickness={4} />
                    ) : (
                        getLabelBasedOnPlan(plan)
                    )}
                </Button>
           </>
        );
    }}

const useStyles = makeStyles(theme => ({
    heading: {
        fontSize: 18,
        fontWeight: 700,
        color: '#6B6B6B',
        marginBottom: 12
    },
    subheading: {
        fontSize: 15,
        color: '#8E8E8E',
        marginBottom: 12,
        maxWidth: '75%'
    },
    radiotext: {
        fontWeight: 600,
        color: "#000000"
    },
    upgradebutton: {
        borderRadius: '11px',
        height: 40,
        fontSize: 13,
        fontWeight: 700,
        position: 'absolute',
        bottom: 20,
        left: 20,
        right: 30,
        marginTop: 20
    },
    textfield: {
        marginTop: 12,
        marginBotoom: 16,
        borderRadius: '15px',
        border: '2px solid #CACACA',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    popupheading: {
        paddingTop: 20,
        marginBottom: 20,
        color: '#6B6B6B',
        fontSize: 18,
        fontWeight: 700
    },
    popupSubheading: {
        size: 15,
        marginRight: 12,
        marginTop: 12,
        color: '#8E8E8E',
        marginBottom: 20,
        fontWeight: 600
    },
    downgradebtn: {
        borderRadius: 11,
        width: 170,
        marginRight: 28,
        height: 40,
        fontSize: 12,
        background: '#EE3C2D',
        color: '#fff',
        border: '1px solid #EE3C2D',
        fontWeight: 700,
        '&.Mui-disabled': {
            backgroundColor: '#F0F0F0',
            color: '#A5A5A5',
            border: 'none',
            cursor: 'not-allowed',
        },
        '&:hover': {
            backgroundColor: '#EE3C2D', 
            border: '1px solid #EE3C2D', 
            opacity:1
    }
},
    cancelbtn: {
        border: '2px solid #EE3C2D',
        color: '#EE3C2D',
        fontWeight: 700,
        marginRight: 20,
        width: 94,
        height: 38,
        borderRadius: 11,
        background: '#FFFFFF',
        color: '#EE3C2D',
        border: '2px solid #EE3C2D',
        '&.Mui-disabled': {
            background: '#FFFFFF',
            color: '#EE3C2D',
            border: '2px solid #EE3C2D',
            cursor: 'not-allowed',
        },
    }
}))

const mapStateToProps = (state) => {
    return {
        subscription_details: state.SubscriptionPlan.subscription_details,
        cancel_subscription_loading: state.SubscriptionPlan.cancel_subscription_loading,
        cancel_premium_Subscription: state.SubscriptionPlan.cancel_premium_Subscription,
        upgrade_premium_subscription_loading: state.SubscriptionPlan.upgrade_premium_subscription_loading,
        upgrade_premium_subscription: state.SubscriptionPlan.upgrade_premium_subscription,
        subscription_status: state.SubscriptionPlan.subscription_status,
        currentPlan: state.SubscriptionPlan.currentPlan,
        scheduled_for_cancelation: state.SubscriptionPlan.scheduled_for_cancelation
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        cancelSubscription: () => dispatch(cancelSubscription()),
        upgradeSubscription: () => dispatch(upgradeSubscription()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePlan);