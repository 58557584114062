import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import clsx from 'clsx';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Mixpanel from 'mixpanel-browser';

//services
import {
  getUserTypeService,
  getUserIdService,
  checkUserTokenService,
  removeUserTokenService,
  saveUserTokenService,
} from 'services/login';
import { getUserDetailService } from 'services/users';
import {
  sendOtpEmailService,
  sendOtpForgetPassService,
  verifyOtpEmailService,
  verifyOtpForgetPassService
} from 'services/Verification';

// Util
import { DASHBOARD_PAGE, LOGIN_PAGE } from 'utils/routesUtils';
import { ERROR, PENDING_EMAIL, ROADCALL_USER_TOKEN, SUCCESS } from 'utils/constants';
import { errorMessageSingleLine } from 'utils/errorUtils';

// Container
import MainContainer from '../MainContainer';
import ButtonWithBackground from 'components/Form/Button';
import ConfirmPassword from '../ForgetPassword/ConfirmPassword';
import { trackEvent } from 'utils/MixPanel';

const EmailVerification = ({ setSnackbar, forgetPassEmail, user_data }) => {
  const history = useHistory();

  const [otp, setOtp] = useState('');

  const [waitTime, setWaitTime] = useState(0);

  const [confirmPassForm, setConfirmPassForm] = useState(false);

  const classes = useStyles();

  const { handleSubmit, watch, getValues, setValue } = useForm();
  
  const onSubmit = async data => {
    setValue('loading', true);

    if (forgetPassEmail) {
      try {
        const res = await verifyOtpForgetPassService(forgetPassEmail, otp);
        if (res.validate === true) setConfirmPassForm(true);
        else {
          setSnackbar({
            type: ERROR,
            message: 'Incorrect OTP!'
          });
          setOtp('');
        }
      } catch (error) {
        setSnackbar({
          type: ERROR,
          message: errorMessageSingleLine(error?.data)
        });
      }

      setValue('loading', false);

      return;
    }

    try {
      const res = await verifyOtpEmailService({
        email: getValues('email'),
        otp
      });

      if (res.validate === true) {
        trackEvent('Otp Verified');
        history.push(DASHBOARD_PAGE);
        setSnackbar({
          type: SUCCESS,
          message: `OTP verified!`
        });

      }

      setValue('loading', false);
    } catch (error) {
      setSnackbar({
        type: ERROR,
        message: errorMessageSingleLine(error?.data)
      });
      setValue('loading', false);
    }
  };

  const sendOtpEmail = async () => {
    setValue('loading', true);

    if (!checkUserTokenService()) {
      history.push(LOGIN_PAGE);
      return false;
    }

    const res = await getUserDetailService(
      getUserIdService(),
      getUserTypeService(false)
    );

    if (res.onboarding_step !== PENDING_EMAIL) {
      history.push(LOGIN_PAGE);
      return false;
    }

    //await sendOtpEmailService(res.email);
    setValue('email', res.email);

    setValue('loading', false);
  };

  useEffect(() => {
    if (!forgetPassEmail) sendOtpEmail();
    setWaitTime(30);
  }, []);
    
  // Decrement resend email timer.
  useEffect(() => {
    let resendOTPInterval = setInterval(
      function() {
        if (waitTime) {
          setWaitTime(waitTime - 1);
        } else {
          clearInterval(resendOTPInterval);
        }
      }.bind(this),
      1000
    );

    return () => {
      clearInterval(resendOTPInterval);
    };
  }, [waitTime]);

  const resendOtp = async () => {
    setValue('loading', true);
    if (forgetPassEmail) {
      try {
        await sendOtpForgetPassService(forgetPassEmail);
      } catch (e) {
        setSnackbar({
          type: ERROR,
          message: `OTP could not sent!`,
          details: ''
        });
      }
    } else {
      try {
        await sendOtpEmailService(getValues('email'));
      } catch (e) {
        setSnackbar({
          type: ERROR,
          message: `OTP could not sent!`,
          details: ''
        });
      }
    }

    setSnackbar({
      type: SUCCESS,
      message: `OTP sent successfully!`,
      details: ''
    });

    setWaitTime(30);

    setValue('loading', false);
  };

  const numberOfInputs = 4;

  return (
    <MainContainer>
      <Box className="navigation-box navigate-absolute">
        <Typography style={{ fontSize: 16, fontWeight: 600 }}>
          <Link to={LOGIN_PAGE}>Sign In</Link>
        </Typography>
      </Box>
      {confirmPassForm ? (
        <ConfirmPassword
          setSnackbar={setSnackbar}
          email={forgetPassEmail}
          otp={otp}
        />
      ) : (
        <Box className="form-box mt-5">
          <Typography
            variant="h3"
            className="heading"
            style={{ marginBottom: 0 }}>
            We've sent you a code to confirm that it's you!
          </Typography>
          <Typography
            style={{
              color: '#6B6B6B',
              fontSize: 10,
              marginTop: 14,
              textAlign: 'center'
            }}>
            We emailed you the four digit code
            {forgetPassEmail || getValues('email')
              ? ` to ${forgetPassEmail || getValues('email')}.`
              : '.'}
          </Typography>
          <Typography variant="h6" className={clsx('heading', classes.title)}>
            Enter your verification code
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className="verify-email-form">
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <OtpInput
                  value={otp}
                  onChange={otp => setOtp(otp)}
                  isInputNum={true}
                  className={classes.otpInput}
                  numInputs={numberOfInputs}
                  containerStyle={classes.otpInputContainer}
                  inputStyle={{
                    background: '#fff',
                    borderRadius: 12,
                    boxShadow: '0px 3px 6px #00000029'
                  }}
                />
                <Typography
                  variant="h6"
                  className="privacy-statement"
                  style={{ marginTop: 16, marginBottom: 16, fontSize: 10 }}>
                  Keep this window open while checking for your Verification
                  code. Remember to check your spam folder!
                </Typography>
              </Grid>

              <Grid item lg={12} sm={12} md={12} xs={12}>
                <ButtonWithBackground
                  fullWidth
                  size="large"
                  disabled={watch('loading')}
                  disabledOnly={otp.length !== numberOfInputs}
                  buttonType="submit"
                  label="Submit"
                  style={{ fontWeight: 600 }}
                />
                <Box className={classes.SigninNavigateBox}>
                  {waitTime ? (
                    <Button
                      disabled={true}
                      className={
                        classes.ResedOtpButton
                      }>{`Resend email in ${waitTime} seconds`}</Button>
                  ) : (
                    <Button
                      disableRipple
                      disabled={watch('loading') ? true : false}
                      onClick={() => resendOtp()}
                      className={classes.ResedOtpButton}>
                      Resend Code
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </MainContainer>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    ResedOtpButton: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.primary.dark,
      padding: 0
    },
    SigninNavigateBox: {
      textAlign: 'center',
      fontWeight: 500,
      marginTop: 10,
      '& a': {
        color: theme.palette.primary.dark
      }
    },
    title: {
      marginBottom: '10px !important',
      marginTop: 35
    },
    otpInputContainer: {
      justifyContent: 'center',
      '& > div': {
        height: '65px'
      }
    },
    otpInput: {
      width: '15%',
      margin: 8,
      '& input': {
        color: '#b1aaaa',
        width: '100% !important',
        height: '80%',
        background: '#cccc',
        border: 0
      }
    }
  })
);

const mapStateToProps = state => {
  return {
    user_data: state.User.user_data
  };
};
export default connect(mapStateToProps)(EmailVerification);
