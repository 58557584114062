import React from 'react';
import {
  makeStyles,
  createStyles,
  FormControl,
  FormHelperText,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';
import CheckIcon from '@mui/icons-material/Check';

const CircleUnchecked = ()=>{
  return(
    <>
      <CheckIcon sx={{ color: "white" , fontSize: "20px", border: "1px solid grey", borderRadius: '5px' }} />
    </>
  )
}

const CircleChecked = ()=>{

  return(
    <>
    <CheckIcon sx={{ color: "red" , fontSize: "20px", border: "1px solid grey", borderRadius: '5px' }} />
    </>
  )
}
const RcCheckbox1 = React.forwardRef(
  ({ label, checked, error, className, changeHandler, ...rest }, ref) => {
    const userType = getUserTypeService();
    const classes = useStyles({ userType });

    return (
      <FormControl required error={Boolean(error)} ref={ref}>
        <FormControlLabel
          className={classes.controlLabel}
          control={
            <Checkbox
            icon={<CircleUnchecked />}
              checkedIcon={<CircleChecked />}
              disableRipple
              style={{ background: 'transparent' }}
              color="default"
              checked={checked}
              onChange={changeHandler}
              className={clsx(checked ? classes.checked : '', className)}
              {...rest}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 70,
                  borderRadius: 20
                }
              }}
            />
          }
          label={label}
        />

        {error && (
          <FormHelperText className={classes.helperText}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

RcCheckbox1.defaultProps = {
  checked: false,
  error: ''
};

RcCheckbox1.propTypes = {
  className: PropTypes.string
};

const useStyles = makeStyles(theme =>
  createStyles({
    checked: {
      color: props =>
        props.userType === SHIPPER
          ? `${theme.palette.secondary.dark} !important`
          : `${theme.palette.primary.dark} !important`,
          borderRadius: 20

    },
    controlLabel: {
      marginBottom: 0,
      color: '#454647',
      fontSize:700

    },
    helperText: {
      marginTop: 0
    }
  })
);

export default RcCheckbox1;
