import React from 'react';
import Tab from '@mui/material/Tab';

import { ReactComponent as TickGrayIcon } from 'assets/images/common/tick-gray.svg';
import { DOCUMENT_VERIFICATION_STEPS } from 'utils/constants';
import { convertToLabel } from 'utils/textUtils';

const DocumentTab = ({
  isDocUploaded,
  label,
  tabType,
  currentTabValue,
  complete,
  uppercase,
  ...props
}) => {
  const getColor = () => {
    if (complete) return '#cdcecd';
    return isDocUploaded(tabType)
      ? '#cdcecd'
      : currentTabValue === DOCUMENT_VERIFICATION_STEPS.indexOf(tabType)
      ? '#EE3C2D'
      : '#6B6B6B';
  };

  const getIcon = () => {
    if (complete) return <TickGrayIcon />;
    return isDocUploaded(tabType) ? <TickGrayIcon /> : null;
  };

  return (
    <Tab
      disabled={complete === true}
      disableRipple
      label={convertToLabel(label)}
      icon={getIcon()}
      iconPosition="end"
      style={{
        fontSize: 12,
        color: getColor(),
        fontWeight: 600,
        marginBottom: '-16px',
        textTransform: uppercase ? 'uppercase' : 'capitalize'
      }}
      {...props}
    />
  );
};

export default DocumentTab;
