import { TableBody } from '@material-ui/core';
import React from 'react';
import RowLoading from '../RowLoading';

const TableDataSkeleton = ({ column }) => {
  return (
    <TableBody style={{backgroundColor:'#F5F7FB'}}>
      <RowLoading columns={column} />
      <RowLoading columns={column} />
      <RowLoading columns={column} />
    </TableBody>
  );
};

export default TableDataSkeleton;
