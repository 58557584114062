import React, { Component } from 'react';
import Particles from 'react-tsparticles';

export default class ExampleWrapperSimple extends Component {
  render() {
    return (
      <div style={{ position: 'absolute', height: '100vh', width: '100vw' }}>
        <Particles />
      </div>
    );
  }
}
