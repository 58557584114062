import axiosClient from 'utils/axiosClient';
import { errorMessageSingleLine } from 'utils/errorUtils';

const version = process.env.REACT_APP_API_VER;

//declarations
const SET_ERROR = 'SET_ERROR_DRIVERS';
const SET_DRIVERS_LOADING = 'SET_DRIVERS_LOADING';
const SET_DRIVERS_NEXT_LOADING = 'SET_DRIVERS_NEXT_LOADING';
const SET_DRIVERS = 'SET_DRIVERS';
const SET_DRIVER_LOADING = 'SET_DRIVER_LOADING';
const SET_DRIVER = 'SET_DRIVER';
const SET_ADD_DRIVER_POPUP_VISIBLE = 'SET_ADD_DRIVER_POPUP_VISIBLE';
const SET_ADD_DRIVER_LOADING = 'SET_ADD_DRIVER_LOADING';
const SET_ADDED_DRIVER_ID = 'SET_ADDED_DRIVER_ID';
const SET_EDITABLE_DRIVER_ID = 'SET_EDITABLE_DRIVER_ID';
const SET_UPDATED_DRIVER = 'SET_UPDATED_DRIVER';
const SET_DELETABLE_DRIVER = 'SET_DELETABLE_DRIVER';
const SET_DELETE_DRIVER_LOADING = 'SET_DELETE_DRIVER_LOADING';
const SET_DELETED_DRIVER = 'SET_DELETED_DRIVER';

//initial state
const initialState = {
  error: '',
  drivers: null,
  driver: null,
  driver_loading: false,
  drivers_loading: false,
  drivers_next_loading: false,
  add_driver_popup_visible: false,
  add_driver_loading: false,
  added_driver_id: '',
  editable_driver_id: '',
  deletable_driver: null,
  delete_driver_loading: false,
  deleted_driver_id: ''
};

export const setEditableDriverId = (driver_id = '') => {
  return dispatch => {
    dispatch({
      type: SET_EDITABLE_DRIVER_ID,
      data: driver_id
    });
  };
};

export const setDeletableDriver = driver => {
  return dispatch => {
    dispatch({
      type: SET_DELETABLE_DRIVER,
      data: driver
    });
  };
};

export const addDriver = data => {
  return async dispatch => {
    dispatch({ type: SET_ADD_DRIVER_LOADING });

    try {
      const response = await axiosClient.post(`/trucker/${version}/`, data);

      dispatch({
        type: SET_ADDED_DRIVER_ID,
        data: response.data.trucker_id
      });

      dispatch(getDrivers());
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response.data)
      });
    }
  };
};

export const updateDriver = (driver_id, data) => {
  return async dispatch => {
    dispatch({ type: SET_ADD_DRIVER_LOADING });

    try {
      await axiosClient.patch(`/trucker/${version}/${driver_id}/`, data);

      dispatch({
        type: SET_UPDATED_DRIVER
      });

      dispatch(getDrivers());
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response.data)
      });
    }
  };
};

export const deleteDriver = driver_id => {
  return async dispatch => {
    dispatch({ type: SET_DELETE_DRIVER_LOADING });

    try {
      await axiosClient.delete(`/trucker/${version}/${driver_id}/`);

      dispatch({
        type: SET_DELETED_DRIVER,
        data: driver_id
      });

      dispatch(getDrivers());
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response.data)
      });
    }
  };
};

export const setDriverPopupVisible = (bool = true) => {
  return dispatch => {
    dispatch({
      type: SET_ADD_DRIVER_POPUP_VISIBLE,
      data: bool
    });
  };
};

export const getDrivers = nextUrl => {
  return async dispatch => {
    dispatch({
      type: nextUrl ? SET_DRIVERS_NEXT_LOADING : SET_DRIVERS_LOADING
    });

    try {
      const response = await axiosClient.get(
        nextUrl || `/trucker/${version}/`,
        {
          params: {
            ordering: '-updated_at'
          }
        }
      );

      dispatch({
        type: SET_DRIVERS,
        data: response.data,
        hasNext: Boolean(nextUrl)
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.statusText)
      });
    }
  };
};

export const getDriver = trucker_id => {
  return async dispatch => {
    dispatch({ type: SET_DRIVER_LOADING });

    try {
      const response = await axiosClient.get(
        `/trucker/${version}/${trucker_id}/`
      );

      dispatch({ type: SET_DRIVER, data: response.data });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        data: errorMessageSingleLine(error.response?.statusText)
      });
    }
  };
};

//reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ADDED_DRIVER_ID:
      return {
        ...state,
        add_driver_loading: false,
        add_driver_popup_visible: false,
        added_driver_id: action.data
      };
    case SET_UPDATED_DRIVER:
      return {
        ...state,
        add_driver_popup_visible: false,
        add_driver_loading: false
      };
    case SET_DELETE_DRIVER_LOADING:
      return {
        ...state,
        deleted_driver_id: '',
        delete_driver_loading: true
      };
    case SET_DELETED_DRIVER:
      return {
        ...state,
        delete_driver_loading: false,
        deletable_driver: null,
        deleted_driver_id: action.data
      };
    case SET_DELETABLE_DRIVER:
      return {
        ...state,
        deletable_driver: action.data
      };
    case SET_EDITABLE_DRIVER_ID:
      return {
        ...state,
        editable_driver_id: action.data
      };
    case SET_ADD_DRIVER_LOADING:
      return {
        ...state,
        error: '',
        added_driver_id: '',
        add_driver_loading: true
      };
    case SET_ADD_DRIVER_POPUP_VISIBLE:
      return {
        ...state,
        error: '',
        add_driver_popup_visible: action.data
      };
    case SET_DRIVERS_LOADING:
      return {
        ...state,
        error: '',
        drivers_loading: true
      };
    case SET_DRIVERS_NEXT_LOADING:
      return {
        ...state,
        error: '',
        drivers_next_loading: true
      };
    case SET_DRIVER_LOADING:
      return {
        ...state,
        error: '',
        driver_loading: true
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.data,
        drivers_loading: false,
        driver_loading: false,
        drivers_next_loading: false,
        add_driver_loading: false,
        delete_driver_loading: false
      };
    case SET_DRIVERS:
      const updated_drivers = action.hasNext
        ? {
            ...action.data,
            results: state.drivers.results.concat(action.data.results)
          }
        : action.data;
      return {
        ...state,
        drivers_loading: false,
        drivers_next_loading: false,
        drivers: updated_drivers
      };
    case SET_DRIVER:
      return {
        ...state,
        driver_loading: false,
        driver: action.data
      };

    default:
      return state;
  }
}
