import React from 'react';
import { useForm } from 'react-hook-form';
import RcTextfield from 'components/Form/Textfield';
import ButtonWithBackground from 'components/Form/Button';
import { sendOtpForgetPassService } from 'services/Verification';
import { ERROR } from 'utils/constants';
import { errorMessageSingleLine } from 'utils/errorUtils';
import { LOGIN_PAGE } from 'utils/routesUtils';
import MainContainer from '../MainContainer';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import EmailVerification from '../EmailVerification';
import { Link } from 'react-router-dom';

const ForgetPassword = ({ setSnackbar }) => {
  const formDefaultValues = {
    email: '',
    loading: false,
    validateOtpForm: false
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: formDefaultValues
  });

  const onSubmit = async data => {
    try {
      setValue('loading', true);
      await sendOtpForgetPassService(data.email);
      setValue('validateOtpForm', true);
    } catch (e) {
      setSnackbar({
        type: ERROR,
        message: errorMessageSingleLine(e?.data)
      });
    }
    setValue('loading', false);
  };

  return (
    <>
      {watch('validateOtpForm') ? (
        <EmailVerification
          setSnackbar={setSnackbar}
          forgetPassEmail={watch('email')}
        />
      ) : (
        <MainContainer>
          {/* <Box className="navigation-box navigate-absolute">
            <Typography style={{ fontSize: 16, fontWeight: 600 }}>
              <Link to={LOGIN_PAGE}>Sign in</Link>
            </Typography>
          </Box> */}
          <Box className="form-box">
            <Typography variant="h3" className="heading">
              Reset Password
            </Typography>
            <Typography
              style={{
                color: '#6B6B6B',
                textAlign: 'center',
                marginBottom: 40
              }}>
              Enter the email associated with your account and we’ll send you a
              verification code.
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <RcTextfield
                    InputLabelProps={{ shrink: false }}
                    placeholder="Email address"
                    value={watch('email')}
                    error={errors.email}
                    {...register('email', {
                      required: 'Please enter a valid email address'
                    })}
                    changeHandler={e =>
                      setValue('email', e.target.value.toLowerCase())
                    }
                  />
                </Grid>
                <Grid item lg={12} sm={12} md={12} xs={12}>
                  <ButtonWithBackground
                    label="Send OTP"
                    fullWidth
                    size="large"
                    buttonType="submit"
                    disabled={watch('loading')}
                    style={{ fontWeight: 600 }}
                  />
                  <Box style={{ textAlign: 'center', marginTop: 20 }}>
                    <Link
                      to={LOGIN_PAGE}
                      style={{ color: '#EF3B2D', fontWeight: 600 }}>
                      Back to login
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </MainContainer>
      )}
    </>
  );
};

export default ForgetPassword;
