import React, { useEffect, useState , useRef} from 'react'
import {
    Box,
    Typography,
    makeStyles,
    Divider
} from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Tabs from '../../Tabs/Tabs';
import PageLoader from 'components/loader';
import Payment_details from './PaymentDetails';
import StripeIcon from 'assets/images/common/StripeIcon.svg';
import Background from 'assets/images/common/background.png';
import {
    getSubscriptionDetails, setStripeTable
} from 'reducers/SubScriptionPlan';
import UpgradePlan from './UpgradePlan';
import { ReactComponent as BackIcon } from 'assets/images/onboarding/back.svg';
import { trackEvent } from 'utils/MixPanel';

const Subscription = ({
    getSubscriptionDetails,
    subscription_details,
    subscription_details_loading,
    user_data,
    setStripeTable,
    title,
    subTitle,
    TabOptions
}) => {
    const classes = useStyles();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const currentTab = params.get('tab')
    const[stripeScriptLoaded, setStripeScriptLoaded] = useState(false)
    const stripeTableRef = useRef(null);
    const customerEmail = user_data.email; 

    useEffect(() => {
        trackEvent('Subscription Page Nav');
    }, []);
    
    useEffect(() => {
            getSubscriptionDetails();
    }, []);

    useEffect(() => {
        if (stripeScriptLoaded) {

            const script = document.createElement('script');
            script.src = 'https://js.stripe.com/v3/pricing-table.js';
            script.async = true;

            script.onload = () => {
                setStripeTable();

                const stripeTable = document.createElement('stripe-pricing-table');
                stripeTable.setAttribute('pricing-table-id', process.env.REACT_APP_STRIPE_PREMIUM_PLAN_PRICING_TABLE_ID);
                stripeTable.setAttribute('publishable-key', process.env.REACT_APP_STRIPE_PUBLISH_KEY);
                stripeTable.setAttribute('customer-email', customerEmail);

                if (stripeTableRef.current) {
                    stripeTableRef.current.appendChild(stripeTable);
                }
            };
            document.head.appendChild(script);
            return () => {
                document.head.removeChild(script);
            };
        }
    }, [stripeScriptLoaded]);

    if ((subscription_details == null)
        || subscription_details_loading) {
        return <PageLoader />
    }
    return (
        <>
           <Box spacing={3} className={classes.container}>
           {TabOptions == 'true' && (
               <Box className={classes.section}>
                    <Tabs
                        currentTab={currentTab || 'account'}
                        className={clsx(
                            classes.tabs,
                            currentTab ? classes.tabInfoHide : ''
                        )}
                    />
                </Box>
           )}
         {TabOptions=='true' && ( <Divider
                    orientation="vertical"
                    flexItem
                    style={{
                        marginRight: '-1px',
                    }}
                    className={classes.verticalDivider}
                />)}
                {stripeScriptLoaded ? (
                    <>
                     <BackIcon
                        onClick={() => { setStripeScriptLoaded(false) }}
                        style={{
                            cursor: 'pointer',
                            marginTop: 20,
                             marginLeft: 12
                            }}
                        />
                    <Box 
                        style={{
                            marginTop: 12,
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}>
                            <div ref={stripeTableRef}
                            />
                        </Box>
                    </>
                ) : (
                    <Box className={classes.section2} style={{ width: TabOptions=='true'? '75%' : '100%'}}>
                            <Typography className={classes.heading}>
                                {title}
                            </Typography>
                            <Typography className={classes.subheading}
                                style={{
                                    marginBottom: TabOptions ? "5%" : '3%',
                                }}>
                                {subTitle}
                            </Typography>

                <Box className={classes.paymentCards}>
                    <Box style={{
                        backgroundColor: '#ffffff',
                        border: '2px solid #EAE9EF',
                        boxShadow: '0px 0px 15px #0000000B',
                    }}
                        className={classes.card}>
                        <Payment_details plan={"Free"}
                            subHeading={"Perfect plan for starters."}
                            amount={0}
                        />
                        <UpgradePlan plan={'Free'} setStripeScriptLoaded = {setStripeScriptLoaded} />
                    </Box>
                    <Box className={classes.card}
                        style={{
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #ED3D2E',
                            boxShadow: '0px 0px 15px #0000000B',
                        }}>
                        <Payment_details plan={"Premium"}
                            subHeading={"For small but efficient businesses."}
                            amount={39}
                        />
                        <UpgradePlan plan={'Premium'} setStripeScriptLoaded = {setStripeScriptLoaded} />
                    </Box>
                </Box>

                        <Box className={classes.footer}>
                            <Typography style={{
                                color: '#8E8E8E',
                                fontWeight: 600,
                                marginRight: 6
                            }}>
                                Powered by
                            </Typography>
                            <img className="stripeicon"
                                alt="Logo"
                                src={StripeIcon}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    )}
 const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    section: {
        width: '25%',
        minWidth: 350,
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            width: '100%'
        }
    },
    section2: {
        marginTop: theme.spacing(1),
        marginTop:40,
        marginBottom:-16,
        marginLeft: 1,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 30,
            width: '100%'
        },
        backgroundImage: `url(${Background})`,
        backgroundSize: ' 100% 50%',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
    },
    verticalDivider: {
        color: '#e5e5e5',
        minHeight: '90vh',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'unset'
        }
    },
    heading: {
        color: '#6B6B6B',
        fontSize: 30,
        fontWeight: 700,
        textAlign: 'center'
    },
    subheading: {
        fontsize: 19,
        fontWeight: 500,
        color: '#000000',
        marginTop: 8,
        textAlign: 'center'
    },
    paymentCards: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom:15,
        flexDirection: 'row',
        position: 'relative',
        maxWidth: 1280,
        paddingLeft: 30,
        paddingRight: 30,
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 30,
            maxWidth: 450,
        },
    },
    card: {
        borderRadius: 15,
        margin: '0 20px 40px',
        paddingBottom: 40,
        position: 'relative',
        maxWidth: 'calc(50% - 180px)',
        flex: '1 1 calc(50% - 40px)',
        [theme.breakpoints.down(1800)]: {
            maxWidth: 400,
        }
    },
    headbox: {
        position: 'absolute',
        left: '50%',
        top: '0%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#F2F2F2',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        padding: '10px 20px',
        borderRadius: '5px',
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
            whiteSpace: 'nowrap',
        }
    },
    headtext: {
        fontWeight: 700,
        color: '#909090',
        letterSpacing: 1.4
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom:'auto',
        paddingBottom:30,
        justifyContent: 'center',
        alignItems: 'center',
    }
}))

const mapStateToProps = (state) => {
    return {
        subscription_details_loading: state.SubscriptionPlan.subscription_details_loading,
        subscription_details: state.SubscriptionPlan.subscription_details,
        user_data: state.User.user_data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSubscriptionDetails: () => dispatch(getSubscriptionDetails()),
        setStripeTable: () => dispatch(setStripeTable())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);