import React from 'react';
import { Box, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';

// Assets.
import { ReactComponent as SortIcon } from 'assets/images/common/sort.svg';
import { ReactComponent as SortIcon2 } from 'assets/images/common/sort2.svg';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';

export const MySortIcon = ({ headerType, onClick, className }) => {
  if (headerType === 'secondary')
    return <SortIcon2 onClick={onClick} className={className} />;

  return <SortIcon onClick={onClick} className={className} />;
};

const Header = props => {
  const {
    list,
    clickHandler,
    ordering,
    headerType,
    showSortIconsWhileLoading
  } = props;

  const userType = getUserTypeService();

  const classes = useStyles({ headerType });

  const orderingIcon = row => {
    if ((ordering && clickHandler && row.label && row.label != 'Action') || showSortIconsWhileLoading) {
      return (
        <MySortIcon
          headerType={headerType}
          className={classes.icon}
          onClick={() => {
            if (clickHandler && ordering && row.label && row.label != 'Action')
              clickHandler(
                ordering.charAt(0) === '-' ? row.name : '-' + row.name
              );
          }}
        />
      );
    }
  };

  return (
    <TableHead className={userType === SHIPPER ? classes.head2 : classes.head} >
      <TableRow>
        {list.map((li, index) => (
          <TableCell key={index} className={classes.item}>
            {li.label}
            {orderingIcon(li)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles(theme => ({
  head: {
    background: props =>
      props.headerType === 'secondary' ? '#eff0f4' : theme.palette.primary.dark,
    border: props => (props.headerType === 'secondary' ? '1px solid #fff' : ''),
    borderTopRightRadius:'11px'
  },
  head2: {
    background: props =>
      props.headerType === 'secondary'
        ? '#eff0f4'
        : theme.palette.secondary.dark,
    border: props => (props.headerType === 'secondary' ? '1px solid #fff' : '')
  },
  item: {
    fontSize: 12,
    backgroundColor: props =>
      props.headerType === 'secondary' ? '#eff0f4' : 'initial',
    color: props =>
      props.headerType === 'secondary'
        ? '#abacc3'
        : theme.palette.secondary.main,
    fontWeight: 700,
     whiteSpace: 'nowrap'
    //paddingLeft: 30
  },
  icon: {
    cursor: 'pointer'
  }
}));

export default Header;
