import { getDeviceType } from "./systemUtils";

const RED = 'red';
const GREEN = 'green';

// Onboarding steps
const PERSONAL_DETAILS = 'personal_details';
const PENDING_EMAIL = 'pending_email';
const VERIFY_EMAIL = 'verify_email';
const COMPANY_DETAILS = 'company_details';
const RIDER_INFO = 'rider_info';
const UPLOAD_DOCUMENTS = 'upload_documents';
const PAYMENT_INFO = 'payment_info';
const RIDER_INFO_SKIPPED = 'rideinfo_skipped';

const VEHICLE = 'vehicle';
const DRIVER = 'driver';
const TRUCKS = 'trucks';

const ROADCALL_VERSION = 'ROADCALL_VERSION';
const ROADCALL_USER_TOKEN = 'ROADCALL_USER_TOKEN';
const LOADLIST_VISIBILITY ='LOADLIST_VISIBILITY';
const ROADCALL_ID = 'ROADCALL_ID'
const ROADCALL_FCM_TOKEN = 'ROADCALL_FCMTOKEN';
const APP_TOKEN = 'APP_TOKEN';
const ROADCALL_SAVE_SEARCHES = 'ROADCALL_SAVED_SEARCHES';
const ROADCALL_PICKUP_RECENT_SEARCHES = 'ROADCALL_PICKUP_RECENT_SEARCHES';
const ROADCALL_DROP_RECENT_SEARCHES = 'ROADCALL_DROP_RECENT_SEARCHES';
const ROADCALL_PICKUP_CONTACT_DETAILS = 'ROADCALL_PICKUP_CONTACT_DETAILS';
const ROADCALL_DROP_CONTACT_DETAILS = 'ROADCALL_DROP_CONTACT_DETAILS';
const ROADCALL_MY_CURRENT_LOCATION = 'ROADCALL_MY_CURRENT_LOCATION';
const ROADCALL_USER_DOC_VER_DIALOGUE_CLOSE =
  'ROADCALL_USER_DOC_VER_DIALOGUE_CLOSE';

const PICKUP = 'pickup';
const DROP = 'drop';
const CUSTOMER = 'customer'

// Map views.
const DUAL_VIEW = 'dual';
const MAP_VIEW = 'map';
const LIST_VIEW = 'list';

// Place bid steps.
const SHIPMENT_DETAIL = 'shipment_detail';
const MAKE_BID = 'make_bid';
const BOOKING_VIEW = 'booking_view';
const BID_SUCCESS = 'bid_success';
const BOOKING_SUCCESS = 'booking_success';
const ASSIGN_DRIVER = 'assign_driver';

const MESSAGES_LIMIT = 99;

const PREFIX_SHIPMENT = 'RC-SHIPMENT-';
const PREFIX_INVOICE = 'RC-SHIPMENT-INVOICE-'
const PREFIX_TRUCK = 'RC-TRUCK-';
const PREFIX_TRUCKER = 'RC-TRUCKER-';
const PREFIX_CARRIER = 'RC-CARRIER-';
const PREFIX_SHIPPER = 'RC-SHIPPER-';
const PREFIX_BROKER = 'RC-BROKER-';
const PREFIX_TRANSACTION = 'RC-TRANSACTION-';

const SOMETHING_WENT_WRONG = 'Something went wrong!';

const ERROR_TEXT_REQUIRED = 'This field is required';
const ERROR_TEXT_ZIP = 'invalid zipcode';
const ERROR_LOCATION_ACCESS =
  'Please allow Roadcall to access your current location!';

const INITIAL_ZOOM = 10;

const ERROR = 'error';
const SUCCESS = 'success';

const USERS = 'users';
const PLATFORM = getDeviceType();
const EXISTING_USER = 'ROADCALL_EXISTING_USER';

const TRUCK_STATUS = {
  ACTIVE: 'active',
  PAUSED: 'paused',
  EXPIRED: 'expired'
};

const LOAD_STEP = {
  ALL: 'all',
  NEW: 'new',
  ACTIVE: 'active',
  DELIVERED: 'delivered',
  ARCHIVED: 'archived',
  PENDING: 'pending',
  MY_JOBS: 'private',
  ORDERED: 'ordered',
  UNASSIGNED: 'unassigned',
  CLOSED:'closed',
  MORE:'more',
  OFFER:'offer'
};

const LOAD_STATUS = {
  TRUCK_ASSIGNED: 'truck_assigned',
  PENDING: 'pending',
  ASSIGNED: 'assigned',
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
  QUOTED: 'quoted',
  TRANSPORTER_ASSIGNED: 'transporter_assigned',
  ACCEPTED: 'accepted',
  PICKED_UP: 'picked_up',
  ON_THE_WAY: 'on_the_way',
  ORDERED: 'ordered',
  OFFER:'offer',
  DELIVERED: 'delivered'
};

const CATEGORY = {
  VEHICLE: 'vehicle',
  FREIGHT: 'freight',
  CONSTRUCTION: 'construction',
  HOME_N_OFFICE: 'home_and_office'
};

const VEHICLE_SUBCATEGORY = {
  MOTOR_VEHICLE_PARTS: 'motor_vehicle_parts',
  CARS: 'cars',
  LIGHT_DUTY_TRUCKS: 'light_duty_trucks',
  TRAILERS: 'trailers',
  HEAVY_DUTY_TRUCKS: 'heavy_duty_trucks',
  MOTERCYCLES: 'motorcycles',
  BOATS: 'boats',
  JETSKI: 'jet_ski',
  CAMPERS_N_RVS: 'campers_and_rvs',
  COMMERCIAL_VEHICLES: 'commercial_vehicles',
  MOTOR_SPORTS_VEHICLE: 'motor_sport_vehicle'
};

const VEHICLE_SUBCATEGORIES = [
  VEHICLE_SUBCATEGORY.MOTOR_VEHICLE_PARTS,
  VEHICLE_SUBCATEGORY.CARS,
  VEHICLE_SUBCATEGORY.LIGHT_DUTY_TRUCKS,
  VEHICLE_SUBCATEGORY.TRAILERS,
  VEHICLE_SUBCATEGORY.HEAVY_DUTY_TRUCKS,
  VEHICLE_SUBCATEGORY.MOTERCYCLES,
  VEHICLE_SUBCATEGORY.BOATS,
  VEHICLE_SUBCATEGORY.JETSKI,
  VEHICLE_SUBCATEGORY.CAMPERS_N_RVS,
  VEHICLE_SUBCATEGORY.COMMERCIAL_VEHICLES,
  VEHICLE_SUBCATEGORY.MOTOR_SPORTS_VEHICLE
];

const HOME_N_OFFICE_SUBCATEGORY = {
  BOXES: 'boxes',
  FURNITURE: 'furniture',
  APPLIANCES: 'appliances',
  FRAGILE_BELONGINGS: 'fragile_belongings',
  GYM_N_SPORTS_EQUIPMENTS: 'gym_and_sports_equipment',
  ELECTRNICS: 'electronics',
  OUTDOOR_TOYS: 'outdoor_toys'
};

const CONSTRUCTION_SUBCATEGORY = {
  TRACTORS: 'tractors',
  EXCAVATORS: 'excavators',
  FORKLIFTS: 'forklifts',
  TELEHANDLERS: 'telehandlers',
  CRANES: 'cranes',
  BULLDOZERS: 'buldozers',
  DUMPTRUCKS: 'dumptrucks',
  BACKHOE_LOADERS: 'backhoe_loaders',
  OTHER: 'other'
};

const FILE_FORMAT = {
  APPLICATION_PDF: 'application/pdf'
};

const FILE = 'file';

const INBOX = {
  NOTIFICATIONS: 'notifications',
  MESSAGES: 'messages'
};

const TOKEN_TYPE = {
  WEB: 'web'
};

const PERMISSION = {
  GRANTED: 'granted'
};

const PAYMENT_STATUS = {
  UPDATED: 'updated',
  SKIPPED: 'skipped',
  PENDING: 'pending'
};

const MAP_STYLES = [
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  }
];

const FILTER_WIDTH = 360;
const SHIPMENT_LIST_WIDTH = 360;
const PIN_DETAILS_WIDTH = 400

const PAYMENT_METHOD_TYPES = {
  BANK: 'bank_account',
  CARD: 'card'
};

const ERRORS = {
  NO_PERMISSION: 'You do not have permission to perform this action.'
};

const MESSAGES = {
  UNDER_VERIFICATION: `Your account verification is under progress!`
};

const VERIFICATION_DOC_TYPES = {
  COMPANY_PROFILE: 'company_profile',
  USDOT: 'usdot',
  W9: 'w9',
  TRANSPORT_TYPE: 'insurance_certificate'
};

const DOCUMENT_VERIFICATION_STEPS = [
  VERIFICATION_DOC_TYPES.COMPANY_PROFILE,
  VERIFICATION_DOC_TYPES.TRANSPORT_TYPE,
  VERIFICATION_DOC_TYPES.USDOT,
  VERIFICATION_DOC_TYPES.W9
];

const S3_BUCKET_DIR = {
  CARRIER_MEDIA: 'carrier-media',
  PROFILE_PIC: 'profile-pic',
  SHIPMENT_MEDIA: 'shipment-media',
  BUGS:'bugs'
};

const VERIFIACTION_STATUS = {
  APPROVED: 'approved',
  PENDING: 'pending',
  REJECTED: 'rejected'
};

export {
  FILTER_WIDTH,
  SHIPMENT_LIST_WIDTH,
  PERMISSION,
  RED,
  GREEN,
  PENDING_EMAIL,
  VERIFY_EMAIL,
  COMPANY_DETAILS,
  RIDER_INFO,
  UPLOAD_DOCUMENTS,
  PAYMENT_INFO,
  PERSONAL_DETAILS,
  VEHICLE,
  DRIVER,
  TRUCKS,
  ROADCALL_VERSION,
  ROADCALL_USER_TOKEN,
  PICKUP,
  DROP,
  DUAL_VIEW,
  LIST_VIEW,
  MAP_VIEW,
  SHIPMENT_DETAIL,
  MAKE_BID,
  BID_SUCCESS,
  BOOKING_VIEW,
  MESSAGES_LIMIT,
  BOOKING_SUCCESS,
  ROADCALL_FCM_TOKEN,
  ROADCALL_ID,
  PREFIX_SHIPMENT,
  SOMETHING_WENT_WRONG,
  ERROR_TEXT_REQUIRED,
  INITIAL_ZOOM,
  LOAD_STEP,
  LOAD_STATUS,
  ERROR,
  SUCCESS,
  ASSIGN_DRIVER,
  CATEGORY,
  VEHICLE_SUBCATEGORY,
  HOME_N_OFFICE_SUBCATEGORY,
  CONSTRUCTION_SUBCATEGORY,
  FILE_FORMAT,
  FILE,
  INBOX,
  USERS,
  TOKEN_TYPE,
  ERROR_TEXT_ZIP,
  PREFIX_TRUCK,
  MAP_STYLES,
  ERROR_LOCATION_ACCESS,
  ROADCALL_SAVE_SEARCHES,
  RIDER_INFO_SKIPPED,
  PREFIX_CARRIER,
  PREFIX_SHIPPER,
  PAYMENT_STATUS,
  PREFIX_BROKER,
  PREFIX_TRUCKER,
  ROADCALL_PICKUP_CONTACT_DETAILS,
  ROADCALL_DROP_CONTACT_DETAILS,
  EXISTING_USER,
  TRUCK_STATUS,
  ROADCALL_PICKUP_RECENT_SEARCHES,
  ROADCALL_DROP_RECENT_SEARCHES,
  ROADCALL_MY_CURRENT_LOCATION,
  PAYMENT_METHOD_TYPES,
  ERRORS,
  MESSAGES,
  PREFIX_TRANSACTION,
  VERIFICATION_DOC_TYPES,
  DOCUMENT_VERIFICATION_STEPS,
  ROADCALL_USER_DOC_VER_DIALOGUE_CLOSE,
  S3_BUCKET_DIR,
  VERIFIACTION_STATUS,
  VEHICLE_SUBCATEGORIES,
  PREFIX_INVOICE,
  PLATFORM,
  APP_TOKEN,
  LOADLIST_VISIBILITY,
  PIN_DETAILS_WIDTH
};
