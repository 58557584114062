import { ReactComponent as SearchIcon } from '../../assets/images/common/search.svg';
import { ReactComponent as ProfileIcon } from '../../assets/images/common/profile.svg';
import { ReactComponent as InboxIcon } from '../../assets/images/common/inbox.svg';
import { ReactComponent as LoadsIcon } from '../../assets/images/common/loads.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/common/settings.svg';
import { ReactComponent as PinnedTrucksIcon } from '../../assets/images/common/pinned-trucks.svg';
import { ReactComponent as SearchIconSelected } from '../../assets/images/common/search-selected.svg';
import { ReactComponent as ProfileIconSelected } from '../../assets/images/common/profile-selected.svg';
import { ReactComponent as InboxIconSelected } from '../../assets/images/common/inbox-selected.svg';
import { ReactComponent as LoadsIconSelected } from '../../assets/images/common/loads-selected.svg';
import { ReactComponent as SettingsIconSelected } from '../../assets/images/common/settings-selected.svg';
import { ReactComponent as SavedLoads } from '../../assets/images/common/saved-loads.svg';
import { ReactComponent as OfferLoads } from '../../assets/images/settings/terms.svg';
import { ReactComponent as OfferLoadSelected } from '../../assets/images/settings/terms-active-carrier.svg';
import { ReactComponent as SavedLoadsSelected } from '../../assets/images/common/saved-loads-selected.svg';
import { ReactComponent as PinnedTrucksSelectedIcon } from '../../assets/images/common/pinned-trucks-selected.svg';
import { ReactComponent as DriversIcon } from 'assets/images/settings/driver.svg';
import { ReactComponent as DriversActiveIcon } from 'assets/images/common/driver_selected.svg';
import { ReactComponent as DashboardIcon } from 'assets/images/common/DashboardIcon.svg';
import { ReactComponent as DashboardActiveIcon } from 'assets/images/common/DashboardIconSelected.svg';
import { ReactComponent as InvoiceIcon } from 'assets/images/common/Invoice_icon.svg';
import { ReactComponent as InvoiceIconSelected } from 'assets/images/common/invoice_icon_selected.svg';

const iconsMap: any = {
  contactIcon: ProfileIcon,
  inboxIcon: InboxIcon,
  searchIcon: SearchIcon,
  loadsIcon: LoadsIcon,
  settingsIcon: SettingsIcon,
  contactSelectedIcon: ProfileIconSelected,
  inboxSelectedIcon: InboxIconSelected,
  searchSelectedIcon: SearchIconSelected,
  loadsSelectedIcon: LoadsIconSelected,
  settingsSelectedIcon: SettingsIconSelected,
  savedLoadsIcon: SavedLoads,
  offerLoadsIcon: OfferLoads,
  offerLoadsSelectedIcon: OfferLoadSelected,
  savedLoadsSelectedIcon: SavedLoadsSelected,
  pinnedtrucksIcon: PinnedTrucksIcon,
  pinnedtrucksSelectedIcon: PinnedTrucksSelectedIcon,
  DriversIcon: DriversIcon,
  DriversActiveIcon: DriversActiveIcon,
  dashboardIcon: DashboardIcon,
  dashboradActiveIcon: DashboardActiveIcon,
  InvoiceIcon: InvoiceIcon,
  InvoiceIconSelected: InvoiceIconSelected
};

export default [
  {
    tag:'',
    label: '',
    content: JSON.parse(
      `[
  {
    "label": "Dashboard",
    "icon": "dashboardIcon",
    "iconSelected": "dashboradActiveIcon",
    "to": "/Dashboard"
  },
  {
    "label": "Inbox",
    "icon": "inboxIcon",
    "iconSelected": "inboxSelectedIcon",
    "to": "/inbox"
  },
  {   
    "label": "Search",
    "icon": "searchIcon",
    "iconSelected": "searchSelectedIcon",
    "to": "/search"
  },
  {
    "label": "Saved loads",
    "icon": "savedLoadsIcon",
    "iconSelected": "savedLoadsSelectedIcon",
    "to": "/saved-loads"
  },
  {
    "label": "My offers",
    "icon": "offerLoadsIcon",
    "iconSelected": "offerLoadsSelectedIcon",
    "to": "/my-offers"
  },
  {
    "label": "My Loads",
    "icon": "loadsIcon",
    "iconSelected": "loadsSelectedIcon",
    "to": "/loads"
  },
  {
    "label": "Invoices",
    "icon": "InvoiceIcon",
    "iconSelected": "InvoiceIconSelected",
    "to": "/invoices"
  },
  {
    "label": "Contacts",
    "icon": "contactIcon",
    "iconSelected": "contactSelectedIcon",
    "to": "/contactbook"
  },
  {
    "label": "More",
    "icon": "settingsIcon",
    "iconSelected": "settingsSelectedIcon",
    "to": "/settings"
  }
]`,
      (key, value) => {
        if (key === 'icon' || key === 'iconSelected') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];
