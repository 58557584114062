import React from 'react';
import ReactDom from 'react-dom';
import projectLogo from 'assets/images/logos/rc_logomark_192px.png';

const Loading = (props: any) => {
  const position: any = document.getElementById('loader');

  return ReactDom.createPortal(
    <div
      style={{
        position: 'absolute',
        zIndex: 1101,
        width: '100%',
        background: '#FAFAFA'
      }}
      className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
      <div className="d-flex align-items-center flex-column px-4">
        <img className="app-sidebar-logo loader" alt="Logo" src={projectLogo} />
      </div>
      {/* <div className="text-muted font-size-xl text-center pt-3">
        Loading please wait...
      </div> */}
    </div>,
    position
  );
};

export default Loading;
