const formatUsNumber = (phoneNumberString: string) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

const formatUsCurrency = (currency: Number, showDecimal: Boolean = true) => {
  let amount = currency;

  if (!amount) amount = 0;

  let params = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  if (showDecimal === false) {
    params.minimumFractionDigits = 0;
    params.maximumFractionDigits = 0;
  }

  return amount.toLocaleString('en-US', params);
};

const formatUsNumbers = (currency: Number, showDecimal: Boolean = true) => {
  let amount = currency;

  if (!amount) amount = 0;

  let params = {
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  if (showDecimal === false) {
    params.minimumFractionDigits = 0;
    params.maximumFractionDigits = 0;
  }

  return amount.toLocaleString('en-US', params);
};

const formatUsNumber2 = (phoneNumberString: string) => {
  if (!phoneNumberString) return '';
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '', match[2], '-', match[3], '-', match[4]].join('');
  }
  return null;
};

const formatPrice = (x: number | undefined | null) => {
  if (x !== 0 && x !== undefined && x !== null) {
    return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

const formatToDollar = (x: number) => {
  return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const maskPhoneNumberInput = (phone: string) => {
  phone = phone.replace(/-|\s/g, '').replace(/[^0-9]/g, '');
  if (phone.length > 10) phone = phone.slice(0, 10);
  let formattedPhone = '';
  for (var i = 0; i < phone.length; i++) {
    if (i === 0) {
      formattedPhone += '(';
    } else if (i === 3) {
      formattedPhone += ') ';
    } else if (i === 6) {
      formattedPhone += '-';
    }
    formattedPhone += phone.charAt(i);
  }
   return formattedPhone;
};

const maskYearInput = (year: any) => {
  year = year.replace(/[^0-9]/g, '');
    if (year.length > 4) year = year.slice(0, 4);
  return year;
};

const sanitizePhoneNumber = (phone: string) => {
  if (!phone) return '';
  return phone.replace(/\s+/g, '').replace(/[-()]/g, '');
};

const sanitizePrice = (price:string) => {
  if (!price) return '';
  // Allow dots (.) for decimal input, remove other non-numeric characters
  return price.replace(/[^\d.]/g, '');
};

const maskZipCodeInput = (zipCode:string) => {
  let sanitizedZipCode = zipCode.replace(/\D/g, ''); 
  if (sanitizedZipCode.length > 5) sanitizedZipCode = sanitizedZipCode.slice(0, 5);
  return sanitizedZipCode;
};

const sanitizeZipCode = (zipCode:string) => {
  if (!zipCode) return '';
  return zipCode.replace(/\D/g, ''); 
};

const calculateDollarPerMile = (dollar: number, distance: number) => {
  return (dollar / distance).toFixed(2);
};

const bytesToSize = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0KB';

  const k = 1024;

  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + '' + sizes[i];
};

const  calculateuserPercentage = (user:number,totalUser:number)=> {
  const userPercentage = (user / totalUser) * 100;
  return userPercentage.toFixed(2);
}
function bytesToSizes(bytes:number) {
  if (bytes === 0) return '0 B';
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) return `${bytes} ${sizes[i]}`;
  const result = (bytes / Math.pow(1024, i)).toFixed(2);
  return `${result} ${sizes[i]}`;
}

export {
  formatUsNumber,
  formatUsNumber2,
  formatToDollar,
  maskPhoneNumberInput,
  maskYearInput,
  sanitizePhoneNumber,
  calculateDollarPerMile,
  bytesToSize,
  formatUsCurrency,
  formatUsNumbers,
  calculateuserPercentage,
  bytesToSizes,
  maskZipCodeInput,
  sanitizeZipCode,
  formatPrice,
  sanitizePrice
};
