import { ReactComponent as SearchIcon } from 'assets/images/common/search.svg';
import { ReactComponent as ProfileIcon } from 'assets/images/common/profile.svg';
import { ReactComponent as InboxIcon } from 'assets/images/common/inbox.svg';
import { ReactComponent as LoadsIcon } from 'assets/images/common/loads.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/common/settings.svg';

import { ReactComponent as SearchIconSelected } from 'assets/images/common/search-selected2.svg';
import { ReactComponent as ProfileIconSelected } from 'assets/images/common/profile-selected2.svg';
import { ReactComponent as InboxIconSelected } from 'assets/images/common/inbox-selected2.svg';
import { ReactComponent as LoadsIconSelected } from 'assets/images/common/loads-selected2.svg';
import { ReactComponent as SettingsIconSelected } from 'assets/images/common/settings-selected2.svg';
import { ReactComponent as DashboardIcon } from 'assets/images/common/DashboardIcon.svg';
import { ReactComponent as DashboardActiveIcon } from 'assets/images/common/DashboardIconSelectedShipper.svg';

const iconsMap: any = {
  profileIcon: ProfileIcon,
  inboxIcon: InboxIcon,
  searchIcon: SearchIcon,
  loadsIcon: LoadsIcon,
  settingsIcon: SettingsIcon,
  profileSelectedIcon: ProfileIconSelected,
  inboxSelectedIcon: InboxIconSelected,
  searchSelectedIcon: SearchIconSelected,
  loadsSelectedIcon: LoadsIconSelected,
  settingsSelectedIcon: SettingsIconSelected,
  dashboardIcon: DashboardIcon,
  dashboradActiveIcon: DashboardActiveIcon,
};

export default [
  {
    label: '',
    content: JSON.parse(
      `[
  {
    "label": "Dashboard",
    "icon": "dashboardIcon",
    "iconSelected":"dashboradActiveIcon",
    "to": "/Dashboard"
  },
  {
    "label": "Inbox",
    "icon": "inboxIcon",
    "iconSelected": "inboxSelectedIcon",
    "to": "/inbox"
  },
  {
    "label": "Deliveries",
    "icon": "loadsIcon",
    "iconSelected": "loadsSelectedIcon",
    "to": "/loads"
  },
  {
    "label": "More",
    "icon": "settingsIcon",
    "iconSelected": "settingsSelectedIcon",
    "to": "/settings"
  }
]`,
      (key, value) => {
        if (key === 'icon' || key === 'iconSelected') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];
