import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Material UI.
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { exportTransactions, getTransactions } from 'reducers/Payment';
import withDevice from 'components/HOC/withDevice';
import withScroll from 'components/HOC/withScroll';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TransactionsList from './TransactionsList';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DownloadSvg from 'assets/components/Svg/DownloadSvg';
import SpinLoader from 'components/loader/SpinLoader';

const Transactions = ({
  transactions,
  getTransactions,
  isMobile,
  transactionsLoading,
  nextLoading,
  exportTransactions,
  exportTransactionsLoading
}) => {
  const classes = useStyles();

  const [filterDays] = useState([
    {
      value: 30,
      label: 'Last 30 Days'
    },
    {
      value: 60,
      label: 'Last 60 Days'
    },
    {
      value: 90,
      label: 'Last 90 Days'
    },
    {
      value: 180,
      label: 'Last 6 Months'
    }
  ]);

  const [days, setDays] = useState(30);
  const [ordering, setOrdering] = useState('-created_at');

  useEffect(() => {
    if (isMobile === false)
      getTransactions({
        days,
        ordering
      });
  }, [days, ordering]);

  if (isMobile === true) return null;

  return (
    <Box className={classes.transactionHistorySection}>
      <Box
        style={{
          marginBottom: 16,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}>
        <Button
          disabled={
            exportTransactionsLoading === true ||
            transactionsLoading === true ||
            nextLoading === true ||
            transactions === null ||
            transactions.results.length === 0
          }
          onClick={() => exportTransactions({ days })}
          variant="contained"
          disableRipple
          disableElevation
          style={{
            backgroundColor: '#eff0f4',

            paddingTop: 7,
            paddingBottom: 7,
            paddingLeft: 16,
            paddingRight: 22,
            marginRight: 16
          }}>
          {exportTransactionsLoading ? (
            <SpinLoader size={14} />
          ) : (
            <DownloadSvg />
          )}
          <Typography
            display="inline"
            style={{ marginLeft: 10, color: '#abacc3', fontWeight: 600 }}>
            Export
          </Typography>
        </Button>

        <Select
          disabled={nextLoading || transactionsLoading ? true : false}
          value={days}
          onChange={e => setDays(e.target.value)}
          variant="standard"
          className={classes.filterTransactionSelect}
          IconComponent={() => (
            <KeyboardArrowDownIcon className={classes.arrow} />
          )}>
          {filterDays.map(val => (
            <MenuItem key={val.value} value={val.value}>
              <Typography style={{ fontWeight: 600, color: '#abacc3' }}>
                {val.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>

      <TransactionsList
        days={days}
        filterDays={filterDays}
        ordering={ordering}
        setOrdering={setOrdering}
      />
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  filterTransactionSelect: {
    '& > div.Mui-disabled': {
      background: '#e2e2e2'
    },
    '&:before': {
      borderBottom: 'none !important'
    },
    '&:after': {
      borderBottom: 'none !important'
    },
    '& > div': {
      '&:focus': {
        borderRadius: 11
      },
      fontSize: 14,
      color: '#c1c1c1',
      border: '2px solid #e2e2e2',
      borderRadius: 11,
      fontWeight: 700,
      minWidth: 110,
      width: 110,
      padding: 6,
      paddingLeft: 20
    }
  },
  transactionHistorySection: {
    marginTop: 4
  },
  arrow: {
    color: '#c7c7c7',
    pointerEvents: 'none',
    position: 'absolute',
    right: 16
  }
}));

const mapStateToProps = state => ({
  transactions: state.Payment.transactions,
  transactionsLoading: state.Payment.transactionsLoading,
  nextLoading: state.Payment.transactionsNextLoading,
  exportTransactionsLoading: state.Payment.exportTransactionsLoading
});

const mapDispatchToProps = dispatch => {
  return {
    getTransactions: data => dispatch(getTransactions(data)),
    exportTransactions: data => dispatch(exportTransactions(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDevice(withScroll(Transactions)));
