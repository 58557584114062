import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses
} from '@mui/material/CircularProgress';
import { Typography } from '@material-ui/core';

const ProgressBar = ({ verificationStepsPercent }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#d5d5d6'
        }}
        size={120}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        value={verificationStepsPercent()}
        size={120}
        sx={{
          color: '#EE3C2D',
          position: 'absolute',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        thickness={4}
      />
      <Box style={{ position: 'absolute', top: '36%', textAlign: 'center' }}>
        <Typography
          variant="h4"
          style={{
            color: '#6B6B6B',
            fontWeight: 700
          }}>
          {verificationStepsPercent()}%
        </Typography>
        <Typography style={{ color: '#6B6B6B', fontSize: 10, fontWeight: 600 }}>
          Complete
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressBar;
