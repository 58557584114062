import React, { useEffect, useLayoutEffect, useRef } from 'react';
import Loading from 'components/loader';
import { connect } from 'react-redux';
import { validatePayoutUrl } from 'reducers/User';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { ERROR } from 'utils/constants';
import PayoutAction from 'containers/Settings/TabInfo/Payout/PayoutAction';

const PayoutAuth = ({
  validatePayoutUrl,
  validatePayoutlLoading,
  user,
  error,
  setSnackbar
}) => {
  const {token } = useParams();

  useEffect(() => {
    validatePayoutUrl(token);
  }, []);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (error) {
      setSnackbar({
        type: ERROR,
        message: error
      });
    }
  }, [error]);

  if (validatePayoutlLoading) {
    return <Loading />;
  }
  else if (!user) {
    return null
  };

  return (
    <Box>
      <PayoutAction/>
    </Box>
  );
};

const mapStateToProps = state => ({
  user: state.User.user_data,
  validatePayoutUrlLoading: state.User.validatePayoutUrlLoading,
  error: state.User.user_error
});

const mapDispatchToProps = dispatch => {
  return {
    validatePayoutUrl: (token) => dispatch(validatePayoutUrl(token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutAuth);
