import { maskPhoneNumberInput, maskZipCodeInput, sanitizePhoneNumber, sanitizeZipCode } from 'utils/numberUtils'

const validateEmail = (email:string) => {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const validatePhoneNumber = (phone:string) => {
  return sanitizePhoneNumber(maskPhoneNumberInput(phone)).length === 10
}

const validateZipCode = (zipcode:string) => {
  return sanitizeZipCode(maskZipCodeInput(zipcode)).length === 5
}

const validatePassword = (password:string) => {
  if (password.match(/[a-z]/g) && 
      password.match(/[A-Z]/g) && 
      password.match(/[0-9]/g) && 
      password.match(/[^a-zA-Z\d]/g) && 
      password.length >= 8) 
    return true 
  else 
    return false 
}

const validatePasswordMessage = (password:string) => {

  if(password.length < 8) return 'Password length must be greater or equal to 8'
  if(password.length > 30) return 'Password too long'
  if(!password.match(/[a-z]/g)) return 'Password must have at least 1 lowercase letter'
  if(!password.match(/[A-Z]/g)) return 'Password must have at least 1 uppercase letter'
  if(!password.match(/[0-9]/g)) return 'Password must have at least 1 digit'
  if(!password.match(/[^a-zA-Z\d]/g)) return 'Password must have at least 1 special character'
  
  return true
}

const validateForm = (errors: any, exclude: any = []) => {
  let err = {...errors}
  if(exclude.length){
    exclude.map( (del: any) => {
      delete err[del]
    })
  }
  let valid = true;
  Object.values(err).forEach((val:any) => val.length > 0 && (valid = false));
  return valid;
};

export { validateEmail, validatePhoneNumber, validatePassword, validatePasswordMessage, validateForm , validateZipCode}