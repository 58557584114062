
import { matchPath } from 'react-router';
import { getUserTypeService } from '../services/login';
import { CARRIER, SHIPPER } from './users';
const LOGIN_PAGE = '/Signin';
const SIGNUP_PAGE = '/Signup';
const VERIFY_PAGE = '/verify';
const CARRIER_ASSIGNED_SHIPMENTS = '/Shipments/Assigned/';
const CARRIER_REQUESTED_SHIPMENTS = '/Shipments/Requested/';
const ADMIN_SHIPPER_VERIFICATION = '/Verification/Shipper';
const ADMIN_CARRIER_VERIFICATION = '/Verification/Carrier';
const DASHBOARD_PAGE = '/Dashboard';
const SEARCH_PAGE = '/search';
const POST_JOB_PAGE = '/post-job';
const JOB_COPY_PAGE = '/loads/copy/';
const JOB_EDIT_PAGE = '/loads/edit/';
const TRUCKS_PAGE = '/trucks';
const TRUCKS_ADD_PAGE = '/trucks/add';
const TRUCKS_EDIT_PAGE = '/trucks/edit/:id';
const SHIPPER_OPEN_SHIPMENTS = '/Shipments/All/';
const SHIPMENTS = '/Shipments';
const TRUCKERS = '/Truckers';
const ONBOARDING_PAGE = '/Onboarding';
const INBOX_PAGE = '/inbox';
const NOT_FOUND = '/404';
const SHIPMENTS_ALL = '/Shipments/All/';
const LOADS = '/loads';
const ADMINLOADS ='/loads/adminloads';
const FORGET_PASS_PAGE = '/forget-password';
const PRIVACY_POLICY_PAGE = '/privacy-policy';
const TERMS_OF_SERVICE_PAGE = '/terms-service';
const CONTACT_US_PAGE = '/contact-us';
const BOL_PAGE = '/bol/:load_id';
const SETTINGS_PAGE = '/settings';

const CARRIERS_PAGE = '/carriers';
const SHIPPERS_PAGE = '/shippers';
const BROKERS_PAGE = '/brokers';
const USER_DETAIL_PAGE = '/user/:type/:id';
const USERS ='/UserManagement';
const SAVED_LOADS_PAGE = '/saved-loads';
const My_OFFERS_PAGE = '/my-offers';
const PAYMENT_METHOD_PAGE = '/payment-methods/:customer_id';
const PAYOUT_AUTH = '/payout_url/:token';
// Apps urls.
const APP_RETURN_PAGE = '/apps/return';
const APP_REFRESH_PAGE = '/apps/refresh';
const DRIVERS_PAGE ='/drivers';
const CONTACT_BOOK ='/contactbook';
const INVOICES ='/invoices';
const SEND_INVOICE = '/invoices/send-invoice';
const DELETE_ACCOUNT ='/DeleteAccount';
const PAYOUT='/Payout';
const PAYOUT_ONBOARDING ='/Payout-onboarding'
const capitalizeFirstLetter = (string:any) => {
  const trimmedString = string.trim();
  return trimmedString.charAt(0).toUpperCase() + trimmedString.slice(1);
};

const routeToLable = (route:any) => {
  if (matchPath(route, { path: TRUCKS_EDIT_PAGE })?.isExact === true)
    return 'Edit Truck';
  const userType = getUserTypeService();
  switch (route) {
    case POST_JOB_PAGE:
      return userType === CARRIER ? 'Add a load' : 'Post a load';
    case SEARCH_PAGE:
      return '';
    case PAYOUT_ONBOARDING:
      return '';
    case TRUCKS_PAGE:
      return userType === CARRIER ? 'Pinned Trucks' : '';
    case TRUCKS_ADD_PAGE:
      return 'Pin a Truck';
    case LOADS:
      return userType === SHIPPER ? 'My Deliveries' : 'My Loads';
    case SAVED_LOADS_PAGE:
      return 'Saved Loads';
    case My_OFFERS_PAGE:
      return 'My Offers';
    case SETTINGS_PAGE:
      return 'More';
    case INVOICES:
    return '' ;
    case CONTACT_BOOK:
        return '' ;  
      case SEND_INVOICE:
        return 'Send Invoice' ;
      default:
      return capitalizeFirstLetter(route.replace(/\/|-/g, '> ').substring(1)); // Replace / and - with space.
  }
};

export {
  PAYMENT_METHOD_PAGE,
  LOGIN_PAGE,
  SIGNUP_PAGE,
  VERIFY_PAGE,
  DASHBOARD_PAGE,
  SEARCH_PAGE,
  ONBOARDING_PAGE,
  SHIPMENTS,
  TRUCKERS,
  CARRIER_ASSIGNED_SHIPMENTS,
  CARRIER_REQUESTED_SHIPMENTS,
  ADMIN_SHIPPER_VERIFICATION,
  ADMIN_CARRIER_VERIFICATION,
  SHIPPER_OPEN_SHIPMENTS,
  NOT_FOUND,
  POST_JOB_PAGE,
  TRUCKS_PAGE,
  INBOX_PAGE,
  SHIPMENTS_ALL,
  LOADS,
  ADMINLOADS,
  TRUCKS_ADD_PAGE,
  FORGET_PASS_PAGE,
  PRIVACY_POLICY_PAGE,
  SETTINGS_PAGE,
  TRUCKS_EDIT_PAGE,
  JOB_COPY_PAGE,
  JOB_EDIT_PAGE,
  CARRIERS_PAGE,
  SHIPPERS_PAGE,
  USER_DETAIL_PAGE,
  APP_RETURN_PAGE,
  APP_REFRESH_PAGE,
  BROKERS_PAGE,
  SAVED_LOADS_PAGE,
  My_OFFERS_PAGE,
  CONTACT_US_PAGE,
  BOL_PAGE,
  TERMS_OF_SERVICE_PAGE,
  USERS,
  DRIVERS_PAGE,
  CONTACT_BOOK,
  INVOICES,
  SEND_INVOICE,
  DELETE_ACCOUNT,
  PAYOUT,
  PAYOUT_ONBOARDING,
  PAYOUT_AUTH,
  routeToLable
};
