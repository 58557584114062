import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from '@mui/material';
import Warningicon from 'assets/images/common/Snakebarwarningicon.png'
import {
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';
import ButtonWithBackground from 'components/Form/Button';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
  ConnectPayouts,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { connect } from 'react-redux';
import {
  connectAccount,
  submitDetails,
  redirectStripeDashboard,
  getSessionLink
} from 'reducers/StripePayout';
import StripeListLoading from 'components/Skeleton/StripecomponentListLoading';
import { APP_TOKEN } from 'utils/constants';
import { getUser } from 'reducers/User';
import { getUserIdService } from 'services/login';
import PageLoader from 'components/loader';

const PayoutAction = ({
  responseUrl,
  user_data,
  setuppayoutaccountloading,
  submitDetailsLoading,
  connectAccount,
  submitDetails,
  redirectStripeDashboard,
  getSessionLink,
  sessionId,
  getUser,
  user,
}) => {
  const classes = useStyles();
  const [status, setStatus] = useState(null);
  const [showStripeComponent, setShowStripeComponent] = useState(false);
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);
  const [redirectingUrl, setRedirectingUrl] = useState(false);
  const [loading, setLoading] = useState(true);

  // get connect account status
  useEffect(() => {
    if (user_data && user_data.connect_account_status)
      setStatus(user_data.connect_account_status);
  }, [user,user_data]);

  // get client secret from backend for status active and information required 
  useEffect(() => {
    if (status == 'ACTIVE' || status == 'INFORMATION_REQUIRED') {
      getSessionLink();
      if (sessionId) {
        setLoading(true);
      }
    }
  }, [status]);

  // get userData if user is redirected from mobile
useEffect(() => {
    getUser('carrier',getUserIdService())
}, []);

  useEffect(() => {
    if (sessionId) {
      handleInstance()
    }
  }, [sessionId]);

  // calll stripe embedded component
  const handleInstance = async () => {
    const stripeInstance = loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_PUBLISH_KEY,
      clientSecret: `${sessionId}`,
      appearance: {
        colorPrimary: '#EF3B2D',
        fontStyle: 'poppins',
      }
    });
    setStripeConnectInstance(stripeInstance);
    setShowStripeComponent(true);
    setLoading(false);
  };

  // redirect to url if any url is recieved from backend
  useEffect(() => {
    if (responseUrl !== null) {
      setRedirectingUrl(true);
      window.location.href = responseUrl;
    }
  }, [responseUrl]);

  // button actions for status no connect account and  details not submitted
  let buttonLabel, disabled, clickHandler;

  switch (status) {
    case 'NO_CONNECT_ACCOUNT':
      buttonLabel = 'Set up payout';
      disabled = setuppayoutaccountloading || redirectingUrl;
      clickHandler = () => connectAccount();
      break;
    case 'DETAILS_NOT_SUBMITTED':
      buttonLabel = 'Finish set up';
      disabled = submitDetailsLoading || redirectingUrl;
      clickHandler = () => submitDetails();
      break;
  }

  if (!status) {
    return <PageLoader />
  };
  
  return (
    <Box className={clsx('container', classes.container)}>
      {/* for status no connect account and details not submitted show message */}
      {(status === 'NO_CONNECT_ACCOUNT' || status === 'DETAILS_NOT_SUBMITTED') && (
        <>
          <Typography className={classes.text}>
            Let's get you paid!
          </Typography>
          <Typography className={classes.detail}>
            Roadcall partners with Stripe to ensure you get paid on time and keep your personal and bank details secure.
          </Typography>
          <ButtonWithBackground
            label={buttonLabel}
            disabled={disabled}
            clickHandler={clickHandler}
            className={classes.button}
          />
        </>
      )}

      {/* show meaasage for redirecting to stripe dashboard */}
      {status !== 'NO_CONNECT_ACCOUNT' && status !== 'DETAILS_NOT_SUBMITTED' && (
        <Box className={classes.message}>
          <Typography>
            Visit your
            <span className={classes.link}
              onClick={() => redirectStripeDashboard()}>
              {''} Stripe dashboard
            </span> to update payment and profile information.
          </Typography>
        </Box>
      )}

      {/* show banner for status information requires */}
      {status === 'INFORMATION_REQUIRED' && (
        <Box className={classes.message}
          style={{
            backgroundColor: '#FFD9D5',
            display: 'block',
            padding: '12px 0',
            margin: '12px -20px'
          }}>

          <Box className={classes.banner}>
            <img src={Warningicon} alt="" />
            <Typography className={classes.bannertext}>
              INFORMATION REQUIRED: To avoid an interruption in payouts, an update is required. Please visit the
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#ED3D2E',
                  fontWeight: 800,
                  cursor: 'pointer'
                }}
                onClick={() => redirectStripeDashboard()}
              >
                {' '}Stripe dashboard{' '}
              </Link>
              to update your payment information.
            </Typography>
          </Box>
        </Box>
      )}

      {/* show embedded UI for payout */}
      <Box className={classes.stripecontainer}>
        {(status === 'ACTIVE' || status === 'INFORMATION_REQUIRED') && loading ? (
          <StripeListLoading />
        ) : (
          showStripeComponent && stripeConnectInstance && (
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectPayouts />
            </ConnectComponentsProvider>
          )
        )}
      </Box>
    </Box>
  );
}
const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100',
  },
  text: {
    color: '#00000099',
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 18
  },
  detail: {
    textAlign: 'center',
    maxWidth: 375,
    color: '#00000099',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 18,
    justifyContent: 'center'
  },
  button: {
    backgroundColor: '#EF3B2D',
    borderRadius: 6,
    padding: '6px 50px',
    marginBottom: 40,
    marginTop: 40
  },
  link: {
    cursor: 'pointer',
    color: '#0000FF'
  },
  message: {
    padding: 20,
    marginTop: -20,
    maxWidth: 900,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  banner: {
    paddingLeft: 12,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    maxmarginLeft: 'auto',
  },
  bannertext: {
    paddingLeft: 20,
    paddingRight: '4%',
    color: '#ED3D2E',
    justifyContent: 'center',
    fontWeight: 600
  },
  stripecontainer: {
    margin: 16,
    paddingRight:8,
    maxWidth: 900,
    width: '100%'
  }
}))

const mapStateToProps = (state) => {
  return {
    responseUrl: state.StripePayout.responseUrl,
    user_data: state.User.user,
    setuppayoutaccountloading: state.StripePayout.setuppayoutaccountloading,
    isPayoutAccount: state.StripePayout.isPayoutAccount,
    submitDetailsLoading: state.StripePayout.submitDetailsLoading,
    detailsSubmitted: state.StripePayout.detailsSubmitted,
    stripedashboardRedirectloading: state.StripePayout.stripedashboardRedirectloading,
    directedStripeDashboard: state.StripePayout.isPayoutAccount,
    sessionIdLoading: state.StripePayout.sessionIdLoading,
    sessionId: state.StripePayout.sessionId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    connectAccount: () => dispatch(connectAccount()),
    submitDetails: () => dispatch(submitDetails()),
    redirectStripeDashboard: () => dispatch(redirectStripeDashboard()),
    getSessionLink: () => dispatch(getSessionLink()),
    getUser: (type,id) => dispatch(getUser(type,id))

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutAction);