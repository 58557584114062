import axiosClient from 'utils/axiosClient';
import { errorMessageSingleLine } from 'utils/errorUtils';

//declarations
const CANCEL_SUBSCRIPTION_LOADING = 'CANCEL_SUBSCRIPTION_LOADING'
const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
const UPGRADE_SUBCRIPTION_LOADING = 'UPGRADE_SUBSCRIPTION_LOADING'
const UPGRADE_SUBSCRIPTION = 'UPGRADE_SUBSCRIPTION'
const GET_SUBSCRIPTION_DEAILS_LOADING = 'GET_SUBSCRIPTION_DETAILS_LOADING'
const GET_SUBSCRIPTION_DEAILS = 'GET_SUBSCRIPTION_DETAILS'
const CREATE_CUSTOMER_PORTAL_LOADING = 'CREATE_CUSTOMER_PORTAL_LOADING'
const CREATE_CUSTOMER_PORTAL = 'CREATE_CUSTOMER_PORTAL'
const STRIPE_TABLE ='STRIPE_TABLE'
const SET_ERROR = 'SET_ERROR'

const initialState = {
    cancel_subscription_loading: false,
    cancel_premium_Subscription: false,
    upgrade_premium_subscription_loading:false,
    upgrade_premium_subscription_:false,
    subscription_status:null,
    subscription_details: null,
    subscription_details_loading: false,
    currentPlan: null,
    createCustomerPortalLoading: false,
    customerPortalCreated: false,
    scheduled_for_cancelation: null,
    error: '',
}

//Get subscription details for user
export const getSubscriptionDetails = () => {
    return async dispatch => {
        let url = `/payment/get/billable/`;
        dispatch({
            type: GET_SUBSCRIPTION_DEAILS_LOADING
        });

        try {
            const response = await axiosClient.get(url)
            dispatch({
                type: GET_SUBSCRIPTION_DEAILS,
                data: response.data
            });

        } catch (error) {
        }
    }
}

//Cancel subscription for user
export const cancelSubscription = () => {
    return async dispatch => {
        let url = `/payment/cancel/subscription/`;
        dispatch({
            type: CANCEL_SUBSCRIPTION_LOADING
        });
        try {
            const response = await axiosClient.post(url)
            dispatch({
                type: CANCEL_SUBSCRIPTION,
                data: response.data
            })
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                data: errorMessageSingleLine(error?.response?.data?.detail)
            });
        }
    }
}
// set StripeTable
export const setStripeTable = () =>{
    return async dispatch => {
        dispatch({
          type: STRIPE_TABLE
      });
 };
}

//Upgrade subscription to premium
export const upgradeSubscription = () => {
    return async dispatch => {
        let url = `/payment/premium/subscription/`;
        dispatch({
            type: UPGRADE_SUBCRIPTION_LOADING
        });
        try {
            const response = await axiosClient.post(url)
            dispatch({
                type: UPGRADE_SUBSCRIPTION,
                data: response.data
            })
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                data: errorMessageSingleLine(error?.response?.data?.detail)
            });
        }
    }
}
// create customer portal
export const createCustomerPortal = () => {
    return async (dispatch) => {
        let url = `/payment/create/portal/`;
        dispatch({
            type: CREATE_CUSTOMER_PORTAL_LOADING,
        });
        try {
            const currentDomain = window.location.origin;

            const path = "/settings?tab=accounts_and_billing";
          
            const returnUrl = currentDomain + path;
          
            const response = await axiosClient.post(url, {
              return_url: returnUrl,
            });
            const portalUrl = response.data.url;
            window.location.href = portalUrl;

            dispatch({
                type: CREATE_CUSTOMER_PORTAL,
                data: response.data,
            });
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                data: errorMessageSingleLine(error?.response?.data?.detail),
            });
        }
    };
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
            case CANCEL_SUBSCRIPTION_LOADING:
                return {
                    ...state,
                    cancel_subscription_loading: true,
                    cancel_premium_Subscription: false,

                };
        case CANCEL_SUBSCRIPTION:
            return {
                ...state,
                cancel_subscription_loading: false,
                cancel_premium_Subscription: true,
                scheduled_for_cancelation:true
            };
            case UPGRADE_SUBCRIPTION_LOADING:
                return {
                    ...state,
                 upgrade_premium_subscription_loading: true,
                 upgrade_premium_subscription : false                };
        case UPGRADE_SUBSCRIPTION:
            const newCurrentPlan = action.data.status === 'USE_STRIPE_TABLE' ? state.currentPlan : 'Premium Plan';
            return {
                ...state,
                upgrade_premium_subscription_loading: false,
                upgrade_premium_subscription: true,
                subscription_status: action.data.status,
                scheduled_for_cancelation: false,
                currentPlan: newCurrentPlan,
            };
        case GET_SUBSCRIPTION_DEAILS_LOADING:
            return {
                ...state,
                subscription_details_loading: true
            };
        case GET_SUBSCRIPTION_DEAILS:
            return {
                ...state,
                subscription_details_loading: false,
                subscription_details: action.data,
                checkFreePlan: true,
                scheduled_for_cancelation: action.data.is_scheduled_for_cancelation,
                currentPlan: action.data.product_name,
                cancel_premium_Subscription: false,
                upgrade_premium_subscription: false
            };
        case CREATE_CUSTOMER_PORTAL_LOADING:
            return {
                ...state,
                createCustomerPortalLoading: true
            };
        case CREATE_CUSTOMER_PORTAL:
            return {
                ...state,
                customerPortalCreated: true

            };
            case STRIPE_TABLE:
            return {
                ...state,
                upgrade_premium_subscription: false
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.data,
                upgrade_premium_subscription_loading: false,
                cancel_subscription_loading: false,
                subscription_details_loading: false,
                createCustomerPortalLoading: false
            };
        default:
            return state;
    }
}