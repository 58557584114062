import { ERROR_TEXT_REQUIRED, SOMETHING_WENT_WRONG } from './constants';
import { convertToLabel } from './textUtils';

const errorMessageSingleLine = (error: any = '') => {
  if (error) {
    if (typeof error === 'object') {
      const errorMessage = error.detail || error.message;
      if (errorMessage) return removeCommaFromEnd(errorMessage);

      const key = Object.keys(error)[0];
      const value = Object.values(error)[0];

      if (Array.isArray(value) && typeof value[0] === 'object') {
        const er = value[0];

        return `${convertToLabel(Object.keys(er)[0]) +
          ': ' +
          Object.values(er)[0]} in ${convertToLabel(key)}`;
      }

      return convertToLabel(key) + ': ' + value;
    } else return removeCommaFromEnd(error);
  }

  return SOMETHING_WENT_WRONG;
};

const breakSingleLineMessage = (error: any = '', breakpoint: string = ': ') => {
  if (error) {
    const errorArray = error.split(breakpoint);

    return errorArray[errorArray.length - 1].split('.')[0];
  }
  return SOMETHING_WENT_WRONG;
};

const removeCommaFromEnd = (str: string) => {
  if (str.slice(-1) === ',') return str.substring(0, str.length - 1);
  return str;
};

const errorTypeToLabel = (type: string, message: string = '') => {
  if (message) return message;
  switch (type) {
    case 'required':
      return ERROR_TEXT_REQUIRED;
    default:
      return 'Invalid input';
  }
};

export {
  errorMessageSingleLine,
  errorTypeToLabel,
  removeCommaFromEnd,
  breakSingleLineMessage
};
