import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { ReactComponent as AccountUnderReviewIcon } from 'assets/images/common/account-under-review.svg';
import { Typography } from '@material-ui/core';
import ButtonWithBackground from 'components/Form/Button';
import { UPLOAD_DOCUMENTS } from 'utils/constants';
import { updateUserService } from 'services/users';
import { getUserIdService, getUserTypeService } from 'services/login';

const AccountUnderReviewView = ({ setAccountUnderReviewViewVisible }) => {
  const [completedocumentsSubmit, setCompletedocumentsSubmit] = useState(false);

  const handleComplete = async (c) => {
    setAccountUnderReviewViewVisible(false)
    setCompletedocumentsSubmit(true);
    const obj = {
      onboarding_step: UPLOAD_DOCUMENTS
    };
    try {
      await updateUserService(
        getUserIdService(),
        getUserTypeService(false),
        obj
      );
    } catch (e) {}
    setCompletedocumentsSubmit(false);
  };

  return (
    <Box style={{ textAlign: 'center', marginTop: 24 }}>
      <AccountUnderReviewIcon />
      <Typography style={{ color: '#ED3D2E', fontWeight: 700, marginTop: 16 }}>
        Thank you!
      </Typography>
      <Typography style={{ color: '#ED3D2E', fontWeight: 700 }}>
        Your account is under review.
      </Typography>
      <Typography
        style={{
          color: '#BEBEBE',
          marginTop: 16,
          fontWeight: 600,
          fontSize: 12,
          paddingLeft: '10%',
          paddingRight: '10%'
        }}>
        Once we have approved your documents, you will be able to book loads and
        hit the road! You will be notified once your account has been verified.
      </Typography>
      <ButtonWithBackground
        clickHandler={() => handleComplete()}
        label="Got it"
        style={{
          width: 200,
          marginTop: 24,
          borderRadius: 9,
          fontSize: 12,
          fontWeight: 600,
          color: '#fff'
        }}
      />
    </Box>
  );
};

export default AccountUnderReviewView;
