import React from 'react';

// Material UI.
import { CATEGORY } from 'utils/constants';
import Vehicle from './Vehicle';
import FreightInfo from './FreightInfo';
import HomeOffice from './HomeOffice';
import Construction from './Construction';

const ItemInfo = ({
  category,
  subCategory,
  shipment_items,
  shipment_damages,
  setSelectedDamageItem
}) => {
  if (category === CATEGORY.VEHICLE) {
    return (
      <Vehicle
        shipment_items={shipment_items}
        subCategory={subCategory}
        shipment_damages={shipment_damages}
        setSelectedDamageItem={setSelectedDamageItem}
      />
    );
  }

  if (category === CATEGORY.FREIGHT) {
    return (
      <FreightInfo
        shipment_items={shipment_items}
        subCategory={subCategory}
        shipment_damages={shipment_damages}
        setSelectedDamageItem={setSelectedDamageItem}
      />
    );
  }

  if (category === CATEGORY.HOME_N_OFFICE) {
    return (
      <HomeOffice
        shipment_items={shipment_items}
        subCategory={subCategory}
        shipment_damages={shipment_damages}
        setSelectedDamageItem={setSelectedDamageItem}
      />
    );
  }

  if (category === CATEGORY.CONSTRUCTION) {
    return (
      <Construction
        shipment_items={shipment_items}
        subCategory={subCategory}
        shipment_damages={shipment_damages}
        setSelectedDamageItem={setSelectedDamageItem}
      />
    );
  }
  return null;
};

export default ItemInfo;
