import axiosClient from 'utils/axiosClient';
import axios from 'axios';
import { EXISTING_USER, VERIFICATION_DOC_TYPES } from 'utils/constants';

const version = process.env.REACT_APP_API_VER;
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getUnverifiedShipperService = async (url: any = null) => {
  try {
    const response = await axiosClient.get(
      url ||
        'shipper/' +
          version +
          '/?limit=1000&offset=0&ordering=-created_at&verification_status=pending&onboarding_step=verification_status'
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const getUnverifiedCarrierService = async (url: any = null) => {
  try {
    const response = await axiosClient.get(
      url ||
        'carrier/' +
          version +
          '/?limit=10&offset=0&ordering=-created_at&verification_status=pending&onboarding_step=verification_status'
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const getUnverifiedShipperDetailsService = async (id: string) => {
  try {
    const response = await axiosClient.get(`shipper/${version}/${id}/`);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const getUnverifiedCarrierDetailsService = async (id: string) => {
  try {
    const response = await axiosClient.get(`carrier/${version}/${id}/`);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const getUsersCountService = async (user_type: string) => {
  try {
    const response = await axiosClient.get(`${user_type}/count/${version}/`);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const updateUnverifiedShipperService = async (
  id: string,
  verification_status: string,
  onboarding_step: string
) => {
  try {
    const response = await axiosClient.patch('shipper/' + version + '/' + id, {
      verification_status
      //onboarding_step
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const updateUnverifiedCarrierService = async (
  id: string,
  verification_status: string,
  onboarding_step: string
) => {
  try {
    const response = await axiosClient.patch('carrier/' + version + '/' + id, {
      verification_status
      //onboarding_step
    });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const sendOtpService = async (phone: string) => {
  const url = `sms/otp/${version}/`;
  try {
    const response = await axiosClient.post(url, { phone });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const verifyOtpService = async (phone: string, otp: string) => {
  const url = `sms/validate/${version}/${phone}/`;

  var options: any = {
    method: 'DELETE',
    url,
    data: { otp }
  };

  try {
    const response = await axiosClient.request(options);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const sendOtpEmailService = async (email: string) => {
  const url = `/email/otp/${version}/`;

  try {
    const response = await axiosClient.post(url, { email });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const sendOtpForgetPassService = async (email: string) => {
  const url = `${baseUrl}auth/otp/${version}/`;

  try {
    const response = await axios.post(url, { email });
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const verifyOtpEmailService = async (data: any) => {
  const url = `/email/validate/${version}/`;

  var options: any = {
    method: 'PUT',
    url,
    data
  };

  try {
    const response = await axiosClient.request(options);
    localStorage.removeItem(EXISTING_USER);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const verifyOtpForgetPassService = async (email: string, otp: string) => {
  const url = `${baseUrl}auth/validate/${version}/`;

  const data = {
    validate_type: 'OTP',
    email,
    otp
  };

  var options: any = {
    method: 'PUT',
    url,
    data
  };

  try {
    const response = await axios.request(options);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const confirmPasswordService = async (
  email: string,
  otp: string,
  password: string
) => {
  const url = `${baseUrl}auth/validate/${version}/`;

  const data = {
    validate_type: 'password',
    email,
    otp,
    password
  };

  var options: any = {
    method: 'PUT',
    url,
    data
  };

  try {
    const response = await axios.request(options);
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

const isVerificationDocUploaded = (user: any, doc_type: string) =>
  user.carrier_verification_docs.find(
    (doc: any) => doc.document_type === doc_type && doc.s3_bucket_url.length
  );

const verificationDocUploadedPercent = (user: any) => {
  let value = 25;
  if (isVerificationDocUploaded(user, VERIFICATION_DOC_TYPES.TRANSPORT_TYPE))
    value += 25;

  if (isVerificationDocUploaded(user, VERIFICATION_DOC_TYPES.USDOT))
    value += 25;

  if (isVerificationDocUploaded(user, VERIFICATION_DOC_TYPES.W9)) value += 25;

  return value;
};

const getVerificationUploadedDoc = (user: any, doc_type: string) =>
  user.carrier_verification_docs.find(
    (doc: any) => doc.document_type === doc_type
  )?.s3_bucket_url;

export {
  sendOtpService,
  getUsersCountService,
  verifyOtpService,
  sendOtpEmailService,
  verifyOtpEmailService,
  confirmPasswordService,
  sendOtpForgetPassService,
  verifyOtpForgetPassService,
  getUnverifiedShipperService,
  getUnverifiedCarrierService,
  updateUnverifiedShipperService,
  updateUnverifiedCarrierService,
  getUnverifiedCarrierDetailsService,
  getUnverifiedShipperDetailsService,
  isVerificationDocUploaded,
  verificationDocUploadedPercent,
  getVerificationUploadedDoc
};
