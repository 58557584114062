import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { ReactComponent as ProfileIcon } from '../../assets/images/common/profile.svg';
import { ReactComponent as ProfileIconSelected } from '../../assets/images/common/profile-selected.svg';

const iconsMap: any = {
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  AssignmentTurnedInIcon: AssignmentTurnedInIcon,
  LocalShippingIcon: LocalShippingIcon,
  profileSelectedIcon: ProfileIconSelected,
  profileIcon: ProfileIcon,
};

export default [
  {
    label: '',
    content: JSON.parse(
      `[
  {
    "label": "Dashboard",
    "to": "/Dashboard"
  },
  {
    "label": "User Management",
    "to": "/UserManagement"
  }
]`,
      (key, value) => {
        if (key === 'icon' || key === 'iconSelected') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];
