import React, { useEffect, useState } from 'react';

// Material UI.
import { Box, Grid } from '@material-ui/core';

// Components.
import clsx from 'clsx';
import Progress from './Progress';
import { connect } from 'react-redux';
import UploadVerificationDocs from './UploadVerificationDocs';
import MenuWrapper from 'containers/Settings/MenuWrapper';
import {
  getVerificationUploadedDoc,
  isVerificationDocUploaded,
  verificationDocUploadedPercent
} from 'services/Verification';
import { setDocumentVerificationDialogueVisibility } from 'reducers/User';
import { VERIFIACTION_STATUS } from 'utils/constants';

const Document = ({
  user,
  setDocumentVerificationDialogueVisibility,
  setSnackbar
}) => {
  const [
    accountUnderReviewViewVisible,
    setAccountUnderReviewViewVisible
  ] = useState(false);

  const isDocUploaded = doc_type => isVerificationDocUploaded(user, doc_type);

  const getUploadedDoc = doc_type => getVerificationUploadedDoc(user, doc_type);

  const verificationStepsPercent = () => verificationDocUploadedPercent(user);

  useEffect(() => {
    if (verificationStepsPercent() === 100) {
      setDocumentVerificationDialogueVisibility(false);
      if (user.verification_status !== VERIFIACTION_STATUS.APPROVED)
        setAccountUnderReviewViewVisible(true);
    }
  }, [user.carrier_verification_docs]);

  return (
    <MenuWrapper>
      <Grid
        item
        lg={9}
        md={9}
        sm={12}
        xs={12}
        style={{ padding: 24, backgroundColor: '#f6f8fa' }}
        className={clsx({
          'account-verificaion-view-visible': accountUnderReviewViewVisible
        })}>
        <Grid container spacing={4}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box
              style={{
                backgroundColor: '#fff',
                padding: 20,
                borderRadius: 8
              }}>
              <UploadVerificationDocs
                isDocUploaded={isDocUploaded}
                getUploadedDoc={getUploadedDoc}
                setSnackbar={setSnackbar}
                accountUnderReviewViewVisible={accountUnderReviewViewVisible}
                setAccountUnderReviewViewVisible={
                  setAccountUnderReviewViewVisible
                }
                verificationStepsPercent={verificationStepsPercent}
              />
            </Box>
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box
              style={{
                backgroundColor: '#fff',
                padding: 20,
                borderRadius: 8
              }}>
              <Progress
                isDocUploaded={isDocUploaded}
                verificationStepsPercent={verificationStepsPercent}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </MenuWrapper>
  );
};

const mapStateToProps = state => {
  return {
    user: state.User.user_data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDocumentVerificationDialogueVisibility: bool =>
      dispatch(setDocumentVerificationDialogueVisibility(bool))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);
