import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import {
  DOCUMENT_VERIFICATION_STEPS,
  VERIFICATION_DOC_TYPES
} from 'utils/constants';
import { uploadVerificationDocuments } from 'reducers/User';
import { connect } from 'react-redux';
import UploadDoc from './UploadDoc';
import AccountUnderReviewView from './AccountUnderReviewView';
import TabPanel from './TabPanel';
import DocumentTab from './DocumentTab';

const UploadedVerificationDocs = ({
  isDocUploaded,
  getUploadedDoc,
  setSnackbar,
  accountUnderReviewViewVisible,
  setAccountUnderReviewViewVisible,
  verificationStepsPercent
}) => {
  const [tabValue, setTabValue] = useState(
    DOCUMENT_VERIFICATION_STEPS.indexOf(VERIFICATION_DOC_TYPES.TRANSPORT_TYPE)
  );

  const tabPanelData = () => {
    if (accountUnderReviewViewVisible)
      return (
        <AccountUnderReviewView
          setAccountUnderReviewViewVisible={setAccountUnderReviewViewVisible}
        />
      );
    return (
      <UploadDoc
        currentVerificationTab={tabValue}
        getUploadedDoc={getUploadedDoc}
        setTabValue={setTabValue}
        isDocUploaded={isDocUploaded}
        setSnackbar={setSnackbar}
        setAccountUnderReviewViewVisible={setAccountUnderReviewViewVisible}
        verificationStepsPercent={verificationStepsPercent}
      />
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={(e, tabValue) => setTabValue(tabValue)}
          className={'verification-docs-tabs'}>
          {DOCUMENT_VERIFICATION_STEPS.map((step, index) => (
            <DocumentTab
              key={index}
              complete={
                step === VERIFICATION_DOC_TYPES.COMPANY_PROFILE ||
                accountUnderReviewViewVisible
              }
              label={`${++index}. ${step}`}
              uppercase={step === VERIFICATION_DOC_TYPES.USDOT}
              tabType={step}
              isDocUploaded={isDocUploaded}
              currentTabValue={tabValue}
            />
          ))}
        </Tabs>
      </Box>

      {DOCUMENT_VERIFICATION_STEPS.map((step, index) => (
        <TabPanel value={tabValue} index={index} key={index}>
          {tabPanelData()}
        </TabPanel>
      ))}
    </Box>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    uploadVerificationDocuments: data =>
      dispatch(uploadVerificationDocuments(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadedVerificationDocs);
