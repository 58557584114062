import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

const TermsOfServiceContent = ({ tncRefs }) => {
  const section = (title, description = '', unBold = false) => {
    return (
      <Box
        style={{ marginTop: 16 }}
        ref={ref => {
          if (tncRefs && tncRefs.current) tncRefs.current[title] = ref;
        }}>
        <Typography
          style={{ fontWeight: unBold ? 'initial' : 600, fontSize: 12 }}
          display="inline">
          {title}
        </Typography>{' '}
        <Typography display="inline" style={{ fontSize: 12 }}>
          {description}
        </Typography>
      </Box>
    );
  };

  return (
    <Box style={{ padding: 12, paddingTop: 0 }}>
      {/* {section(`PLEASE READ AND INDICATE YOUR AGREEMENT TO THE FOLLOWING TERMS OF
          SERVICE.`)}
      {section(
        '',
        `You agree to and understand the following in its entirety:`
      )}
      {section(
        '',
        `The Terms of Service is an agreement between you and Roadcall.co (the
          “User Agreement”), the Roadcall.co Privacy Policy (the “Privacy
          Policy”), and all policies posted on our Roadcall.co domain,
          sub-domains, other Roadcall.co sites, the Roadcall.co mobile
          application(s), any available Roadcall.co application programming
          interfaces (“APIs”), and all other related services,websites,
          applications, and software platform in Roadcall.co’s online shipping
          marketplace, describe the terms of service which Roadcall.co offers
          You, a registered user, (also referred to as “You”, “your”, “User”, or
          “Users”) may access and use our shipping marketplace services
          (collectively, “Services”). When You accept the terms of service of
          this Agreement, You hereby agree and acknowledge that at any time and
          in our sole discretion, we may modify the terms of this User
          Agreement. If we make changes to the of this User Agreement, we will
          notify you of such changes by posting the revised User Agreement on
          the Roadcall.co software platform and by updating the “Revised Date”
          at the top of this User Agreement. All modified terms shall take
          effect immediately after posting to the Roadcall.co software platform,
          or upon the stated date included within our notice. Your continued use
          of the Roadcall.co Services following the effective date of such
          changes will constitute your acceptance and consent to any and all
          modified terms. This User Agreement may not be modified, amended,
          and/or changed by You in any manner. Furthermore, You agree that we
          may modify this User Agreement or discontinue our Services at any time
          and without notice and without any liability or responsibility to You.`
      )}
      {section(
        '',
        `The legal entity You are entering into this User Agreement with is
          Roadcall, Inc., a New York Corporation located in 144 Route 59, Room
          #5B, Suffern, New York 10901.`
      )}
      {section(`THIS USER AGREEMENT CONTAINS AN ARBITRATION CLAUSE AND CLASS ACTION
          WAIVER. PLEASE SEE SECTION [TBD] OF THIS USER AGREEMENT REGARDING
          RESTRICTIONS ON YOUR LEGAL RIGHTS IN ANY DISPUTE INVOLVING OUR
          SERVICES, USER AGREEMENT, OR PRIVACY POLICY. THE ARBITRATION CLAUSE
          AND CLASS ACTION WAIVER GOVERNS HOW CLAIMS AGAINST ROADCALL, INC. CAN
          BE BROUGHT BY YOU AS A USER. BY AGREEING TO THE TERMS OF THIS USER
          AGREEMENT, YOU ACKNOWLEDGE AND AGREE TO SUBMIT ALL CLAIMS YOU MAY HAVE
          AGAINST ROADCALL, INC. THROUGH FINAL AND BINDING ARBITRATION AND YOU
          WAIVE ANY RIGHT TO PARTICIPATE IN CLASS ACTIONS, CLASS ARBITRATIONS,
          OR REPRESENTATIVE ACTIONS.`)} */}

      {section(
        `License and Acceptable Use.`,
        `Roadcall.co grants to You a non-exclusive, non-transferable limited
            license to make use of the Roadcall.co software platform to connect
            Shippers, Carriers and Brokers under the Terms of Service
            (“Roadcall.co Services”). This license does not include any resale
            of Roadcall.co Services. The Roadcall.co Services contain
            copyrighted material, inventions, know-how, potential patentable
            business method material, design logos, phrases, names, logos or
            applications, and code ("Intellectual Property Content") all of
            which, unless otherwise indicated and/or provided pursuant to a
            third-party license, are our sole property and we retain all
            appurtenant rights, interests, and title. We also claim ownership
            rights under the copyright and trademark laws with regard to the
            "look", "feel", "appearance", and "graphic function" of our
            Roadcall.co Services including, but not limited to, its color
            combinations, sounds, layouts, and designs. You agree and
            acknowledge that your use of the Roadcall.co Services does not
            confer upon You any license or permission to use our (or any third
            party's) Intellectual Property Content. You shall not reproduce,
            reverse engineer, modify, display, sell, or distribute the
            Intellectual Property Content, or use it in any other way for public
            or commercial purpose. All other trademarks, service marks, and
            copyrights are held by their rightful owners. You may not collect
            and/or use any shipment listings, descriptions, or prices for any
            reason. You may not engage in: any derivative use of any Roadcall.co
            Service or its contents; any downloading or copying of account
            information; or any use of data mining, robots, or similar data
            gathering and extraction tools. All rights not expressly granted to
            You in this User Agreement are reserved and retained by Roadcall.co.
            No Roadcall.co Service, nor any part of any Roadcall.co open
            software platform, may be reproduced, duplicated, copied, sold,
            resold, visited, or otherwise exploited for any commercial purpose
            without express written consent of Roadcall.co. You may not misuse
            the Roadcall, Inc. You may use the Roadcall.co Services only as
            permitted by this User Agreement.`
      )}

      <Box
        style={{ marginTop: 16 }}
        ref={ref => {
          if (tncRefs && tncRefs.current) tncRefs.current['Definitions.'] = ref;
        }}>
        <Typography style={{ fontWeight: 600, fontSize: 12 }}>
          Definitions.
        </Typography>

        <Box style={{ marginTop: 10 }}>
          <Typography
            style={{ fontWeight: 600, marginLeft: 30, fontSize: 12 }}
            display="inline">
            Shipper.
          </Typography>{' '}
          <Typography display="inline" style={{ fontSize: 12 }}>
            A person or company that arranges to have freight shipped on their
            own behalf by a Carrier (“Shipper”).
          </Typography>
        </Box>
        <Box style={{ marginTop: 10 }}>
          <Typography
            style={{ fontWeight: 600, marginLeft: 30, fontSize: 12 }}
            display="inline">
            Carrier.
          </Typography>{' '}
          <Typography display="inline" style={{ fontSize: 12 }}>
            A licensed company that provides transportation and related services
            to a Shipper.
          </Typography>
        </Box>
        <Box style={{ marginTop: 10 }}>
          <Typography
            style={{ fontWeight: 600, marginLeft: 30, fontSize: 12 }}
            display="inline">
            Broker.
          </Typography>{' '}
          <Typography display="inline" style={{ fontSize: 12 }}>
            A licensed company who, for compensation, arranges, or offers to
            arrange, the transportation of freight by a Carrier.
          </Typography>
        </Box>
      </Box>

      {section(
        `Open Software Platform.`,
        ` Roadcall.co is not a Shipper, Carrier or Broker. Our software platform acts as a venue where Shippers, 
          Carriers and Brokers can interact and enter into agreements. Roadcall.co is not a party to any shipping agreement made 
          between Roadcall.co Users via the Roadcall.co open software platform. As a result, we have no control over the quality, safety, or legal aspects of the transactions that take place on our software platform. However, as part of our service to the User, Roadcall.co does prequalify Carriers and Brokers. The prequalification is limited to requiring the Carrier to provide satisfactory information regarding their business entity and insurance coverage, Roadcall.co does not endorse, recommend, or refer any specific Carrier or Shipper, nor does Roadcall.co have any control or influence over actions or decisions made by Users of the Service. All Users of the Roadcall.co software platform make their own decisions and You acknowledge and agree that we are not in any way arranging transportation or shipping or logistics services on your behalf. Because we are not involved in the transaction between Shippers and Carriers, we have no control over the accuracy of listings, the ability of Carriers to transport items, or the ability of Shippers to send items. We cannot ensure the Shipper or Carrier will actually complete a shipment. We cannot and do not guarantee the ability of users to complete or fulfill any services booked through the
Roadcall.co software platform. You acknowledge and agree that any and all communications, 
correspondence, verbal or written or by electronic means, or any warranties or representations 
made with regard to the arrangement of transportation services are not provided by us and are specifically and solely between 
You and the other User.`
      )}

      {section(
        `Access to Site and Services.`,
        `You agree that You can form legally binding contracts under applicable law. You acknowledge that You are at least 18 years of age and you have reached the age of majority in your state. If You are not yet 18 years old or have not reached the age of
          majority in your state, You may not use our Services. Your Roadcall.co account may not be transferred or sold to another party. If You are registering as a business entity, you represent that You have the authority to bind the business entity to this Agreement. If You are a Broker using our Services, You agree that you possess the authority to act as a Broker on behalf of a Shipper or Carrier. Users who engage in the transportation of goods must have a valid license to operate the vehicle they intend to use. You agree that the Roadcall.co Services are to arrange for transportation services only and that the work that You provide will only involve transportation services. You agree that You will not use the Roadcall.co software platform as a means to sell or market any types of goods or products.`
      )}

      {section(
        `Responsibilities of Shippers and Carriers.`,
        `Shippers are responsible for the accuracy of their information in the listing posted on the software platform and the terms of their arrangement to
          have freight shipped by a Carrier. Carriers must legally be authorized to transport the freight that You contract with the Shipper through the Roadcall.co software platform. You are responsible for reading all of the shipment details and by booking shipments on Roadcall.co, you are entering into a legally binding agreement to complete the services as requested by the Shipper.`
      )}

      {section(
        `Insurance Coverage for Carriers.`,
        `Carriers are required to maintain satisfactory insurance coverage while a registered User of the Roadcall.co Service. Upon receipt of a notice of cancellation, Roadall.co will suspend the license of a Carrier to perform transportation services on the Roadcall.co software platform until such time that Roadcall.co receives written notice of reinstatement. Notwithstanding the foregoing, it is the responsibility of the Shipper to ensure that that Carrier is insured at the time and for the purpose of the transaction.`
      )}

      {section(
        `Payment.`,
        `The Shipper acknowledges that the amount paid by the Shipper is credited to the Carrier’s Roadcall.co account. The Shipper is required to provide a debit card for full payment to Roadcall.co at the time of booking with a Carrier on the Roadcall.co software platform. The Shipper acknowledges and agrees that Roadcall.co shall include a fee to the posted price in the listing. All payments shall be made through the Roadcall.co software platform.`
      )}

      {section(
        `a) Payment Processing Services.`,
        `Payment processing services for Shippers and Carriers on the Roadcall.co software platform are provided by Stripe and are subject to the Stripe Connected Account Agreement, which includes theStripe Terms of Service(collectively, the “Stripe Services Agreement”). By agreeing to this User Agreement, You agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time. As a condition of Roadcall.co enabling payment processing services through Stripe, You agree to provide
          Roadcall.co with accurate and complete information about You and Your business, and you authorize Roadcall.co to share it and transaction information related to Your use of the payment processing services provided by Stripe.`,
        true
      )}

      {section(
        `b) Roadcall.co Transaction Fee.`,
        `The amount of the Roadcall.co transaction fee is deducted from the payment made through the Roadcall.co Service. You authorize Roadcall.co to deduct the transaction fee from the payment. The Roadcall.co transaction fee is non-refundable.`,
        true
      )}

      {section(
        `c) Release of Payment by Shipper.`,
        `The Shipper agrees that Roadcall.co shall release the payment to the Carrier within 7-10 calendar days upon delivery of the shipment regardless of damages, delays, or any other service level issue that might have occurred during transit. Roadcall.co will make commercially reasonable efforts to ensure proper disbursement of funds between Shippers and Carriers; however Roadcall.co shall have no liability or be otherwise be indebted to the Carrier if the Shipper fails to satisfy the condition of payment, nor will Roadcall.co be liable to the Shipper for any damages, missing items, or other service level issues that occurred during the shipment. It is the mutual responsibility of both the Shipper and Carrier to resolve all monetary disputes, damage claims, breach of contract claims, etc. which is outside of Roadcall.co Service. In any such circumstances, the Shipper and Carrier acknowledge and agree that Roadcall.co shall not have any obligations or liability to the Shipper or Carrier for disputes relating to their
          agreement.`,
        true
      )}

      {section(
        `d) Modification of Agreement.`,
        `In the event that the Shipper and Carrier modify the terms of their agreement which either increases or decreases the amount of the cost price of their agreement, the amount of the Roadcall.co transaction fee shall be either debited or credited accordingly.
          `,
        true
      )}

      {section(
        `e) Cancellation of Agreement.`,
        `A Shipper may cancel a transaction any time before the pick-up of the freight by the Carrier. Under such circumstances, the Shipper and Carrier acknowledge that Roadcall.co Service shall refund any such payment made by the Shipper and the Carrier shall not be entitled to any payment nor shall Roadcall.co Service be entitled to any transaction fee.`,
        true
      )}
      {section(
        ``,
        `f) If a chargeback of the Shipper’s payment is accepted by Stripe, Roadcall.co reserves the right to charge the Carrier for the actual cost associated with the chargeback. In such circumstances, the Carrier is responsible to include the chargeback in its invoice to the Shipper.`
      )}
      {section(
        '',
        `g) Roadcall.co discourages You against providing anyone with access to your login credentials to your user account. However, if You do give someone your username and password, or if You fail to adequately safeguard such information, You may be responsible for any unauthorized transaction performed on your account. In such circumstances, Roadcall.co shall have no responsibility or liability for the unauthorized transaction.`
      )}
      {section(
        `h) You acknowledge that telephone calls to or from Roadcall.co may be monitored and recorded for the purposes of quality assurance, training, and other purposes. You verify that any contact information provided to Roadcall.co, its agents and Users, including, but not limited to, your
name, business name, mailing address, email address, your residential or business telephone number, and/or your mobile telephone number is and accurate.`,
        ``,
        true
      )}

      {section(
        `Fees.`,
        `You are solely responsible for all transactions conducted through your account and for paying all fees incurred by any users of your account.`
      )}
      {section(
        `Mobile Application Usage.`,
        `When using the Services on a mobile device and/or the Roadcall.co mobile application, you shall:`
      )}
      {section(``, `(i) observe all traffic laws and otherwise drive safely.`)}
      {section(``, `(ii) use your good personal judgment while driving.`)}
      {section(
        ``,
        `(iii) not interact with the app, unless your vehicle is stationary and legally parked.`
      )}
      {section(
        ``,
        `(iv) not use the Services for any illegal, unauthorized, unintended, unsafe, hazardous, or unlawful purposes.`
      )}

      {section(
        `Information You Submit.`,
        `You solely are responsible for any information You provide to us or other Users in the registration, shipping, transportation process, or any other use of our Services. Your personal information and any shipment listings must be true, legal, accurate, and non-fraudulent. You authorize Roadcall.co to use the information You supply to us in connection with our Services and in accordance with this User Agreement. You acknowledge and agree that our Services act as a passive conduit for any and all communication and/or distribution of information and such communication and/or distribution does not constitute a sale of information. We have no control, editorial or otherwise, over any communication, information,
and specifically, over the content of such communication or information. We do not and will not ensure the accuracy or reliability of such communication or information, nor will we act as a monitor over the content of such communication or information. However, we do reserve the absolute right to remove or restrict any communication or information that You may post to the Roadcall.co software platform that is in the sole opinion of Roadcall.co a violation of this User Agreement, illegal, discriminatory, threatening, or lewd. You assume sole legal responsibility for all damages incurred as a result of any of your online communication or distribution of information and You agree to hold harmless and indemnify Roadcall.co for any claims relating to any communication or information that You post to the Roadcall.co software platform.`
      )}
      {section(
        ``,
        `Furthermore, You expressly represent and warrant the following: (1) You are the owner, with all appurtenant rights thereto, of any and all communication, content and/or information that You post on the Roadcall.co software platform, or; (2) You are the legitimate and rightful grantee of a worldwide, royalty free, perpetual, irrevocable, sub-licensable, non-exclusive license to use, distribute, reproduce and distribute such communication, content, and/or information. To only that extent to allow us to use your communication, content, and/or information and not violate your rights in the same, You grant to us a royalty free, transferable, worldwide, perpetual, irrevocable, sub-licensable, non-exclusive license to exercise the copyright, publicity, and database rights that You have in your communication, content, and/or information. You further represent and warrant that any and all of your online communication, content, and/or information will not, in the sole opinion of Roadcall.co:`
      )}
      {section(
        `o`,
        `Violate any international, federal or state law, regulation, rule, or statute;`
      )}
      {section(`o`, `Violate the terms of this User Agreement;`)}
      {section(
        `o`,
        `Infringe upon any third party's intellectual property rights including, but not limited to,
copyright, patent, or trademark rights;`
      )}
      {section(
        `o`,
        `Contain obscene, lewd, or suggestive content and under no circumstances will it contain
pornography;`
      )}
      {section(
        `o`,
        `Be libelous, threatening, discriminatory, harassing, or defamatory. This specifically
includes making legal claims of any sort about Roadcall.co employees, agents, other
users, or any of the Roadcall.co Services;`
      )}
      {section(
        `o`,
        `Knowingly contain any computer hardware or software, viruses, trojan horses, worms, or
any other computer programming that may interfere with the operation of our software platform, operation of any of our systems, and/or create or impose a large burden or load
on our software platform;`
      )}
      {section(
        `o`,
        `Scan or test the vulnerability or security of our Services or the system within which our
software platform operate;`
      )}
      {section(
        `o`,
        `Be used for commercial or public purposes outside of the requirements of this User
Agreement;`
      )}
      {section(
        `o`,
        `Knowingly create liability for Roadcall.co through your use of Roadcall.co’s software
platform;`
      )}
      {section(
        `o`,
        `Frame or link to our software platform without our written permission; or`
      )}
      {section(
        `o`,
        `Knowingly involve the upload, or insertion of, any programming language or code into or
onto our software platform.`
      )}
      {section(
        ``,
        `b) Information Posted Publicly. Unless otherwise indicated, shipping transaction activity on Roadcall.co is intended to be made public, and You are authorizing Roadcall.co to use or repurpose this information within the scope of the Roadcall.co Services and this User Agreement, including in a user identifiable manner. In order to maintain the safety, security and integrity of the Roadcall.co Service, You may not provide contact information to other users prior to booking and never on any public area of the site.`
      )}
      {section(
        ``,
        `c) Information Posted Privately. For any information You provide that is expressly not intended to be made public, You grant Roadcall.co a right to use the information in aggregate or in a non- user identifiable manner pursuant to our Privacy Policy.`
      )}
      {section(
        `Prohibited and Restricted Items.`,
        `You may not list any shipment on our site that violates any applicable law, statute, ordinance, regulation, or includes prohibited items. Prohibited items are defined as hazardous or dangerous goods that may pose a danger to health, safety, or property while being transported (such as explosives, radioactive materials, flammable gases and solids, and toxic substances). Hazardous goods that cannot be shipped through the United States Postal Service or a commercial carrier are not allowed to be transported on the Roadcall.co. Service. The few hazardous materials that lawfully may be transported under certain conditions (if they are properly packaged and labeled) may be listed on Roadcall.co software platform provided that the listing contains a clear notice of the hazardous nature of the material and a description of the planned method of shipping that complies with the law. For details on particular hazardous, restricted, and perishable mail in the United States, see United States Postal Service Publication 52. Full responsibility rests with the Shipper and Carrier to comply with all postal and non- postal laws and regulations that relate to the mailing of hazardous, restricted, and perishable material. Anyone who sends, or causes to be sent, a non-mailable or improperly packaged
hazardous material can be subject to legal penalties.`
      )}
      {section(
        ``,
        `a) (U.S. Only) Household Goods Shipments. Federal Motor Carrier Safety Administration (FMCSA), part of the U.S. Department of Transportation, administers laws and regulations for the protection of Shippers of household goods. Your Carrier is required by the FMCSA to provide You with certain information pertaining to a household goods move. You can determine the FMCSA registration and safety status of your Carrier at www.fmcsa.dot.gov and review the FMCSA regulations for household goods moves as well as other helpful information at ProtectYourMove.gov.`
      )}
      {section(
        ``,
        `b) (U.S. Only) Non-household Goods Shipments.The Federal Motor Carrier Safety Administration, part of the U.S. Department of Transportation, requires that all Carriers handling interstate shipments register with it and provide certain information. You can determine the registration and safety statutes of your Carrier at www.fmcsa.dot.gov. Certain transportation services may involve movements only within one state. A number of states have statutes and regulations pertaining to such intrastate transportation. We suggest You check with your state Department of Transportation if your service is entirely within one state.`
      )}
      {section(
        `Prohibited Activities.`,
        `Roadcall.co may suspend or remove your account if we suspect that
You have engaged in prohibited activities in connection with our Services.`
      )}
      {section(
        `Dispute Resolution.`,
        `Disputes between You and Roadcall.co regarding our Services should be reported to Roadcall.co Support. Roadcall.co shall attempt to resolve any disputes between you and the other party through customer support protocols. Because we are a neutral venue, however, we are not responsible for successfully resolving any disputes, nor are we responsible for any decisions made or actions taken in a reasonable effort to assist in the resolution of a dispute involving You. If You report a dispute to Roadcall.co, we will make reasonable efforts to help both parties communicate; however, all disputes must be resolved directly between You and the other party. Therefore, if we are contacted by a User who claims to have a dispute with You regarding transactions completed on Roadcall.co and they request your contact information (including, but not limited to, any of your provided phone numbers and/or addresses) to settle the dispute, You expressly authorize us to release your provided contact information to the Roadcall.co User and You agree to hold Roadcall.co harmless from any and all liability associated the release of such information.
`
      )}
      {section(
        `No Agency.`,
        `You hereby agree and acknowledge that your execution of this User Agreement, your provision of transportation services and/or your use of the Roadcall.co Services, does not confer or imply any contractor (independent or otherwise), agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship with us and furthermore that no affiliation, association, or connection exists between You and Roadcall, Inc.`
      )}
      {section(
        `Release.`,
        `Should you have a dispute with one or more Roadcall.co users, by use of the Roadcall.co Service, You expressly release Roadcall.co, Inc. from all claims, demands, and
damages (actual and consequential) of any kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes and/or this User Agreement.`
      )}
      {section(
        `Confidentiality/Non-Disclosure.`,
        `As a result of the performance of this User Agreement and whether due to any intentional or negligent act or omission, we may disclose to You or You may otherwise learn of, or discover, our documents, business practices, object code, source code, management styles, day-to-day business operations, capabilities, systems, current and future strategies, marketing information, financial information, software, technologies, processes, procedures, methods and applications, or other aspects of our business (“Our Information”). You hereby agree and acknowledge that any and all of Our Information is confidential and shall be our sole and exclusive intellectual property and proprietary information. You agree to use Our Information only for the specific purposes as allowed by the performance of this User Agreement. Any disclosure of Our Information to a third party specifically including a direct competitor is strictly prohibited. All obligations contained in this Section [TBD] shall survive the termination of this User Agreement. Furthermore, You acknowledge that Our Information is proprietary, confidential, and extremely valuable to us, and that we would be materially damaged by your disclosure of Our Information. You acknowledge and agree that monetary damages provide an insufficient remedy for the breach of this confidentiality obligation, and that we shall
be entitled to injunctive relief in addition to recovery for damages.`
      )}
      {section(
        `Recordkeeping.`,
        ` Roadcall.co reserves the right to keep all records of any and all transactions and communications between You and other users for administration purposes in accordance with all applicable laws and regulations. All records will be kept in accordance with the Roadcall.co Privacy Policy.`
      )}
      {section(
        `Remedies.`,
        `If Roadcall.co determines that your use of our Services is in violation of this User Agreement, you authorize Roadcall.co to take action against your Roadcall.co account which may include, but is not limited to, the immediate removal of your shipment listing(s), offers, bids, and/or profile, notifying our users of your actions, issuing a warning, temporarily suspending your user status, terminating your User status, and/or refusal to provide our Services to You.`
      )}
      {section(
        `Limited Liability and No Warranty.`,
        `You acknowledge that we cannot guarantee the continuous operation of or access to our software platform or Services. You further acknowledge that operation of and access to our software platform or Services may be interfered with as a result of technical issues or numerous factors outside of our control. You agree that Roadcall.co is not responsible for any failures, delays, outages, or otherwise not making the Services available at any time. You agree that You are making use of our software platform or Services at your own risk, and that they are being provided to You on an “AS IS” and “AS AVAILABLE” basis. Accordingly, to the extent permitted by applicable law, we exclude all express or implied warranties, terms and conditions including, but not limited to, implied warranties of merchantability, warranties of title, and fitness for a particular purpose.`
      )}
      {section(
        ``,
        `In addition, to the extent permitted by applicable law, we are not liable, and You agree not to hold Roadcall.co responsible, for any damages or losses resulting in any way from the foregoing in this Section [TBD], or the following:`
      )}
      {section(
        `o`,
        `Viruses or other malicious software obtained by accessing software platform or Services;`
      )}
      {section(
        `o`,
        `Glitches, bugs, errors, or inaccuracies of any kind including information and graphics
obtained from or in our software platform;`
      )}
      {section(
        `o`,
        `The content, actions, or inactions of third parties, including items listed using our
Services;`
      )}
      {section(
        `o`,
        `Your need to modify practices, content, or behavior or your loss of or inability to do business, as a result of changes to this Agreement or our policies;`
      )}
      {section(
        `o`,
        `Changes to the Service availability, including planned or unplanned Service downtime;`
      )}
      {section(`o`, `Changes to any Roadcall.co Services; and`)}
      {section(`o`, `Your removal from the Roadcall.co marketplace.`)}
      {section(
        ``,
        `Some jurisdictions do not allow the disclaimer of warranties or exclusion of damages, so such disclaimers and exclusions may not apply to You. In such jurisdictions, it is the intent of the parties to this User Agreement to make clear that Roadcall, Inc.’s liability is limited and provides its services “AS IS”, and if this User Agreement is modified by any Court of competent jurisdiction, such modification shall be made to preserve the original intention of the parties.`
      )}
      {section(
        `Indemnity.`,
        `You agree to indemnify and hold Roadcall, Inc. harmless from any claim or demand, including the cost of your attorneys' fees, made by any third party due to or arising out of your negligence, breach of this Agreement, misuse of Roadcall.co’s Services, or violation of any law or the rights of a third party.`
      )}
      {section(
        `Legal Compliance.`,
        `You shall comply with all applicable domestic and international laws, statutes, ordinances, and regulations regarding your use of the Roadcall.co Services and your listing, shipping, transporting, and solicitation of offers to ship and transport items.`
      )}
      {section(
        `Arbitration and Waiver of Class Actions.`,
        `PLEASE READ THIS SECTION CAREFULLY BECAUSE IT IS AN AGREEMENT TO ARBITRATE DISPUTES (“ARBITRATION AGREEMENT”) AND IT LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF. YOU UNDERSTAND AND AGREE THAT YOU WOULD HAVE HAD A RIGHT TO LITIGATE DISPUTES THROUGH A COURT AND TO HAVE A JUDGE OR JURY DECIDE YOUR CASE, BUT BY ENTERING INTO THIS AGREEMENT CHOOSE TO HAVE ANY DISPUTE RESOLVED THROUGH BINDING INDIVIDUAL ARBITRATION. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY NOT BE AVAILABLE OR MAY BE MORE LIMITED IN ARBITRATION, INCLUDING YOUR RIGHT TO APPEAL. In consideration for our willingness to provide you with access to our sites, Services, or tools, you and we agree as follows: Any legal claim arising out of or relating to this Agreement or our Services (excluding legal action taken by Roadcall.co to collect our fees or recover damages for, or obtain an injunction relating to, the Roadcall.co operations, intellectual property, or Our Information), shall be settled by binding arbitration in accordance with the commercial arbitration rules of the
American Arbitration Association. The arbitration shall be conducted in Rockland County, New York and judgment on the arbitration award may be entered into any state or federal court having jurisdiction thereof of Rockland County, New York. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS SECTION [TBD] MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE CLIENT OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CLIENT OR USER.YOU HEREBY WAIVE YOUR RIGHT TO COMMENCE OR PARTICIPATE IN ANY SUCH COLLECTIVE OR REPRESENTATIVE PROCEEDING.
`
      )}
      {section(
        `Trademark and Domain Name Protection.`,
        `The Roadcall.co Services contain trademarks, trade names, trade dress, service marks, domain names, and other indicia of ownership (collectively the "Marks") owned or licensed for use by Roadcall, Inc. Unless otherwise agreed to in an Addendum to this Agreement, You agree that no right, property, license, permission, or interest of any kind in or to the Marks is or is intended to be given or transferred to or acquired by You pursuant to the execution, performance or non-performance of this Agreement or any part thereof. You shall in no way contest or deny the validity of, our right of title to or license of use for, the Marks, and You shall not encourage or assist others directly or indirectly to do so, during the lifetime of this Agreement and thereafter. You shall not utilize the Marks in any
manner that would diminish their value or harm their reputation. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of the Roadcall.co Services without express written consent. You may not use any Meta tags or any other "hidden text" utilizing Roadcall.co’s name or trademarks without the express written consent of Roadcall.co. You shall not use or register any domain name that is identical to or similar to any of the Marks. To the greatest extent possible, Roadcall.co’s rights in our Marks and Our Information shall be enforceable and respected nationwide.`
      )}
      {section(
        `Disclaimer of Third-Party Materials and Links.`,
        `OUR SERVICES MAY DISPLAY, INCLUDE, OR MAKE AVAILABLE THIRD-PARTY CONTENT OR PROVIDE LINKS TO THIRD-PARTY WEBSITES OR SERVICES THAT ROADCALL.CO DOES NOT OWN OR CONTROL. ROADCALL.CO DOES NOT ENDORSE OR ASSUME ANY RESPONSIBILITY FOR ANY SUCH THIRD-PARTY WEBSITES, INFORMATION, MATERIALS, PRODUCTS, OR SERVICES. YOU ACKNOWLEDGE AND AGREE THAT IF YOU ACCESS A THIRD-PARTY WEBSITE OR SERVICE FROM OUR SERVICES OR SHARE YOUR DATA OR INFORMATION ON OR THROUGH ANY THIRD-PARTY WEBSITE OR SERVICE, YOU DO SO ENTIRELY AT YOUR OWN RISK, AND THIS USER AGREEMENT AND THE ROADCALL.CO PRIVACY POLICY DO NOT APPLY TO YOUR USE OF SUCH WEBSITES. YOU EXPRESSLY RELEASE ROADCALL.CO FROM ANY AND ALL LIABILITY ARISING FROM YOUR USE OF ANY THIRD-PARTY WEBSITE, SERVICE, OR CONTENT.`
      )}
      {section(
        `Security.`,
        `Roadcall.co uses industry standard practices to safeguard your personal information, including firewalls and Secure Socket Layers (SSL). We utilize several different
security techniques to protect data from unauthorized access, but we cannot guarantee the security of our system. We also do not guarantee uninterrupted or secure access to our system, as the operation of our Services can be interrupted by numerous factors outside of our control.`
      )}
      {section(
        `Governing Law.`,
        `THIS AGREEMENT AND YOUR USE OF THE SERVICES SHALL BE GOVERNED BY THE LAWS OF THE STATE OF NEW YORK WITHOUT REGARD TO PRINCIPLES OF CONFLICT OF LAWS.`
      )}
      {section(
        `Other Terms and Conditions.`,
        `You acknowledge and agree that we have the sole discretion to set forth and post additional terms and conditions for your use of the Roadcall.co Services at any time. You agree that any additional terms and conditions that apply to your use of our Services shall be considered an effective amendment to this Agreement and said terms and conditions shall be incorporated herein. Furthermore, You expressly agree that, if there is any conflict between those additional terms and conditions and the specific terms and conditions set forth herein, the terms and conditions set forth in those additional terms shall govern.`
      )}
      {section(
        `Notices.`,
        `All notices sent to Roadcall.co shall be sent by physical mail to: Roadcall, Inc., 144 Route 59, Room #5B, Suffern, New York 10901 or via email to: legal@Roadcall.co.com. All notices will be sent to You using the contact information you provide, and may be sent via regular mail, certified mail-return receipt requested, overnight e-mail or fax and You agree to
accept such notice in the manner sent under this User Agreement.`
      )}
      {section(
        `Miscellaneous.`,
        `This Agreement may not be modified, supplemented, qualified, or interpreted by any trade usage or prior course of dealing not made a part of the Agreement by its express terms. The invalidity or unenforceability of any provision of this Agreement shall not affect the validity of this Agreement as a whole, and any such provision should be enforced by authorities and reconstructed if need be, to apply to the maximum extent allowable under applicable law. The failure by either party to enforce at any time any of the provisions of this Agreement, to exercise any election or option provided herein, or to require at any time the performance of the other of any of the provisions herein will not in any way be construed as a waiver of such provisions. Reasonable attorneys' fees and costs will be awarded to the prevailing party in the event of litigation or arbitration involving the enforcement or interpretation of this Agreement. The section headings used herein are for convenience only and shall not be given any legal import.`
      )}
    </Box>
  );
};

export default TermsOfServiceContent;
