import React, { useState } from 'react';
import {
  makeStyles,
  createStyles,
  Select,
  MenuItem,
  Checkbox
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import { ListItemText } from '@mui/material';
import { Menu } from '@mui/material';

const SelectDropdown = React.forwardRef(
  (
    {
      value,
      changeHandler,
      name,
      children,
      options,
      multiple,
      flexed,
      ...rest
    },
    ref
  ) => {
    const [isOpen, setOpen] = useState(false);

    const classes = useStyles({ isOpen });

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      classes: { paper: classes.menu },
      disableAutoFocusItem: true,
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 0
        }
      }
    };

    const isSelected = v => value.indexOf(v) > -1;

    const getOptions = () => {
      if (multiple)
        return options.map((option, index) => (
          <MenuItem
            style={{
              paddingTop: 0,
              paddingBottom: 4
            }}
            key={index}
            value={option.value}>
            <Checkbox
              disableRipple
              checked={isSelected(option.value)}
              style={{
                background: 'transparent',
                color: isSelected(option.value) ? '#ee3e2d' : 'auto'
              }}
            />
            <ListItemText
              primary={option.label}
              className={classes.categoryItems}
            />
          </MenuItem>
        ));

      return options.map((option, index) => (
        <MenuItem
          style={{ textTransform: 'capitalize' }}
          key={index}
          value={option.value}>
          {option.label}
        </MenuItem>
      ));
    };

    return (
      <Select
        MenuProps={MenuProps}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        className={clsx(
          'input-box',
          isOpen ? 'dropdown-open' : '',
          flexed ? 'dropdown-flexed' : ''
        )}
        variant="outlined"
        fullWidth
        IconComponent={() =>
          isOpen ? (
            <KeyboardArrowUpIcon className={classes.arrow} />
          ) : (
            <KeyboardArrowDownIcon className={classes.arrow} />
          )
        }
        name={name}
        value={value}
        onChange={changeHandler}
        {...rest}
        style={{ textTransform: 'capitalize' }}
        multiple={multiple}>
        {getOptions()}
      </Select>
    );
  }
);

const useStyles = makeStyles(theme =>
  createStyles({
    dropdown: {},
    arrow: {
      color: '#c7c7c7',
      pointerEvents: 'none',
      position: 'absolute',
      right: 25
    },
    menu: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: '0px 20px 15px #00000033'
    },
    categoryItems: {
      '& > span': {
        color: '#b5b5b6',
        fontSize: 12,
        fontWeight: 600,
        fontFamily: 'poppins'
      }
    }
  })
);

SelectDropdown.defaultProps = {
  multiple: false,
  flexed: false,
  name: ''
};

export default SelectDropdown;
