import React from 'react';
import { connect } from 'react-redux';
import { Box, Typography, Avatar } from '@material-ui/core';
import { getUserTypeService } from 'services/login';
import { CARRIER } from 'utils/users';

import CircularProgress, {
  circularProgressClasses
} from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom';
import { getNameAbbreviation } from 'services/users';

import { ReactComponent as UnverifiedDocumentIcon } from 'assets/images/common/unverified-document.svg';
import { ReactComponent as VerifiedDocumentIcon } from 'assets/images/common/verified-document.svg';
import ButtonWithBackground from 'components/Form/Button';
import { SETTINGS_PAGE } from 'utils/routesUtils';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';
import { verificationDocUploadedPercent } from 'services/Verification';
import { VERIFIACTION_STATUS } from 'utils/constants';

const S3_URL = process.env.REACT_APP_S3_URL;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E4E4E4'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#EE3C2D'
  }
}));

const DocumentVerificationView = ({ user }) => {
  const currentUser = getUserTypeService();
  const history = useHistory();
  if (currentUser !== CARRIER) return null;
  return (
    <Box style={{ position: 'absolute', width: '100%', bottom: 4 }}>
      <Box style={{ display: 'flex', flexDirection: 'column', padding: 12 }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <CircularProgress
              variant="determinate"
              sx={{
                color: '#d5d5d6'
              }}
              size={45}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              value={verificationDocUploadedPercent(user)}
              size={45}
              sx={{
                color: '#EE3C2D',
                position: 'absolute',
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round'
                }
              }}
              thickness={4}
            />
            <Box
              style={{
                position: 'absolute',
                top: 4,
                textAlign: 'center',
                cursor: 'pointer'
              }}
              onClick={() =>
                history.push({
                  pathname: SETTINGS_PAGE,
                  search: `?tab=account`
                })
              }>
              <Avatar
                style={{
                  width: 37,
                  height: 37,
                  fontWeight: 700,
                  fontSize: 12
                }}
                src={user.profile_pic_url ? S3_URL + user.profile_pic_url : ''}>
                {getNameAbbreviation(user.first_name, user.last_name)}
              </Avatar>
            </Box>
          </Box>
          <Box style={{ marginLeft: 12 }}>
            <Typography
              style={{
                color: '#ABACC3',
                fontWeight: 600,
                fontSize: 11,
                textTransform: 'capitalize'
              }}>{`${user.first_name} ${user.last_name}`}</Typography>

            {user.verification_status === VERIFIACTION_STATUS.APPROVED ? (
              <VerifiedDocumentIcon />
            ) : (
              <UnverifiedDocumentIcon />
            )}
          </Box>
        </Box>

        {verificationDocUploadedPercent(user) !== 100 ? (
          <Box>
            <Box style={{ marginTop: 8 }}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 5
                }}>
                <Typography
                  style={{ color: '#ABACC3', fontSize: 10, fontWeight: 600 }}>
                  Profile Completion
                </Typography>
                <Typography
                  style={{ color: '#EE3C2D', fontSize: 10, fontWeight: 600 }}>
                  {verificationDocUploadedPercent(user)}%
                </Typography>
              </Box>
              <BorderLinearProgress
                variant="determinate"
                value={verificationDocUploadedPercent(user)}
              />
            </Box>

            <ButtonWithBackground
              type="border"
              fullWidth
              label="Verify My Account"
              clickHandler={() =>
                history.push({
                  pathname: SETTINGS_PAGE,
                  search: `?tab=documents`
                })
              }
              style={{ fontSize: 10, borderRadius: 4, marginTop: 12 }}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default connect(state => ({
  user: state.User.user_data
}))(DocumentVerificationView);
