import Mixpanel from 'mixpanel-browser';
import { ROADCALL_ID } from './constants';

export const trackEvent = (eventName) => {
  const roadcall_id = localStorage.getItem(ROADCALL_ID);
  const commonProperties = {
    roadcall_id: roadcall_id || "UNKNOWN_USER"
  };
  Mixpanel.track(eventName, commonProperties);
};

export const identifyUser = () => {
  const roadcall_id = localStorage.getItem(ROADCALL_ID);
  if (roadcall_id !== null) {
    // Identify the user in Mixpanel with the roadcall_id
    Mixpanel.identify(roadcall_id);
    Mixpanel.people.set({ $name: roadcall_id });
  }
};

export const resetMixPanel = () => {
  Mixpanel.reset();
}

export const setupEventHandlers = () => {
  let isReloading = false;

  const handleVisibilityChange = () => {
    if (!isReloading) {
      if (document.hidden) {
        // App is sent to background
        trackEvent('App Sent To Background');
      } else {
        // App is brought to foreground
        trackEvent('App Resumed');
        identifyUser();
      }
    }
  };

  const handleAppClosed = () => {
    // App is being closed
    if (!isReloading) {
      trackEvent('App Closed');
    }
  };

  const handleBeforeUnload = () => {
    isReloading = true;
    // Call handleAppClosed when the user navigates away or closes the tab/window
    handleAppClosed();
  };

  const handleUnload = () => {
    if (!isReloading) {
      trackEvent('App Closed');
    }
  };

  const isFirstTime = !localStorage.getItem('visitedBefore');
  
  // If it's the first time, track 'App Opened' event
  if (isFirstTime) {
    trackEvent('App Opened');

    // Set flag indicating that the user has visited the app before
    localStorage.setItem('visitedBefore', true);
  } else {
    // If not the first time, track 'App Reopened' event
    if (document.visibilityState === 'visible') {
      trackEvent('App Reopened');
    }
  }
  
  // Add event listeners for visibility change and page unload
  document.addEventListener('visibilitychange', handleVisibilityChange);
  window.addEventListener('beforeunload', handleBeforeUnload);
  window.addEventListener('unload', handleUnload);

  return () => {
    // Clean up event listeners when component unmounts
    document.removeEventListener('visibilitychange', handleVisibilityChange);
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.removeEventListener('unload', handleUnload);
  };
};
