import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { errorTypeToLabel } from 'utils/errorUtils';

const RcTextfield = React.forwardRef(
  (
    {
      label,
      error,
      className,
      value,
      changeHandler,
      placeholder,
      hidePlaceholder,
      shrink,
      ...rest
    },
    ref
  ) => {
    const placeholderText =
      hidePlaceholder === true ? '' : placeholder || label;
    const errorText =
      typeof error === 'object' && error.type
        ? errorTypeToLabel(error.type, error.message)
        : error;
    return (
      <TextField
        ref={ref}
        InputLabelProps={shrink ? { shrink } : null}
        variant="outlined"
        label={label}
        fullWidth
        placeholder={placeholderText}
        className={clsx('input-box', className)}
        error={Boolean(errorText)}
        helperText={errorText}
        {...rest}
        value={value}
        onChange={e => {
          if (changeHandler) changeHandler(e);
        }}
      />
    );
  }
);

RcTextfield.defaultProps = {
  label: '',
  placeholder: '',
  hidePlaceholder: false,
  shrink: true
};

RcTextfield.propTypes = {
  label: PropTypes.string,
  hidePlaceholder: PropTypes.bool
};

export default RcTextfield;
