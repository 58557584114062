import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import {
  DOCUMENT_VERIFICATION_STEPS,
  VERIFICATION_DOC_TYPES
} from 'utils/constants';
import VerificationStep from './VerificationStep';
import ProgressBar from './ProgressBar';

const Progress = ({ isDocUploaded, verificationStepsPercent }) => {
  return (
    <Box>
      <Typography
        style={{
          color: '#313131',
          fontSize: 12,
          textAlign: 'center',
          fontWeight: 600,
          marginBottom: 12
        }}>
        Verification Steps
      </Typography>

      <ProgressBar
        isDocUploaded={isDocUploaded}
        verificationStepsPercent={verificationStepsPercent}
      />

      <Box style={{ marginTop: 20 }}>
        {DOCUMENT_VERIFICATION_STEPS.map((step, index) => (
          <VerificationStep
            key={index}
            label={`${++index}. ${step}`}
            complete={
              step === VERIFICATION_DOC_TYPES.COMPANY_PROFILE
                ? true
                : isDocUploaded(step)
            }
          />
        ))}
      </Box>
    </Box>
  );
};

export default Progress;
