import React from 'react';
import { Box } from '@material-ui/core';
import SkeletonRow from './Layout/SkeletonRow';

const StripeListLoading = () => {
  return (
    <Box style={{
      width: '80%'
    }}>
      {Array.from({ length: 12 }).map((_, index) => (
        <div key={index}
          style={{
            margin: 8,
          }}>
          <SkeletonRow variant="rect"
            height={12} animation="wave"
            style={{
              opacity: 0.3,
              marginBottom: 30,
              marginLeft: 20
            }}
          />
        </div>
      ))}
    </Box>
  );
};

export default StripeListLoading;
