import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  TextField,
  Grid,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  makeStyles,
  CardMedia
} from '@material-ui/core';
import MainContainer from '../MainContainer';
import RC_Logo from 'assets/images/logos/rc_logo.png';

//services
import { AttemptLoginService, saveUserTokenService } from 'services/login';
// Utils.
import {
  DASHBOARD_PAGE,
  DELETE_ACCOUNT,
  FORGET_PASS_PAGE,
  SIGNUP_PAGE,
  VERIFY_PAGE
} from 'utils/routesUtils';
import {
  ERROR,
  EXISTING_USER,
  PENDING_EMAIL,
  ROADCALL_ID,
  ROADCALL_USER_TOKEN
} from 'utils/constants';
import { errorMessageSingleLine } from 'utils/errorUtils';
import ButtonWithBackground from 'components/Form/Button';
import Mixpanel from 'mixpanel-browser';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { sendOtpEmailService } from 'services/Verification';
import { identifyUser, resetMixPanel } from 'utils/MixPanel';
import clsx from 'clsx';

const LoginPage = ({ setSnackbar }) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors }
  } = useForm();

  const [showPassord, setPasswordVisibility] = useState(false);

  const onSubmit = async data => {
    setValue('loading', true);

    try {
      const res = await AttemptLoginService(
        data.username.toLowerCase(),
        data.password
      );
      if (res.token) {
        saveUserTokenService(res);
        identifyUser();
        if (res.onboarding_step == PENDING_EMAIL) {
          sendOtpEmailService(data.username.toLowerCase());
          history.push(VERIFY_PAGE);
        } else {
          const queryParams = new URLSearchParams(location.search);
          const redirectQueryParam = queryParams.get('redirect');
          if (redirectQueryParam === 'DeleteAccount') {
            history.push(DELETE_ACCOUNT); 
          } else {
            history.push(DASHBOARD_PAGE);
          }
        }
      } else {
        setSnackbar({
          type: ERROR,
          message: errorMessageSingleLine(),
          details: ''
        });
      }
      setValue('loading', false);
    } catch (e) {
      setSnackbar({
        type: ERROR,
        message: errorMessageSingleLine(e?.data?.message),
        details: ''
      });
      setValue('loading', false);
    }
  };

  useEffect(() => {
    localStorage.removeItem(ROADCALL_USER_TOKEN);
    localStorage.removeItem(ROADCALL_ID);
    resetMixPanel();
  }, []);

  return (
    <MainContainer>
      <Box className="form-box" 
      style={{
        justifyContent:'flex-start'
        }}>
       <Box className = {classes.hidecontent}>
        <Typography
          variant="h3"
          className="heading"
          style={{ marginBottom: 10 }}>
          {localStorage.getItem(EXISTING_USER) ? 'Welcome back.' : 'Sign in'}
        </Typography>
        <Typography
          style={{
            color: '#6B6B6B',
            textAlign: 'center',
            fontSize: 12,
            marginBottom: 18
          }}>
          Please enter your details below to log in.
        </Typography>
        </Box>
        <CardMedia
          component="img"
          src={RC_Logo}
          className={clsx('logo-placeholder', classes.logo)}
          style={{ 
            width: 180, 
            marginTop: 20, 
            marginBottom: 20 
          }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Email"
                name="username"
                defaultValue={getValues('username')}
                className="input-box"
                error={errors.username ? true : false}
                {...register('username', { required: true })}
                onChange={e => {
                  setValue('username', e.target.value);
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Password"
                name="password"
                type={!showPassord ? 'password' : 'text'}
                defaultValue={getValues('password')}
                error={errors.password ? true : false}
                {...register('password', { required: true })}
                className="input-box end-endorment"
                onChange={e => {
                  setValue('password', e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        style={{ background: 'transparent' }}
                        onClick={() => setPasswordVisibility(!showPassord)}>
                        {!showPassord ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <ButtonWithBackground
                fullWidth
                disabled={watch('loading')}
                buttonType="submit"
                label="Sign In"
                size="large"
                style={{ fontWeight: 600 }}
              />
            </Grid>
          </Grid>
        </form>

        <Typography
          style={{
            color: '#B8B8B8',
            fontSize: 12,
            textAlign: 'center',
            marginTop: 10,
            fontWeight: 500
          }}>
          Don’t have an account?{' '}
          <Link to={SIGNUP_PAGE} style={{ color: '#EE3C2D', fontWeight: 600 }}>
            Create account
          </Link>
        </Typography>
        <Typography style={{ textAlign: 'center', marginTop: 10 }}>
          <Link
            to={FORGET_PASS_PAGE}
            style={{
              color: '#EE3C2D',
              fontWeight: 600,
              fontSize: 12
            }}>
            Forgot Password?
          </Link>
        </Typography>
      </Box>
    </MainContainer>
  );
};
const useStyles = makeStyles(theme =>({
  hidecontent:{
    [theme.breakpoints.down('sm')]: {
      display:'none'
    }
  },
  logo: {
    marginBottom: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))
export default LoginPage;
