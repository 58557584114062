import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';

const MyCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const arrowStyle = {
    position: 'absolute',
    top: '50%',
    zIndex: 2,
    color: '#ffffff',
  };

  return (
    <div style={{ display: 'flex', position: 'relative', overflow: 'hidden' }}>
      <div style={{ display: 'flex', transition: 'transform 0.5s', transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((img, i) => (
          <div key={i} style={{ flex: '0 0 100%', margin: 0, padding: 0 }}>
            <img src={img} alt={`slide-${i}`} style={{ objectFit: 'cover', width: '100%' }} />
          </div>
        ))}
      </div>
      <Button onClick={goToPrev} style={{ ...arrowStyle, left: '-16px' }}>
        <ArrowCircleLeft />
      </Button>
      <Button onClick={goToNext} style={{ ...arrowStyle, right: '-16px' }}>
        <ArrowCircleRight />
      </Button>
    </div>
  );
};

export default MyCarousel;
