import React, { useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { useImmer } from 'use-immer';

// Components.
import RcDropdown from 'components/Form/Dropdown';
import ButtonWithBackground from 'components/Form/Button';
import FileUpload from 'components/FileUpload';

// Services.
import { getUserIdService, getUserTypeService } from 'services/login';

// Utils.
import states from 'utils/states';
import { sanitizedItems } from 'utils/util';
import cityWithState from 'utils/city&states';
import { updateProfile } from 'reducers/User';
import { uploadFileService } from 'services/files';
import { IMaskInput } from 'react-imask';
import { validatePhoneNumber } from 'utils/validationUtils';
import { sanitizePhoneNumber } from 'utils/numberUtils';
import { createStyles } from '@mui/material';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { ERROR, S3_BUCKET_DIR } from 'utils/constants';
import { errorMessageSingleLine } from 'utils/errorUtils';
import { ZipCodeMask } from 'utils/systemUtils';
import PhoneNumberMask from 'components/Form/PhoneNumberMask';
import { SHIPPER } from 'utils/users';
const S3_URL = process.env.REACT_APP_S3_URL;

const Account = ({
  user,
  updateProfile,
  update_profile_loading,
  setSnackbar
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: user
  });
  const userType = getUserTypeService();

  const classes = useStyles();

  const [profilePic, setProfilePic] = useImmer({
    fileName: '',
    file: ''
  });

  const [loading, setLoading] = useState(false);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    // Not to call on initial render.
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    // Update city value as blank on change of state.
    setValue('city', '');
  }, [watch('state')]);

  const uploadProfilePicture = (fileName, file) => {
    setProfilePic(draft => {
      draft.fileName = fileName;
      draft.file = file;
    });
  };

  const onSubmit = async data => {
    if (
      watch('street_address') &&
      watch('street_address').replace(/ /g, '').length <= 10
    ) {
      setError('street_address', {
        type: 'manual',
        message: 'Address too short'
      });
      return;
    }
    try {
      setLoading(true);
      if (profilePic.fileName && profilePic.file) {
        const res = await uploadFileService(
          profilePic.fileName,
          S3_BUCKET_DIR.PROFILE_PIC,
          profilePic.file
        );
        data.profile_pic_url = res;
      }

      data.phone = sanitizePhoneNumber(data.phone);

      data.company_phone = sanitizePhoneNumber(data.company_phone);

      await updateProfile(getUserTypeService(false), getUserIdService(), data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setSnackbar({
        type: ERROR,
        message: errorMessageSingleLine(e?.message)
      });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="settings-form">
      <Grid container spacing={2}>
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          style={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            className="personal-info"
            style={{ position: 'relative', marginBottom: 12 }}>
            <Box className="avatar-block">
              <FileUpload
                accept="image/jpeg, image/png"
                title="Add a Profile Picture"
                noFilesMessage="Adding a profile picture is optional you can do it later"
                noFilesAlert="success"
                profile_pic={
                  watch('profile_pic_url')
                    ? S3_URL + watch('profile_pic_url')
                    : null
                }
                onSubmit={uploadProfilePicture}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
          sm={12}
          style={{ paddingTop: 4, paddingBottom: 12 }}>
          <Typography className={classes.label}>First Name</Typography>
          <TextField
            variant="outlined"
            fullWidth
            className="inpt-fld fld-active capitalizefield"
            value={watch('first_name')}
            error={errors.first_name}
            {...register(`first_name`, { required: true })}
            onChange={e => setValue('first_name', e.target.value.trimLeft())}
          />
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
          sm={12}
          style={{ paddingTop: 4, paddingBottom: 12 }}>
          <Typography className={classes.label}>Last Name</Typography>
          <TextField
            variant="outlined"
            fullWidth
            className="inpt-fld fld-active capitalizefield"
            value={watch('last_name')}
            error={errors.last_name}
            {...register(`last_name`, { required: true })}
            onChange={e => setValue('last_name', e.target.value.trimLeft())}
          />
        </Grid>

        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          style={{ paddingTop: 4, paddingBottom: 12 }}>
          <Typography className={classes.label}>Email</Typography>
          <TextField
            //inputProps={{ readOnly: true }}
            disabled
            variant="outlined"
            fullWidth
            className="inpt-fld fld-active"
            value={watch('email')}
          />
        </Grid>
        
        {userType == SHIPPER && user.company_name ? (
          <Grid
            item
            lg={12}
            xs={12}
            sm={12}
            style={{ paddingTop: 4, paddingBottom: 12 }}>
            <Typography className={classes.label}>Company Name</Typography>
            <TextField
              disabled
              variant="outlined"
              fullWidth
              className="inpt-fld fld-active"
              value={watch('company_name')}
            />
          </Grid>
        ) : null}
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          style={{ paddingTop: 4, paddingBottom: 12 }}>
          <Typography className={classes.label}>Contact Number</Typography>
          <TextField
            InputProps={{
              inputComponent: PhoneNumberMask
            }}
            variant="outlined"
            fullWidth
            className="inpt-fld fld-active"
            value={watch('phone')}
            error={validatePhoneNumber(watch('phone') || '') ? false : true}
            {...register(`phone`, {
              required: true
            })}
            onChange={e => setValue('phone', e.target.value.trimLeft())}
          />
        </Grid>

        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          style={{ paddingTop: 4, paddingBottom: 12 }}>
          <Typography className={classes.label}>Address</Typography>
          <TextField
            variant="outlined"
            fullWidth
            className="inpt-fld fld-active capitalizefield"
            value={watch('street_address')}
            error={errors.street_address}
            helperText={errors.street_address?.message}
            {...register(`street_address`, {
              required: true,
              minLength: { value: 10, message: 'Address too short' }
            })}
            onChange={e =>
              setValue('street_address', e.target.value.trimLeft())
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          style={{ paddingTop: 4, paddingBottom: 12 }}>
          <Typography className={classes.label}>Zip</Typography>
          <TextField
            InputProps={{
              inputComponent: ZipCodeMask
            }}
            variant="outlined"
            fullWidth
            className="inpt-fld fld-active"
            value={watch('zip_code')}
            error={errors.zip_code}
            {...register(`zip_code`, {
              required: true,
            })}
            onChange={e => setValue('zip_code', e.target.value)}
          />
        </Grid>

        <Grid
          item
          lg={6}
          xs={12}
          sm={12}
          style={{ paddingTop: 4, paddingBottom: 12 }}>
          <Typography className={classes.label}>State</Typography>
          <RcDropdown
            className="inpt-drpbx fld-active"
            InputLabelProps={{ shrink: false }}
            placeholder="State"
            value={watch('state')}
            items={sanitizedItems(states, 'name', 'name')}
            error={errors.state}
            {...register(`state`, { required: true })}
            changeHandler={e => {
              setValue('state', e.target.value);
            }}
            dropdownType="secondary"
            InputProps={{
              endAdornment: <KeyboardArrowDown className={classes.arrowIcon} />
            }}
          />
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
          sm={12}
          style={{ paddingTop: 4, paddingBottom: 12 }}>
          <Typography className={classes.label}>City</Typography>
          <RcDropdown
            className="inpt-drpbx fld-active"
            InputLabelProps={{ shrink: false }}
            placeholder="City"
            value={watch('city')}
            error={errors.city}
            {...register(`city`, { required: true })}
            changeHandler={e => setValue('city', e.target.value)}
            items={sanitizedItems(
              cityWithState.filter(city => city.state === watch('state')),
              'city',
              'city'
            )}
            dropdownType="secondary"
            InputProps={{
              endAdornment: <KeyboardArrowDown className={classes.arrowIcon} />
            }}
          />
        </Grid>

        <Grid item lg={12} xs={12} sm={12}>
          <ButtonWithBackground
            loaderSize={21}
            label="Save"
            size="large"
            buttonType="submit"
            disabled={update_profile_loading || loading}
            style={{ borderRadius: 11, fontSize: 12, fontWeight: 700 }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    label: {
      color: '#454647',
      fontSize: 10,
      marginBottom: 4,
      marginLeft: 25,
      fontWeight: 600
    },
    arrowIcon: {
      color: '#c7c7c7',
      pointerEvents: 'none',
      position: 'absolute',
      right: 25
    }
  })
);

const mapStateToProps = state => ({
  user: state.User.user_data,
  update_profile_loading: state.User.update_profile_loading
});

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: (user_type, user_id, data) =>
      dispatch(updateProfile(user_type, user_id, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
