import React from 'react';

const withScroll = WrappedComponent => {
  const WithScrollComponent = ({ ...props }) => {
    const handleScroll = e => {
      if (props.nextLoading) return false;
      const nearBottom =
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;

      const nextUrl = props.nextUrl || (props.data && props.data.next);
      if (nearBottom && nextUrl) {
        props.loadNext(nextUrl);
      }
    };

    return <WrappedComponent handleScroll={handleScroll} {...props} />;
  };
  return WithScrollComponent;
};

export default withScroll;
