// Core.
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { GoogleApiWrapper } from 'google-maps-react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  makeStyles,
  createStyles,
  Typography,
  Grid,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

// Components
import PageLoader from 'components/loader';
import LineLoading from 'components/loader/LineLoader';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker
} from '@material-ui/pickers';
import { DATE_FORMAT_DD } from 'utils/CommonFormats';

// Icons.
import { ReactComponent as CalenderIcon } from 'assets/images/common/calender.svg';
import { ReactComponent as TimeIcon } from 'assets/images/common/time.svg';
import { getTruck, getTruckConfigs, postTruck } from 'reducers/Truck';
import ButtonWithBackground from 'components/Form/Button';
import {
  cityFromAddressService,
  isUSAddressService,
  stateFromAddressService,
  zipFromAddressService
} from 'services/Shipments';
import { ERROR, PREFIX_TRUCK, SUCCESS } from 'utils/constants';
import { errorMessageSingleLine } from 'utils/errorUtils';
import { addTruck, updateTruck } from 'services/truck';
import clsx from 'clsx';
import { TRUCKS_PAGE } from 'utils/routesUtils';
import PhoneNumberMask from 'components/Form/PhoneNumberMask';
import { sanitizePhoneNumber } from 'utils/numberUtils';
import { Chip } from '@mui/material';

const google_api_key = process.env.REACT_APP_GOOGLE_API;

const AddTruck = ({
  setSnackbar,
  truck_capacities,
  user,
  truck_types,
  capacities,
  destination_radius,
  regions,
  getTruckConfigs,
  origin_radius,
  truck_destination_option,
  trucksConfigLoading,
  postTruck,
  getTruck,
  truck,
  truck_loading
}) => {
  const formDefaultValues = {
    truck: '',
    weight: '',
    length: '',
    capacity: '',
    origin: '',
    origin_city: '',
    origin_state: '',
    origin_zip: '',
    origin_coords: null,
    radius: '',
    origin_date: null,
    origin_time: null,
    destination_type: '',
    destination: '',
    destination_city: '',
    destination_state: '',
    destination_zip: '',
    destination_coords: null,
    price: '',
    comments: '',
    full_name: `${user.first_name} ${user.last_name}`,
    phone: user.phone,
    email: user.email
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    setFocus,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: formDefaultValues
  });

  const { id: url_truck_id } = useParams();

  const history = useHistory();

  const [addingTruck, setAddingTruck] = useState(false);

  const [commentTags, setCommentTags] = useState([]);

  useEffect(() => {
    if (truck_types.length) setValue('truck', truck_types[0].truck_type);
  }, [truck_types]);

  useEffect(() => {
    if (origin_radius.length) setValue('radius', origin_radius[0].value);
  }, [origin_radius]);

  useEffect(() => {
    if (truck_destination_option.length)
      setValue('destination_type', truck_destination_option[0].name);
  }, [truck_destination_option]);

  const classes = useStyles();

  const onSubmit = async data => {
    const formData = {
      origin_city: data.origin_city,
      origin_state: data.origin_state,
      origin_radius: data.radius,
      contact_full_name: data.full_name,
      contact_phone_number: sanitizePhoneNumber(data.phone),
      contact_email: data.email,
      truck_type: data.truck,
      capacity: data.capacity,
      weight: data.weight || null,
      length: data.length || null,
      destination_type: data.destination_type,
      destination: data.destination,
      destination_city: data.destination_city,
      destination_state: data.destination_state,
      destination_zip: data.destination_zip,
      destination_lat: data.destination_coords
        ? data.destination_coords.lat
        : null,
      destination_long: data.destination_coords
        ? data.destination_coords.lng
        : null,
      origin: data.origin,
      origin_zip: data.origin_zip,
      origin_lat: data.origin_coords.lat,
      origin_long: data.origin_coords.lng,
      origin_time: data.origin_time,
      origin_date: data.origin_date,
      comments: data.comments,
      price: data.price || null,
      is_repost: !data.origin_date && !data.origin_time ? true : false,
      all_comments: commentTags
    };

    try {
      setAddingTruck(true);
      const response = url_truck_id
        ? await updateTruck(url_truck_id, formData)
        : await addTruck(formData);

      if (response) {
        //reset(formDefaultValues);
        history.push(TRUCKS_PAGE);
      }
    } catch (e) {
      setSnackbar({
        type: ERROR,
        message: errorMessageSingleLine(e.data)
      });
    }
    setAddingTruck(false);
  };

  useEffect(() => {
    // Get truck info by truck id.
    if (url_truck_id) getTruck(`${PREFIX_TRUCK}${url_truck_id}`);
  }, []);

  const setCommentTag = value => {
    if (!value.trim()) return;

    let newSelectedItem = [...commentTags];

    newSelectedItem = [...newSelectedItem, value];

    setCommentTags(newSelectedItem);

    setValue('comments', '');
  };

  const handleMultiComment = e => {
    if (e.keyCode == 13 || e.keyCode === 9) {
      e.preventDefault();

      setCommentTag(e.target.value);
    }
  };

  const handleMultiCommentOnBlur = e => setCommentTag(e.target.value);

  const handleDeleteTag = index => {
    const tags = [...commentTags];
    tags.splice(index, 1);
    setCommentTags(tags);
  };

  useEffect(() => {
    if (truck && url_truck_id) {
      setValue('truck', truck.truck_type.truck_type);
      setValue('weight', truck.weight);
      setValue('length', truck.length);
      setValue('capacity', truck.capacity ? truck.capacity.capacity : '');
      setValue('origin', truck.origin);
      setValue('origin_city', truck.origin_city);
      setValue('origin_state', truck.origin_state);
      setValue('origin_zip', truck.origin_zip);
      setValue('origin_coords', {
        lat: truck.origin_lat,
        lng: truck.origin_long
      });
      setValue('radius', truck.origin_radius.value);
      setValue('origin_date', truck.origin_date);
      setValue('origin_time', truck.origin_time);
      setValue('destination_type', truck.destination_type.name);
      setValue('destination', truck.destination);
      setValue('destination_city', truck.destination_city);
      setValue('destination_state', truck.destination_state);
      setValue('destination_zip', truck.destination_zip);
      setValue(
        'destination_coords',
        truck.destination_lat && truck.destination_long
          ? { lat: truck.destination_lat, lng: truck.destination_long }
          : null
      );
      setValue('price', truck.price);
      setValue('comments', truck.comments);
      setValue('full_name', truck.contact_full_name);
      setValue('phone', truck.contact_phone_number);
      setValue('email', truck.contact_email);
      setCommentTags(truck.all_comments);
    }
  }, [truck]);

  if (trucksConfigLoading) return <PageLoader />;
  if (url_truck_id && truck_loading) return <PageLoader />;

  return (
    <Box className="container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.card}>
          <Typography className={classes.cardTitle}>Truck Details</Typography>
          <Box className={classes.cardInner}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className={classes.label}>
                  Select a Truck
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  className="inpt-fld-select"
                  error={errors.truck ? true : false}
                  value={watch('truck')}
                  {...register('truck', {
                    required: true
                  })}
                  onChange={e => {
                    setValue('truck', e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <KeyboardArrowDownIcon className={classes.arrowIcon} />
                    )
                  }}>
                  {truck_types.map(truck => (
                    <MenuItem key={truck.truck_type} value={truck.truck_type}>
                      {truck.display_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={2} md={3} sm={6} xs={12}>
                <Typography className={classes.label}>Weight (kg)</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  className="inpt-fld"
                  placeholder="Weight"
                  value={watch('weight')}
                  {...register('weight')}
                  onChange={e => {
                    setValue('weight', e.target.value.replace(/\D/g, ''));
                  }}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={6} xs={12}>
                <Typography className={classes.label}>Length (mtr)</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  className="inpt-fld"
                  placeholder="Length"
                  value={watch('length')}
                  {...register('length')}
                  onChange={e => {
                    setValue('length', e.target.value.replace(/\D/g, ''));
                  }}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={6} xs={12}>
                <Typography className={classes.label}>Capacity</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  className="inpt-fld-select"
                  InputProps={{
                    endAdornment: (
                      <KeyboardArrowDownIcon className={classes.arrowIcon} />
                    )
                  }}
                  value={watch('capacity')}
                  error={errors.capacity ? true : false}
                  {...register('capacity', {
                    required: false
                  })}
                  onChange={e => {
                    setValue('capacity', e.target.value);
                  }}>
                  {truck_capacities.map(capacity => (
                    <MenuItem key={capacity.capacity} value={capacity.capacity}>
                      {capacity.display_value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={2} md={2} sm={12} xs={12}></Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.card}>
          <Typography className={classes.cardTitle}>
            Where will you be empty?
          </Typography>
          <Box className={classes.cardInner}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className={classes.label}>Origin</Typography>
                <PlacesAutocomplete
                  searchOptions={{
                    componentRestrictions: { country: ['us'] }
                  }}
                  onChange={address => {
                    setValue('origin', address);
                    setValue('origin_coords', formDefaultValues.origin_coords);
                  }}
                  value={watch('origin')}
                  onSelect={location =>
                    geocodeByAddress(location)
                      .then(results => {
                        if (
                          isUSAddressService(results[0]) &&
                          stateFromAddressService(results[0]) &&
                          cityFromAddressService(results[0])
                        ) {
                          setValue('origin', location);

                          setValue(
                            'origin_zip',
                            zipFromAddressService(results[0]) || ''
                          );

                          setValue(
                            'origin_city',
                            cityFromAddressService(results[0])
                          );

                          setValue(
                            'origin_state',
                            stateFromAddressService(results[0])
                          );
                          return getLatLng(results[0]);
                        } else {
                          setValue('origin', formDefaultValues.origin);
                          setValue(
                            'origin_coords',
                            formDefaultValues.origin_coords
                          );
                        }
                      })
                      .then(coords => {
                        setValue('origin_coords', coords);
                        setValue('origin', location);
                      })
                      .catch(error => console.log(error))
                  }>
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading
                  }) => (
                    <>
                      <TextField
                        InputLabelProps={{ shrink: false }}
                        variant="outlined"
                        className="inpt-fld"
                        fullWidth
                        error={
                          errors.origin || errors.origin_coords ? true : false
                        }
                        {...register('origin', {
                          required: true
                        })}
                        {...register('origin_coords', {
                          required: true
                        })}
                        {...getInputProps({
                          placeholder: 'Origin'
                        })}
                      />
                      <>
                        {loading && <LineLoading />}
                        {suggestions.map(suggestion => (
                          <MenuItem
                            {...getSuggestionItemProps(suggestion)}
                            key={suggestion.description}>
                            {suggestion.description}
                          </MenuItem>
                        ))}
                      </>
                    </>
                  )}
                </PlacesAutocomplete>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <Typography className={classes.label}>Radius</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  className="inpt-fld-select"
                  InputProps={{
                    endAdornment: (
                      <KeyboardArrowDownIcon className={classes.arrowIcon} />
                    )
                  }}
                  error={errors.radius ? true : false}
                  value={watch('radius')}
                  {...register('radius', {
                    required: true
                  })}
                  onChange={e => {
                    setValue('radius', e.target.value);
                  }}>
                  {origin_radius.map(radius => (
                    <MenuItem key={radius.value} value={radius.value}>
                      {radius.display_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <Typography className={classes.label}>Date</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    format={DATE_FORMAT_DD}
                    placeholder="Any Date"
                    className="inpt-fld"
                    value={watch('origin_date')}
                    onChange={date => setValue('origin_date', date)}
                    fullWidth
                    inputVariant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disableRipple
                            style={{ padding: 0, background: 'transparent' }}>
                            <CalenderIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <Typography className={classes.label}>Time</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    disabled={!watch('origin_date') ? true : false}
                    placeholder="Any time"
                    className="inpt-fld"
                    value={watch('origin_time')}
                    onChange={time => setValue('origin_time', time)}
                    fullWidth
                    inputVariant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disableRipple
                            style={{ padding: 0, background: 'transparent' }}>
                            <TimeIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item lg={2} md={2} sm={12} xs={12}>
                <Typography className={classes.tip}>
                  To automatically repost your listing daily, leave the date and
                  time blank.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className={classes.card}>
          <Typography className={classes.cardTitle}>
            Where would you like to go?
          </Typography>
          <Box className={classes.cardInner}>
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className={classes.label}>
                  Select a destination option
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  className="inpt-fld-select"
                  error={errors.destination_type ? true : false}
                  value={watch('destination_type')}
                  {...register('destination_type', {
                    required: true
                  })}
                  onChange={e => {
                    setValue('destination_type', e.target.value);
                    setValue('destination', '');
                    setValue('destination_city', '');
                    setValue('destination_state', '');
                    setValue('destination_zip', '');
                    setValue('destination_coords', null);
                  }}
                  InputProps={{
                    endAdornment: (
                      <KeyboardArrowDownIcon className={classes.arrowIcon} />
                    )
                  }}>
                  {truck_destination_option.map(option => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.display_value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                {watch('destination_type') === 'specific_address' && (
                  <>
                    <Typography className={classes.label}>
                      Destination
                    </Typography>
                    <PlacesAutocomplete
                      searchOptions={{
                        componentRestrictions: { country: ['us'] }
                      }}
                      onChange={address => {
                        setValue('destination', address);
                        setValue(
                          'destination_coords',
                          formDefaultValues.destination_coords
                        );
                      }}
                      value={watch('destination')}
                      onSelect={location =>
                        geocodeByAddress(location)
                          .then(results => {
                            if (
                              isUSAddressService(results[0]) &&
                              stateFromAddressService(results[0]) &&
                              cityFromAddressService(results[0])
                            ) {
                              setValue('destination', location);

                              setValue(
                                'destination_zip',
                                zipFromAddressService(results[0]) || ''
                              );

                              setValue(
                                'destination_city',
                                cityFromAddressService(results[0])
                              );

                              setValue(
                                'destination_state',
                                stateFromAddressService(results[0])
                              );
                              return getLatLng(results[0]);
                            } else {
                              setValue(
                                'destination',
                                formDefaultValues.destination
                              );
                              setValue(
                                'destination_coords',
                                formDefaultValues.destination_coords
                              );
                            }
                          })
                          .then(coords => {
                            setValue('destination_coords', coords);
                            setValue('destination', location);
                          })
                          .catch(error => console.log(error))
                      }>
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading
                      }) => (
                        <>
                          <TextField
                            InputLabelProps={{ shrink: false }}
                            variant="outlined"
                            className="inpt-fld"
                            fullWidth
                            error={
                              errors.destination || errors.destination_coords
                                ? true
                                : false
                            }
                            {...register('destination', {
                              required: true
                            })}
                            {...register('destination_coords', {
                              required: true
                            })}
                            {...getInputProps({
                              placeholder: 'Enter destination'
                            })}
                          />
                          <>
                            {loading && <LineLoading />}
                            {suggestions.map(suggestion => (
                              <MenuItem
                                {...getSuggestionItemProps(suggestion)}
                                key={suggestion.description}>
                                {suggestion.description}
                              </MenuItem>
                            ))}
                          </>
                        </>
                      )}
                    </PlacesAutocomplete>
                  </>
                )}
                {watch('destination_type') === 'select_a_region' && (
                  <>
                    <Typography className={classes.label}>
                      Select a Region
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      select
                      className="inpt-fld-select"
                      onChange={e => setValue('destination', e.target.value)}
                      value={watch('destination')}
                      error={errors.destination ? true : false}
                      {...register('destination', {
                        required: true
                      })}
                      InputProps={{
                        endAdornment: (
                          <KeyboardArrowDownIcon
                            className={classes.arrowIcon}
                          />
                        )
                      }}>
                      {regions.map(region => (
                        <MenuItem key={region.name} value={region.name}>
                          {region.display_value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
                {watch('destination_type') === 'max_distance_from_origin' && (
                  <>
                    <Typography className={classes.label}>
                      How far are you willing to travel?
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      select
                      className="inpt-fld-select"
                      onChange={e => setValue('destination', e.target.value)}
                      value={watch('destination')}
                      error={errors.destination ? true : false}
                      {...register('destination', {
                        required: true
                      })}
                      InputProps={{
                        endAdornment: (
                          <KeyboardArrowDownIcon
                            className={classes.arrowIcon}
                          />
                        )
                      }}>
                      {destination_radius.map(radius => (
                        <MenuItem key={radius.value} value={radius.value}>
                          {radius.display_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
              </Grid>
              <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}></Grid>
            </Grid>
          </Box>
        </Box>

        <Box className={classes.card}>
          <Typography className={classes.cardTitle}>
            Additional Information
          </Typography>
          <Box className={classes.cardInner}>
            <Grid container spacing={3}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Typography className={classes.label}>
                  Price per mile
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  className="inpt-fld"
                  placeholder="1234"
                  helperText={errors.price?.message}
                  error={errors.price ? true : false}
                  value={watch('price')}
                  {...register('price', {
                    maxLength: {
                      value: 6,
                      message: 'Price too high.'
                    }
                  })}
                  onChange={e => {
                    setValue('price', e.target.value.replace(/\D/g, ''));
                  }}
                  InputProps={{
                    startAdornment: (
                      <Typography
                        style={{
                          fontSize: 12,
                          position: 'absolute',
                          left: 25
                        }}>
                        $
                      </Typography>
                    )
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Typography className={classes.label}>Comments</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  className="inpt-fld chips-fld"
                  placeholder="Press enter or tab to saperate comments."
                  value={watch('comments')}
                  {...register('comments')}
                  onChange={e => {
                    setValue('comments', e.target.value);
                  }}
                  onKeyDown={handleMultiComment}
                  onBlur={handleMultiCommentOnBlur}
                  // multiline
                  // rows={2}
                  InputProps={{
                    startAdornment: commentTags.map((item, index) => (
                      <Chip
                        key={index}
                        tabIndex={-1}
                        label={item}
                        className={classes.chip}
                        onDelete={() => handleDeleteTag(index)}
                      />
                    ))
                  }}
                />
              </Grid>
              <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <Typography className={classes.tip}>
                  Adding additional information helps shippers know what type of
                  jobs you are looking for.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className={classes.card}>
          <Typography className={classes.cardTitle}>Contact Details</Typography>
          <Box className={classes.cardInner}>
            <Grid container spacing={3}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Typography className={classes.label}>Full Name</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  className={clsx('inpt-fld', 'inpt-name-fld')}
                  placeholder="Enter your full name"
                  error={errors.full_name ? true : false}
                  value={watch('full_name')}
                  {...register('full_name', {
                    required: true,
                    maxLength: 20
                  })}
                  onChange={e => {
                    setValue('full_name', e.target.value.trimLeft());
                  }}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Typography className={classes.label}>Phone</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  className="inpt-fld"
                  placeholder="Enter your phone number"
                  error={errors.phone ? true : false}
                  value={watch('phone')}
                  {...register('phone', {
                    required: true,
                    minLength: 10
                  })}
                  onChange={e => {
                    setValue('phone', e.target.value.replace(/\D/g, ''));
                  }}
                  InputProps={{
                    inputComponent: PhoneNumberMask
                  }}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Typography className={classes.label}>Email</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  className="inpt-fld"
                  placeholder="Enter your email address"
                  error={errors.email ? true : false}
                  value={watch('email')}
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/
                    }
                  })}
                  onChange={e => {
                    setValue('email', e.target.value.trimLeft());
                  }}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={12} xs={12}></Grid>
            </Grid>
          </Box>
        </Box>
        <ButtonWithBackground
          disabled={addingTruck}
          size="medium"
          buttonType="submit"
          label={url_truck_id ? 'Update Truck' : 'Pin Truck'}
          loaderSize={20}
          style={{
            marginRight: 20,
            borderRadius: 11,
            fontWeight: 700,
            paddingRight: 30,
            paddingLeft: 30,
            marginBottom: 0,
            fontSize: 10,
            position: 'fixed',
            zIndex: 9,
            top: 22,
            right: 40
          }}
        />
      </form>
    </Box>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      background: '#fff',
      borderRadius: 9,
      padding: 16,
      marginBottom: 20,
      paddingBottom: 30
    },
    cardTitle: {
      fontSize: 16,
      fontWeight: 700,
      color: '#656565'
    },
    cardInner: {
      marginTop: 20
    },
    label: {
      color: '#ACACAC',
      fontSize: 12,
      marginBottom: 8,
      marginLeft: 25,
      fontWeight: 600
    },
    arrowIcon: {
      color: '#c7c7c7',
      pointerEvents: 'none',
      position: 'absolute',
      right: 25
    },
    tip: {
      color: '#D5D5D5',
      fontSize: 12,
      fontStyle: 'italic',
      marginTop: 22
    },
    chip: {
      margin: `${theme.spacing(0.5, 0.25)} !important`
    }
  })
);

const mapStateToProps = state => ({
  truck_loading: state.Truck.truck_loading,
  truck_types: state.Truck.truck_types,
  categories: state.Job.cats,
  user: state.User.user_data,
  origin_radius: state.Truck.origin_radius,
  truck_destination_option: state.Truck.truck_destination_option,
  destination_radius: state.Truck.destination_radius,
  trucksConfigLoading: state.Truck.trucksConfigLoading,
  user: state.User.user_data,
  truck_capacities: state.Truck.truck_capacities,
  regions: state.Truck.regions,
  truck: state.Truck.truck
});

const mapDispatchToProps = dispatch => {
  return {
    getTruckConfigs: data => dispatch(getTruckConfigs(data)),
    postTruck: data => dispatch(postTruck(data)),
    getTruck: truck_id => dispatch(getTruck(truck_id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  GoogleApiWrapper({
    apiKey: google_api_key,
    LoadingContainer: PageLoader
  })(AddTruck)
);
