import React from 'react';

// Material UI.
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { capitalizeFirst, convertToLabel, yesOrNo } from 'utils/textUtils';
import BoxLayout3 from 'components/BoxLayout3';
import { CONSTRUCTION_SUBCATEGORY } from 'utils/constants';
import ShipmentItemDamages from '../ShipmentItemDamages';

const Construction = ({
  shipment_items,
  subCategory,
  shipment_damages,
  setSelectedDamageItem
}) => {
  const classes = useStyles();
  return (
    <Box style={{ marginTop: 30 }}>
      {shipment_items.map((item, index) => (
        <BoxLayout3
          key={index}
          className={classes.box}
          title={`${
            item.description ? item.description + ' - ' : ''
          } ${convertToLabel(subCategory)}`}
          collapse={index > 0}>
          <Box className={classes.info}>
            <Box>
              {subCategory === CONSTRUCTION_SUBCATEGORY.TRACTORS ||
              subCategory === CONSTRUCTION_SUBCATEGORY.OTHER ? (
                <Box className={classes.row}>
                  <Typography display="inline" className={classes.key}>
                    Type:{' '}
                  </Typography>{' '}
                  <Typography display="inline" style={{ fontSize: 12 }}>{item.type}</Typography>
                </Box>
              ) : null}

              {subCategory === CONSTRUCTION_SUBCATEGORY.TRACTORS ? (
                <Box className={classes.row}>
                  <Typography display="inline" className={classes.key}>
                    On trailer:{' '}
                  </Typography>{' '}
                  <Typography display="inline" style={{ fontSize: 12 }}>
                    {yesOrNo(item.on_trailer)}
                  </Typography>
                </Box>
              ) : null}
              <Box className={classes.row}>
                <Typography display="inline" className={classes.key}>
                  Dimension:{' '}
                </Typography>{' '}
                <Typography display="inline" style={{ fontSize: 12 }}>
                  {capitalizeFirst(item.dimension_type)}
                </Typography>
              </Box>
              {item.trailer_preference && item.trailer_type_preference ? (
                <Box className={classes.row}>
                  <Typography display="inline" className={classes.key}>
                    Preference:{' '}
                  </Typography>{' '}
                  <Typography display="inline" style={{ fontSize: 12 }}>
                    {convertToLabel(item.trailer_preference)}
                  </Typography>
                </Box>
              ) : null}
            </Box>

            <Box>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingRight: 4,
                        paddingBottom: 8,
                        verticalAlign: 'top'
                      }}>
                      <Typography display="inline" className={classes.key}>
                        Length:
                      </Typography>{' '}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingLeft: 4,
                        paddingBottom: 8
                      }}>
                      <Typography display="inline" style={{ fontSize: 12 }}>
                        {item.length} {item.length_unit_type}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingRight: 4,
                        paddingBottom: 8,
                        verticalAlign: 'top'
                      }}>
                      <Typography display="inline" className={classes.key}>
                        Width:
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingLeft: 4,
                        paddingBottom: 8
                      }}>
                      <Typography display="inline" style={{ fontSize: 12 }}>
                        {item.width} {item.width_unit_type}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingRight: 4,
                        paddingBottom: 8,
                        verticalAlign: 'top'
                      }}>
                      <Typography display="inline" className={classes.key}>
                        Height:
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingLeft: 4,
                        paddingBottom: 8
                      }}>
                      <Typography display="inline" style={{ fontSize: 12 }}>
                        {item.height} {item.height_unit_type}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingRight: 4,
                        paddingBottom: 8,
                        verticalAlign: 'top'
                      }}>
                      <Typography display="inline" className={classes.key}>
                        Weight:
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'unset',
                        padding: 0,
                        paddingLeft: 4,
                        paddingBottom: 8
                      }}>
                      <Typography display="inline" style={{ fontSize: 12 }}>
                        {item.weight} {item.weight_unit_type}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>
          <ShipmentItemDamages
            shipment_damages={shipment_damages}
            setSelectedDamageItem={setSelectedDamageItem}
            shipmentItemId={item.shipment_item_id}
          />
        </BoxLayout3>
      ))}
    </Box>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    box: {
      marginBottom: theme.spacing(3)
    },
    info: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    row: {
      marginBottom: 8
    },
    key: {
      fontWeight: 600,
      fontSize: 12
    }
  })
);

export default Construction;
