import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import configureStore from 'config/configureStore';
import { Provider } from 'react-redux';
import Routes from 'Routes';
import 'assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import smoothscroll from 'smoothscroll-polyfill';
import Mixpanel from 'mixpanel-browser';
import { setupEventHandlers, trackEvent } from 'utils/MixPanel';

const stripe_publish_key = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
const stripePromise = loadStripe(stripe_publish_key);
const mixPanel_token =  process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN

const store = configureStore();
smoothscroll.polyfill();
Mixpanel.init(mixPanel_token);

const App = () => {

  useEffect(setupEventHandlers, []); 
  
  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <CssBaseline />
        <Elements stripe={stripePromise}>
          <Routes />
        </Elements>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
