import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ReactComponent as DeleteIcon } from 'assets/images/common/delete-xs.svg';
import { ReactComponent as PdfIcon } from 'assets/images/common/pdf-xs.svg';

import { getShipmentMedia } from 'services/files';

const UploadedFiles = ({ files, removeItem }) => {
  return (
    <Box style={{ marginTop: 16 }}>
      {files.map((file, index) => (
        <Box
          key={index}
          style={{
            padding: 8,
            paddingRight: 12,
            paddingLeft: 12,
            border: '1px solid #D1D1D1',
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 12
          }}>
          <Box
            style={{ cursor: 'pointer' }}
            onClick={() => getShipmentMedia(file.name)}>
            <PdfIcon />

            <Typography
              display="inline"
              style={{
                color: '#707070',
                fontSize: 12,
                fontWeight: 600,
                marginLeft: 16
              }}>
              {file.name.split('-').pop()}
            </Typography>
          </Box>
          <Box style={{ cursor: 'pointer' }} onClick={() => removeItem(index)}>
            <DeleteIcon />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default UploadedFiles;
