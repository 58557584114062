import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Divider, List, ListItem, Typography } from '@material-ui/core';

import { ReactComponent as BellIcon } from 'assets/images/common/bell.svg';
import { ListItemText } from '@mui/material';

import { getNotifications, markNotificationAsRead } from 'reducers/Inbox';
import SpinLoader from 'components/loader/SpinLoader';
import { LOADS } from 'utils/routesUtils';
import { getUserTypeService } from 'services/login';
import { SHIPPER } from 'utils/users';

import Menu from '@mui/material/Menu';

const NotificationBell = ({
  notifications,
  getNotifications,
  notifications_loading,
  markNotificationAsRead
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const userType = getUserTypeService();

  const history = useHistory();

  useEffect(() => {
    if (anchorEl)
      getNotifications({
        ordering: '-created_at',
        limit: 5
      });
  }, [anchorEl]);

  const isUnreadNotifications = () => {
    let foundUnreadNotification = false;
    if (!notifications || notifications.results.length === 0) return false;
    notifications.results.map(n => {
      if (n.is_read === false && foundUnreadNotification === false)
        foundUnreadNotification = true;
    });

    return foundUnreadNotification;
  };

  const notificationContent = () => {
    if (notifications_loading && !notifications) return <SpinLoader />;
    else if (!notifications || notifications.results.length === 0)
      return <Typography>No notifications found!</Typography>;
    return (
      <List>
        {notifications.results.map((notification, index) => (
          <Box key={index}>
            <ListItem
              style={{ maxWidth: 350 }}
              selected={notification.is_read === false}
              button
              onClick={() => {
                setAnchorEl(null);
                markNotificationAsRead(notification.notification_id);
                history.push({
                  pathname: LOADS,
                  search: `id=${notification.shipment_id}&quote_status=${notification.quote_status}`
                });
              }}>
              <ListItemText
                primary={
                  <Typography variant="h5" style={{ marginBottom: 10 }}>
                    {notification.title}
                  </Typography>
                }
                secondary={
                  <Typography component="span" variant="body2">
                    {notification.message}
                  </Typography>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </Box>
        ))}
      </List>
    );
  };

  return (
    <>
      <Box
        onClick={event => setAnchorEl(event.currentTarget)}
        style={{ cursor: 'pointer', marginRight: 10 }}>
        <Box style={{ position: 'relative' }}>
          <BellIcon />
          {isUnreadNotifications() ? (
            <Box
              style={{
                width: 7,
                height: 7,
                background: userType === SHIPPER ? '#23b6b9' : '#e53935',
                borderRadius: 20,
                position: 'absolute',
                right: 2,
                top: 5
              }}></Box>
          ) : null}
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            ml: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <Box className="p-2">{notificationContent()}</Box>
      </Menu>
    </>
  );
};

const mapStateToProps = state => ({
  notifications: state.Inbox.notifications,
  notifications_loading: state.Inbox.notifications_loading
});

const mapDispatchToProps = dispatch => ({
  getNotifications: params => dispatch(getNotifications(params)),
  markNotificationAsRead: notification_id =>
    dispatch(markNotificationAsRead(notification_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBell);
