import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import { ReactComponent as Tick2Icon } from 'assets/images/common/tick2.svg';
import { ReactComponent as EllipseIcon } from 'assets/images/common/ellipse.svg';
import { convertToLabel } from 'utils/textUtils';

const VerificationStep = ({ label, complete }) => {
  return (
    <Box
      style={{
        padding: 8,
        paddingRight: 12,
        paddingLeft: 12,
        border: '1px solid #D1D1D1',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 12
      }}>
      <Box>
        <Typography
          style={{
            color: complete ? '#313131' : '#B4B4B4',
            fontSize: 12,
            fontWeight: 600
          }}>
          {convertToLabel(label)}
        </Typography>
      </Box>
      <Box>
        {complete ? null : (
          <Typography
            display="inline"
            style={{
              color: '#B4B4B4',
              fontSize: 12,
              marginRight: 6,
              fontWeight: 600
            }}>
            +25%
          </Typography>
        )}
        {complete ? <Tick2Icon /> : <EllipseIcon />}
      </Box>
    </Box>
  );
};

export default VerificationStep;
