import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getUserTypeService } from 'services/login';

import { Box, Typography } from '@material-ui/core';
import { CARRIER } from 'utils/users';

// Icons.
import { ReactComponent as ArrowRightIcon } from 'assets/images/common/arrow-right.svg';
import { ReactComponent as ErrorExIcon } from 'assets/images/common/error-ex.svg';
import { ReactComponent as CrossXsIcon } from 'assets/images/common/cross-xs.svg';
import { verificationDocUploadedPercent } from 'services/Verification';
import { setDocumentVerificationDialogueVisibility } from 'reducers/User';
import { SETTINGS_PAGE } from 'utils/routesUtils';
import {
  documentVerificationDialogueClose,
  getDocumentVerificationDialogueCloseStatus
} from 'services/users';

const DocumentVerificationDialogue = ({
  user,
  showDocumentVerificationDialogue,
  setDocumentVerificationDialogueVisibility
}) => {
  const userType = getUserTypeService();

  useEffect(() => {
    if (user && userType === CARRIER) {
      if (verificationDocUploadedPercent(user) !== 100)
        setDocumentVerificationDialogueVisibility(true);
    }
  }, [user]);

  if (
    !user ||
    userType !== CARRIER ||
    showDocumentVerificationDialogue === false ||
    getDocumentVerificationDialogueCloseStatus() === '1'
  )
    return null;

  return (
    <Box
      style={{
        backgroundColor: '#EFF0F4',
        zIndex: 999,
        position: 'absolute',
        width: '100%',
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight: 200
      }}>
      <Box style={{ marginLeft: 20, display: 'flex', alignItems: 'center' }}>
        <ErrorExIcon />
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            display="inline"
            style={{
              color: '#9C9DC3',
              fontWeight: 600,
              fontSize: 11,
              marginLeft: 20
            }}>
            To book loads and gain full access to the platform,
          </Typography>
          <Link
            to={`${SETTINGS_PAGE}?tab=documents`}
            onClick={() => {
              setDocumentVerificationDialogueVisibility(false);
              documentVerificationDialogueClose();
            }}>
            <Typography
              style={{
                color: '#ec3c2c',
                fontWeight: 700,
                fontSize: 11,
                textDecoration: 'underline',
                marginLeft: 2,
                marginRight: 6
              }}>
              verify your carrier profile here.
            </Typography>
          </Link>
          <ArrowRightIcon />
        </Box>
      </Box>

      <Box
        style={{ marginRight: 20, cursor: 'pointer' }}
        onClick={() => {
          setDocumentVerificationDialogueVisibility(false);
          documentVerificationDialogueClose();
        }}>
        <CrossXsIcon />
      </Box>
    </Box>
  );
};

const mapStateToProps = state => ({
  user: state.User.user_data,
  showDocumentVerificationDialogue: state.User.showDocumentVerificationDialogue
});

const mapDispatchToProps = dispatch => {
  return {
    setDocumentVerificationDialogueVisibility: bool =>
      dispatch(setDocumentVerificationDialogueVisibility(bool))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentVerificationDialogue);
