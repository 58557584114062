import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { ListItem, Button, Collapse, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { getUserTypeService } from 'services/login';
import { CARRIER, SHIPPER } from 'utils/users';

const CustomRouterLink = forwardRef(function CustomLink(props, ref) {
  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  );
});

const SidebarMenuListItem = props => {
  const location = useLocation();

  const userType = getUserTypeService();

  const classes = useStyles();

  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    iconSelected: IconSelected,
    className,
    open: openProp,
    label: Label,
    tag:Tag,
    ...rest
  } = props;

  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(open => !open);
  };

  let paddingLeft = 14;

  if (depth > 0) {
    paddingLeft = 32 + 20 * depth;
  }

  const style = {
    paddingLeft
  };

  if (children) {
    return (
      <ListItem
        {...rest}
        className={clsx('app-sidebar-item', className)}
        disableGutters>
        <Button
          color="primary"
          className={clsx('app-sidebar-button', { active: open })}
          onClick={handleToggle}
          style={style}>
          {Icon && <Icon className="app-sidebar-icon" />}
          <span>{title}</span>
          {open ? (
            <ExpandLessIcon className="sidebar-expand-icon" color="inherit" />
          ) : (
            <ExpandLessIcon
              className="sidebar-expand-icon sidebar-expand-icon-rotate"
              color="inherit"
            />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem
        {...rest}
        className={clsx('app-sidebar-item', className)}
        disableGutters>
        <Button
          disabled={location.pathname === href}
          activeClassName="active-item"
          color="primary"
          disableRipple
          variant="text"
          className={clsx('app-sidebar-button-wrapper', `depth-${depth}`)}
          component={CustomRouterLink}
          exact
          title={title}
          style={style}
          to={{ pathname: href, state: { prevPath: location.pathname } }}>
          {location.pathname === href ? (
            <>
              <div
                className={clsx(
                  userType === SHIPPER ? 'sidebar-dot2': userType===CARRIER ? 'sidebar-dot':'sidebar-dot3',
                  userType === SHIPPER
                    ? classes.SelectedIcon2
                    : classes.SelectedIcon
                )}></div>
              {IconSelected && <IconSelected className="app-sidebar-icon" />}
              <div
                className={clsx(
                  userType === CARRIER
                    ? classes.SelectedText
                    : classes.SelectedText2
                )}>
                {title}
              </div>
              {Tag && (
              <div
                className={classes.plan}>
                <Typography className={classes.text}>
                  {Tag}
                </Typography>
              </div>
            )}
            </>
          ) : (
            <>
              <div
                className={
                  userType === SHIPPER ? 'sidebar-dot2' : userType===CARRIER?'sidebar-dot':'sidebar-dot3'
                }></div>
              {Icon && (
                <Icon className="app-sidebar-icon app-sidebar-icon-default" />
              )}
              {IconSelected && (
                <IconSelected className="app-sidebar-icon app-sidebar-icon-selected" />
              )}
              <div
                className={
                  userType === CARRIER
                    ? 'sidebar-label-text'
                    : 'sidebar-label-text2'
                }>
                {title}
              </div>
            </>
          )}
          {Label && (
            <span className="menu-item-label">
              <Label />
            </span>
          )}
          {Tag && (
            <div
              className={classes.plan}>
              <Typography className={classes.text}>
                {Tag}
              </Typography>
            </div>
          )}
        </Button>
      </ListItem>
    );
  }
};

SidebarMenuListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  iconSelected: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

SidebarMenuListItem.defaultProps = {
  depth: 0,
  open: false
};

const useStyles = makeStyles(theme =>
  createStyles({
    SelectedIcon: {
      background: theme.palette.primary.dark
    },
    SelectedText: {
      color: theme.palette.primary.dark,
      whiteSpace: 'nowrap'
    },
    SelectedIcon2: {
      background: theme.palette.secondary.dark
    },
    SelectedText2: {
      color: theme.palette.secondary.dark,
      whiteSpace: 'nowrap'
    },
    plan:
    {
      padding: '2px 4px',
      position:'absolute',
      right:'-14px',
      backgroundColor: '#132239',
      borderRadius: 4, 
    },
    text:
    {
      color: '#FFFFFF',
      fontSize: 10,
      fontWeight: 600
    }
  })
);

export default SidebarMenuListItem;
